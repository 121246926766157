import React from "react";
import { Checker, Input } from "../shared";

const formFields = [
  {
    label: "Полное наименование организации*",
    placeholder: "Например ООО Рога и Копыта или ИП Сидоров Петр",
    getError: (v) => {
      return v.length > 0 && v.length < 5 ? (
        <p className="input__text">
          Название компании не может содержать меньше 5 символов
        </p>
      ) : null;
    },
    filledLength: 5,
    name: "organization",
  },
  {
    label: "ИНН Организации*",
    placeholder: "ИНН Организации",
    getError: (v) => {
      return v.length > 0 && v.length < 10 ? (
        <p className="input__text">
          ИНН Организации не может содержать меньше 10 символов
        </p>
      ) : null;
    },
    filledLength: 10,
    name: "inn",
    helperText: `Идентификационный номер налогоплательщика для уплаты налогов.
    Обязательный реквизит любой организации, состоящий из 10 арабских цифр в
    строгой последовательности`,
    maxLength: 12,
    onlyNumbers: true,
  },
  {
    label: "КПП*",
    placeholder: "КПП",
    getError: (v) => {
      return v.length > 0 && v.length < 9
        ? "КПП не может содержать меньше 9 символов"
        : null;
    },
    filledLength: 1,
    name: "registrationCode",
    helperText: ``,
    maxLength: 9,
  },
  {
    label: "Основной регистрационный номер*",
    placeholder: "Основной регистрационный номер",
    getError: (v) => {
      return v.length > 0 && v.length !== 13 ? (
        <p className="input__text">
          Основной регистрационный номер не может содержать меньше 13 символов
        </p>
      ) : null;
    },
    filledLength: 13,
    name: "registrationNumber",
    helperText: `ОГРН (основной государственный регистрационный номер) — государственный
    регистрационный номер записи о создании юридического лица (13 цифр)`,
    maxLength: 13,
    onlyNumbers: true,
  },
  {
    label: "Имя генерального директора*",
    placeholder: "Имя генерального директора",
    getError: (v) => {},
    filledLength: 2,
    name: "directorName",
    helperText: ``,
    maxLength: 140,
  },
  {
    label: "Основание действия генерального директора*",
    placeholder: "Основание действия генерального директора",
    getError: (v) => {},
    filledLength: 2,
    name: "actionBasis",
    helperText: ``,
    maxLength: 255,
  },
  {
    label: "Юридический адрес*",
    placeholder: "Юридический адрес",
    getError: (v) => {},
    filledLength: 3,
    name: "juridicalAdress",
    helperText: ``,
    maxLength: 255,
  },
  {
    label: "Контактный телефон*",
    placeholder: "71234567890",
    getError: (v) => {
      return v.length > 0 && v.length < 11 ? "Номер должен содержать 11 цифр" : null;
    },
    filledLength: 11,
    name: "contactPhone",
    helperText: ``,
    maxLength: 11,
    onlyNumbers: true,
  },
];

const bankFields = [
  {
    label: "Наименование учреждения банка*",
    placeholder: "Наименование учреждения банка",
    getError: (v) => {
      return v.length > 0 && v.length < 5 ? (
        <p className="input__text">
          Наименование учреждения банка не может содержать меньше 5 символов
        </p>
      ) : null;
    },
    filledLength: 5,
    name: "bankName",
    maxLength: 255,
  },
  {
    label: "Номер расчетного счета*",
    placeholder: "Номер расчетного счета",
    getError: (v) => {
      return v.length > 0 && v.length < 20 ? (
        <p className="input__text">
          Номер расчетного счета не может содержать меньше 20 символов
        </p>
      ) : null;
    },
    filledLength: 20,
    name: "billNumber",
    maxLength: 20,
  },
  {
    label: "Банковский идентификационный код*",
    placeholder: "Банковский идентификационный номер",
    getError: (v) => {
      return v.length > 0 && v.length !== 9 ? (
        <p className="input__text">
          Банковский идентификационный номер не может содержать меньше 9 символов
        </p>
      ) : null;
    },
    filledLength: 9,
    name: "bic",
    helperText: `Уникальный идентификатор банка`,
    maxLength: 9,
  },
  {
    label: "Номер корреспондирующего счета*",
    placeholder: "Номер корреспондирующего счета",
    getError: (v) => {
      return v.length > 0 && v.length !== 20 ? (
        <p className="input__text">
          Номер корреспондирующего счета не может содержать меньше 20 символов
        </p>
      ) : null;
    },
    filledLength: 20,
    name: "correspondingNumber",
    helperText: `Номер корреспондирующего счета(20 цифр)`,
    maxLength: 20,
  },
];

const getOnlyNumbers = (val) => {
  return val.replace(/[^\d]/g, "");
};

const JuridicalForm = ({
  onChange,
  getFieldValue,
  withVAT,
  onVATChange,
  disabled = false,
}) => {
  return (
    <div className="create-stand-content__form">
      {formFields.map((f, i) => {
        const fieldValue = getFieldValue(f.name);

        return (
          <Input
            key={i}
            label={f.label}
            error={f.getError(fieldValue)}
            isFilled={fieldValue.length >= f.filledLength}
            value={fieldValue}
            onChange={(e) => {
              let value = e.target.value;

              if (f.onlyNumbers) {
                value = getOnlyNumbers(value);
              }

              onChange({ fieldName: f.name, value });
            }}
            placeholder={f.placeholder}
            maxLength={f.maxLength}
            helperText={f.helperText || ""}
            type={f.type || "text"}
            disabled={disabled}
          />
        );
      })}

      <div className="input input--create-stand ">
        <label className="create-stand-content__checker">
          <span>С НДС</span>
          <Checker isActive={withVAT} onClick={onVATChange} />
        </label>
      </div>
      <div className="create-stand-content__posttitle create-stand-content__posttitle--mt">
        Банковская информация
      </div>

      {bankFields.map((f, i) => {
        const fieldValue = getFieldValue(f.name);

        return (
          <Input
            key={i}
            label={f.label}
            error={f.getError(fieldValue)}
            isFilled={fieldValue.length >= f.filledLength}
            value={fieldValue}
            onChange={(e) => {
              let value = e.target.value;

              if (f.onlyNumbers) {
                value = getOnlyNumbers(value);
              }

              onChange({ fieldName: [f.name], value });
            }}
            placeholder={f.placeholder}
            maxLength={f.maxLength}
            helperText={f.helperText || ""}
            type={f.type || "text"}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

export default JuridicalForm;
