import React from "react";
import { Element } from "react-scroll";
import moment from "moment";
import { clearString } from "../../../../utils";

const DemoEventProgram = ({ program, colorTitle, colorText }) => {
  return (
    program &&
    program.length > 0 && (
      <Element name="Программа" className="demo-event__program">
        <div className="demo__container demo__container--program">
          <div className="demo-event__program-title" style={{ color: colorTitle }}>
            Программа мероприятия
          </div>
          <div className="demo-event__program-main">
            {program.map((day, ind) => {
              return (
                <div key={ind} className="demo-event__program-item">
                  <h5 className="demo-event__program-item-title">
                    {moment(new Date(day.date)).format("D MMMM")}
                  </h5>
                  {[...day.events]
                    .sort((a, b) => {
                      if (!a?.timeStart || !b?.timeStart) {
                        return 0;
                      }
                      return new Date(a.timeStart) - new Date(b.timeStart);
                    })
                    .map(
                      (event, i) =>
                        event.timeStart && (
                          <div key={i} className="demo-event__program-item-content">
                            <div className="demo-event__program-time">
                              <span>{moment(event.timeStart).format("HH:mm")}</span>
                              <span>-</span>
                              <span>{moment(event.timeEnd).format("HH:mm")}</span>
                            </div>
                            <div
                              className="demo-event__program-event"
                              style={{ color: colorText }}
                            >
                              {clearString(event.description)}
                            </div>
                          </div>
                        )
                    )}
                </div>
              );
            })}
          </div>
        </div>
      </Element>
    )
  );
};

export default DemoEventProgram;
