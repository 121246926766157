import React from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import {
  setCurrentStandPosition,
  setCurrentStandInPlayer,
} from "../../redux/eventReducer";

const BackToLobby = ({ frameRef, isOnContentView }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const parsedQuery = queryString.parse(history.location.search);

  const onClick = () => {
    dispatch(setCurrentStandPosition(null));
    dispatch(setCurrentStandInPlayer(null));
    const currentStandId = parsedQuery.memberId;
    if (!currentStandId) {
      return;
    }

    frameRef.current.contentWindow.postMessage(
      JSON.stringify({ type: "backToCheckpoint", standId: currentStandId }),
      "*"
    );
    history.push(history.location.pathname + "?location=checkpoint");
  };

  if (isOnContentView) {
    return null;
  }

  return (
    <div className="back-to-lobby">
      <button onClick={onClick} className="back-to-lobby__button">
        Выйти из просмотра стенда
      </button>
    </div>
  );
};

export default BackToLobby;
