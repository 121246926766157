import React from "react";
import { useHistory } from "react-router-dom";
import { registrationBack } from "../../assets";
import { Button } from "../shared";
import { Hint } from "../organizer-panel/";
import { useSelector } from "react-redux";

const StandHeader = ({
  title,
  subtitle,
  button,
  onClickButton,
  back,
  classes = "",
  buttons,
  buttonDisabled,
  withHint = false,
  hintText = "Для редактирования заполните обязательные предыдущие разделы",
}) => {
  const role = useSelector((state) => state.user.profile.role);

  const history = useHistory();

  const onBackClick = () => {
    history.goBack();
  };

  return (
    <div className={"stand-settings__header " + classes}>
      <div className="stand-settings__about">
        {back && (
          <button onClick={onBackClick} className="stand-settings__about-back">
            <img src={registrationBack} alt="Back Icon" />
          </button>
        )}
        <div className="stand-settings__about-info">
          <h5 className="stand-settings__title">{title}</h5>
          {subtitle ? <p className="stand-settings__subtitle">{subtitle}</p> : null}
          {withHint && role === "организатор" && <Hint hintText={hintText} />}
        </div>
      </div>
      <div className="stand-settings__buttons">
        {button ? (
          <Button disabled={buttonDisabled} onClick={onClickButton}>
            {button}
          </Button>
        ) : null}
        {buttons?.map((b, i) =>
          b ? (
            <div className="stand-settings__header-btn" key={i}>
              {b}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default StandHeader;
