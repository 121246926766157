import React from "react";
import { Contact } from "./Contact";
import { truncateString } from "../../../utils";
import {useSelector} from "react-redux";

export const Contacts = ({ onContactClick }) => {
  const contacts = useSelector((state) => state.chats.contacts);

  return (
    <div className="side-chat__contacts">
      {contacts.map((c) => (
        <Contact
          id={c.id}
          name={c.name}
          role={(c.eventName ? truncateString(c.eventName) + ", " : "") + c.role}
          status={c.status}
          photo={c.photo}
          count={c.count}
          key={c.id}
          onClick={() => onContactClick(c)}
        />
      ))}
    </div>
  );
};
