export const getImageSize = (file, src = "") => {
  const img = document.createElement("img");
  let url = src;

  if (file) {
    url = URL.createObjectURL(file);
  }

  const promise = new Promise((resolve, reject) => {
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      resolve({ width, height });
    };

    img.onerror = reject;
  });

  img.src = url;

  return promise;
};
