import React from "react";
import classNames from "classnames";

const ContactsPagination = ({ currentPage, setCurrentPage, pageNumbers }) => {
  return (
    <nav className="stand-settings__pagination">
      <ul className="pagination">
        <button
          className={classNames("pagination-item", {
            disabled: currentPage === 1,
          })}
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          <span className="pagination-link">
            <i>
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.75098 11C5.78353 11 5.81527 10.994 5.84619 10.982C5.87712 10.97 5.90397 10.9503 5.92676 10.9229C5.97559 10.8715 6 10.8099 6 10.7379C6 10.666 5.97559 10.6043 5.92676 10.5529L1.60547 6L5.92676 1.45221C5.97559 1.40082 6 1.3383 6 1.26465C6 1.19099 5.97559 1.12847 5.92676 1.07708C5.87793 1.02569 5.81934 1 5.75098 1C5.68262 1 5.62402 1.02569 5.5752 1.07708L1.07324 5.81501C1.02441 5.86639 1 5.92806 1 6C1 6.07194 1.02441 6.13361 1.07324 6.18499L5.5752 10.9229C5.59798 10.9503 5.62484 10.97 5.65576 10.982C5.68669 10.994 5.71842 11 5.75098 11Z"
                  stroke="#3F4551"
                />
              </svg>
            </i>
          </span>
        </button>
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={classNames("pagination-item", {
              active: currentPage === number,
            })}
            onClick={() => setCurrentPage(number)}
          >
            <span className="pagination-link">{number}</span>
          </li>
        ))}
        <button
          className={classNames("pagination-item", {
            disabled: pageNumbers.length === currentPage || pageNumbers.length === 0,
          })}
          disabled={pageNumbers.length === currentPage || pageNumbers.length === 0}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <span className="pagination-link">
            <i>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.24902 1C4.21647 1 4.18473 1.006 4.15381 1.01799C4.12288 1.02998 4.09603 1.04967 4.07324 1.07708C4.02441 1.12847 4 1.19013 4 1.26208C4 1.33402 4.02441 1.39568 4.07324 1.44707L8.39453 6L4.07324 10.5478C4.02441 10.5992 4 10.6617 4 10.7354C4 10.809 4.02441 10.8715 4.07324 10.9229C4.12207 10.9743 4.18066 11 4.24902 11C4.31738 11 4.37598 10.9743 4.4248 10.9229L8.92676 6.18499C8.97559 6.13361 9 6.07194 9 6C9 5.92806 8.97559 5.86639 8.92676 5.81501L4.4248 1.07708C4.40202 1.04967 4.37516 1.02998 4.34424 1.01799C4.31331 1.006 4.28158 1 4.24902 1Z"
                  stroke="#3F4551"
                />
              </svg>
            </i>
          </span>
        </button>
      </ul>
    </nav>
  );
};

export default ContactsPagination;
