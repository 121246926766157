import cn from "classnames";
import React from "react";
import DatePicker from "react-datepicker";
import { CreateWebDelete, promotionDate } from "../../assets";
import TimePicker from "../shared/TimePicker";

export const isProgramValid = (programEvents) => {
  let isValid = true;

  if (programEvents.some((pr) => !pr.date)) {
    isValid = false;
    return isValid;
  }

  programEvents.forEach((pr) => {
    if (pr.events.some((ev) => !ev.timeEnd || !ev.timeStart || !ev.description)) {
      isValid = false;
    }
  });

  return isValid;
};

const ProgramEvents = ({
  programEvents,
  setProgramEvents,
  isDisabled = false,
  isShowErrors = false,
  minDate = new Date(),
  maxDate = null,
  withDisabledWebinarInputs = false,
}) => {
  isDisabled = false;
  if (isDisabled) {
    setProgramEvents = () => {};
  }

  const onProgramDateChange = ({ date, index }) => {
    setProgramEvents((prev) =>
      [...prev].map((pr, ind) =>
        ind === index ? { ...pr, date: new Date(date).toISOString() } : pr
      )
    );
  };

  const onProgramTimeStartChange = ({ date, index, eventIndex }) => {
    setProgramEvents((prev) =>
      [...prev].map((pr, ind) =>
        ind === index
          ? {
              ...pr,
              events: pr.events.map((event, evInd) =>
                eventIndex === evInd
                  ? { ...event, timeStart: new Date(date).toISOString(), timeEnd: "" }
                  : event
              ),
            }
          : pr
      )
    );
  };

  const onProgramTimeEndChange = ({ date, index, eventIndex }) => {
    setProgramEvents((prev) =>
      [...prev].map((pr, ind) =>
        ind === index
          ? {
              ...pr,
              events: pr.events.map((event, evInd) =>
                eventIndex === evInd
                  ? { ...event, timeEnd: new Date(date).toISOString() }
                  : event
              ),
            }
          : pr
      )
    );
  };

  const onProgramEventDescriptionChange = ({ newValue, index, eventIndex }) => {
    setProgramEvents((prev) =>
      [...prev].map((pr, ind) =>
        ind === index
          ? {
              ...pr,
              events: pr.events.map((event, evInd) =>
                eventIndex === evInd ? { ...event, description: newValue } : event
              ),
            }
          : pr
      )
    );
  };

  const onAddNewProgramDay = () => {
    setProgramEvents((prev) => [
      ...prev,
      {
        date: "",
        events: [
          { timeStart: "", timeEnd: "", description: "" },
          { timeStart: "", timeEnd: "", description: "" },
        ],
      },
    ]);
  };

  const onAddNewProgramEvent = ({ index }) => {
    setProgramEvents((prev) =>
      [...prev].map((pr, ind) =>
        ind === index
          ? {
              ...pr,
              events: [
                ...pr.events,
                {
                  timeStart: "",
                  timeEnd: "",
                  description: "",
                },
              ],
            }
          : pr
      )
    );
  };

  const onDeleteProgramDay = ({ index }) => {
    setProgramEvents((prev) => [...prev].filter((_, ind) => ind !== index));
  };

  const onDeleteProgramEvent = ({ index, eventIndex }) => {
    setProgramEvents((prev) =>
      [...prev].map((pr, ind) =>
        ind === index
          ? {
              ...pr,
              events: [...pr.events].filter((_, i) => i !== eventIndex),
            }
          : pr
      )
    );
  };

  const isDateValid = (date) => {
    if (!isShowErrors) {
      return true;
    }

    return !!date;
  };

  const isEventInputDisabled = (event) => {
    if (!withDisabledWebinarInputs) {
      return false;
    }

    if (event.isWebinar && withDisabledWebinarInputs) {
      return true;
    }
  };

  return (
    <>
      <div className="web-item__program-main">
        {programEvents.map((day, index) => (
          <div key={index} className="web-item__program">
            <div className="web-item__program-inputs">
              <div className="input input-promotion input__program--time">
                <label style={{ marginBottom: 5 }} className="input__label">
                  Дата
                </label>
                <div className="web-item__program-input">
                  <div className="web-item__input-wrapper">
                    <DatePicker
                      selected={day.date ? new Date(day.date) : null}
                      onChange={(date) => onProgramDateChange({ date, index })}
                      dateFormat="dd-MM-yyyy"
                      className={cn("default-input", {
                        "default-input--error": !isDateValid(day.date),
                        "default-input--filled": day.date,
                      })}
                      minDate={minDate}
                      maxDate={maxDate}
                      placeholderText="дд.мм.гг"
                      disabled={isDisabled}
                    />
                    <img className="web-item__date-icon" src={promotionDate} alt="#" />
                    {index !== 0 &&
                      !day.events.some((ev) => isEventInputDisabled(ev)) && (
                        <img
                          onClick={() => onDeleteProgramDay({ index })}
                          className="web-item__product-delete program"
                          src={CreateWebDelete}
                          alt="delete"
                        />
                      )}
                  </div>
                  {!isDateValid(day.date) && (
                    <span className="input-error">Это поле обязательно</span>
                  )}
                </div>
              </div>
            </div>
            {day.events.map((event, i) => (
              <div key={i} className="web-item__program-times">
                <div className="input web-item__time-wrapper">
                  <label style={{ marginBottom: 5 }} className="input__label">
                    Время
                  </label>
                  <div className="web-item__program-time">
                    <div className="web-item__time-input-wrapper">
                      <TimePicker
                        value={event.timeStart}
                        onChange={(date) =>
                          onProgramTimeStartChange({
                            date,
                            index,
                            eventIndex: i,
                          })
                        }
                        placeholder="00:00"
                        className={cn("input__date default-input", {
                          "default-input--error": !isDateValid(event.timeStart),
                          "default-input--filled": event.timeStart,
                        })}
                        disabled={isDisabled || isEventInputDisabled(event)}
                      />
                      {!isDateValid(event.timeStart) && (
                        <span className="input-error">Это поле обязательно</span>
                      )}
                    </div>
                    <div className="web-item__time-input-wrapper">
                      <TimePicker
                        value={event.timeEnd}
                        onChange={(date) =>
                          onProgramTimeEndChange({
                            date,
                            index,
                            eventIndex: i,
                          })
                        }
                        placeholder="00:00"
                        className={cn("input__date default-input", {
                          "default-input--error": !isDateValid(event.timeEnd),
                          "default-input--filled": event.timeEnd,
                        })}
                        minTime={event?.timeStart && new Date(event.timeStart)}
                        disabled={isDisabled || isEventInputDisabled(event)}
                      />
                      {!isDateValid(event.timeEnd) && (
                        <span className="input-error">Это поле обязательно</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="input input__program--event">
                  <label style={{ marginBottom: 5 }} className="input__label">
                    Событие {i + 1}
                  </label>
                  <div className="web-item__program-description">
                    <div className="web-item__program-description-wrapper">
                      <input
                        value={event.description || ""}
                        onChange={(e) =>
                          onProgramEventDescriptionChange({
                            eventIndex: i,
                            index,
                            newValue: e.target.value,
                          })
                        }
                        className={cn("default-input", {
                          "default-input--error": isShowErrors && !event.description,
                          "default-input--filled": event.description,
                        })}
                        placeholder="Опиcание события"
                        type="text"
                        disabled={isDisabled || isEventInputDisabled(event)}
                      />
                      {day.events.length > 1 && !isEventInputDisabled(event) && (
                        <img
                          onClick={() => onDeleteProgramEvent({ index, eventIndex: i })}
                          className="web-item__program-description-delete"
                          src={CreateWebDelete}
                          alt="delete"
                        />
                      )}
                    </div>
                    {isShowErrors && !event.description && (
                      <span className="input-error">Это поле обязательно</span>
                    )}
                  </div>
                </div>
              </div>
            ))}
            <button
              onClick={() => onAddNewProgramEvent({ index })}
              className="popup-user__add popup-user__add--web popup-user__add--product"
            >
              Добавить событие
            </button>
          </div>
        ))}
      </div>
      <div
        onClick={onAddNewProgramDay}
        className="popup-user__add popup-user__add--web popup-user__add--product popup-user__add--date"
      >
        Новая дата
      </div>
    </>
  );
};

export default ProgramEvents;
