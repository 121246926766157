import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AcceptArrowGreen, AcceptArrowGrey } from "../../assets";

const getSpecsForTariff = (tariff) => {
  const isSmall = tariff === "small";
  const isMedium = tariff === "medium";
  const isLarge = tariff === "large";

  return [
    { name: "Выбор шаблона стенда", value: true },
    {
      name: "Медиа поверхности",
      value: isSmall ? 3 : isMedium ? 4 : 5,
    },
    {
      name: "Лого на стенде",
      value: true,
    },
    {
      name: "Виртуальный хостес",
      value: !isSmall,
    },
    {
      name: "Аудио  приветствие",
      value: !isSmall,
    },
    {
      name: "Рекламный баннер в лобби зала",
      value: isLarge,
    },
    {
      name: "Автоматизированные рассылки, приглашения",
      value: true,
    },
    {
      name: "Шаблон лендинга",
      value: true,
    },
    {
      name: "Организация вебинара",
      value: !isSmall,
    },
    {
      name: "Сбор и выгрузка контактов",
      value: true,
    },
    {
      name: "Чат с посетителями",
      value: true,
    },
    {
      name: "Чат с организатором",
      value: true,
    },
    {
      name: "Тех поддержка",
      value: true,
    },
  ];
};

const getNameForTariff = (tariff) => {
  const isSmall = tariff === "small";
  const isMedium = tariff === "medium";
  const isLarge = tariff === "large";

  if (isSmall) return "Стандарт";
  if (isMedium) return "Бизнес";
  if (isLarge) return "Премиум";
};

const fakeStands = [
  {
    count: 20,
    currency: "RUB",
    id: 1,
    name: "Стандартный",
    price: 0,
    type: "small",
  },
  {
    count: 8,
    currency: "RUB",
    id: 2,
    name: "Бизнес",
    price: 0,
    type: "medium",
  },
  {
    count: 6,
    currency: "RUB",
    id: 3,
    name: "Премиальный",
    price: 0,
    type: "large",
  },
];

export const useParticipantTariffs = (props) => {
  // onTariffChange is only useCallback function
  const { currentTariffId, onTariffChange, isPlaceholders } = props || {};

  const [tariffs, setTariffs] = useState([]);

  const withChooseButton = !!onTariffChange;

  const stands = useSelector((state) => state.events.tariffStands);

  useEffect(() => {
    if ((stands && stands.length > 0) || isPlaceholders) {
      const tariffStands = isPlaceholders ? fakeStands : stands;

      const changedTariffs = Array.from(tariffStands)
        .map((t) => ({
          ...t,
          specs: Array.from(getSpecsForTariff(t.type)),
          name: getNameForTariff(t.type) || t.name,
        }))
        .reverse();

      let items = [["name"]];
      let keyPrice = 0;
      for (let k = 0; k < changedTariffs.length; k++) {
        let stand = changedTariffs[k];
        items[0].push(stand.name);
        if (stand.specs) {
          for (let i = 0; i < stand.specs.length; i++) {
            let spec = stand.specs[i];
            let key = items.findIndex((it) => it[0] === spec.name);
            let item = [spec.name];
            if (key !== -1) {
              item = items[key];
            }
            item.push(spec.value);
            if (key === -1) {
              items.push(item);
            } else {
              items[key] = item;
            }
          }
        }
        if (!k && !isPlaceholders) {
          items.push(["price"]);
          if (withChooseButton) {
            keyPrice = items.push([<span> </span>]);
            keyPrice -= 1;
          }
        }

        let key = items.findIndex((it) => it[0] === "price");
        if (key !== -1) {
          items[key].push(stand.price + " ₽");
        }

        if (withChooseButton) {
          items[keyPrice].push(
            <button
              onClick={() => onTariffChange(stand)}
              className={classNames("blue-btn", {
                "blue-btn--active": stand.id === currentTariffId,
              })}
            >
              Выбрать
            </button>
          );
        }
      }

      setTariffs(items);
    }
  }, [stands, currentTariffId, withChooseButton, onTariffChange, isPlaceholders]);

  return { tariffs };
};

export const ParticipantTariffsTable = ({ tariffs }) => {
  if (!tariffs) return null;

  return (
    <table className="create-stand-content__price-table">
      <tbody>
        {tariffs.map((t, key) => {
          let className = "create-stand-content__price-row";
          let classNameSpec = "create-stand-content__price-cell";
          if (t[0] === "name") {
            className += " create-stand-content__price-header";
            classNameSpec += " create-stand-content__price-cell--bold";
          } else if (t[0] === "price") {
            className += " create-stand-content__price-footer";
          }
          return (
            <tr key={key} className={className}>
              {t.map((it, keySc) => {
                let title = <span>{it}</span>;
                if (it === "name") {
                  title = <span>Выбор тарифа</span>;
                } else if (it === "price") {
                  title = <span>Стоимость</span>;
                } else if (typeof it === "boolean") {
                  title = it ? (
                    <img alt={""} src={AcceptArrowGreen} />
                  ) : (
                    <img alt={""} src={AcceptArrowGrey} />
                  );
                }
                return (
                  <td key={key + "" + keySc} className={classNameSpec}>
                    {title}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const ParticipantStandsCount = ({ stands }) => {
  return (
    <table className="create-stand-content__price-table">
      <tbody>
        <tr className="create-stand-content__price-row create-stand-content__price-header">
          <td className="create-stand-content__price-cell create-stand-content__price-cell--bold"></td>
          {stands.map((st, ind) => {
            return (
              <td
                key={ind}
                className="create-stand-content__price-cell create-stand-content__price-cell--bold"
              >
                {getNameForTariff(st.type)}
              </td>
            );
          })}
        </tr>
        <tr className="create-stand-content__price-row create-stand-content__price-footer">
          <td className="create-stand-content__price-cell">Кол-во стендов</td>
          {stands.map((st, ind) => {
            return (
              <td
                key={ind}
                className="create-stand-content__price-cell create-stand-content__price-cell--bold"
              >
                {st.count}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};
