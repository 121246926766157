export const convertRole = (role) => {
  if (role === "Viewer" || role === 10) {
    return "посетитель";
  }

  if (role === "Participant" || role === 20) {
    return "участник";
  }

  if (role === "Organizer" || role === 50) {
    return "организатор";
  }

  if (role === "Support") {
    return "тех. поддержка";
  }

  return role;
};
