import React from "react";
import { CloseSvg } from "../../assets";

const UploadPopup = ({ title, onCloseClick, onLoadClick, dropField }) => {
  return (
    <div className="upload-popup">
      <div className="upload-popup__bg"></div>
      <div className="upload-popup__main">
        <div className="upload-popup__header">
          <h3>{title}</h3>
          <button onClick={onCloseClick}>
            <img src={CloseSvg} alt="#" />
          </button>
        </div>
        {dropField}
        <div className="upload-popup__buttons">
          <button className="upload-popup__cancel-btn white-btn" onClick={onCloseClick}>
            Отмена
          </button>
          <button onClick={onLoadClick} className="upload-popup__load-btn red-btn">
            Загрузить
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadPopup;
