import React from "react";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";

const CustomCalendarToolbar = (props) => {
  const navigate = (action) => {
    props.onNavigate(action);
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={() => navigate("PREV")}>
          назад
        </button>
        <button type="button" onClick={() => navigate("TODAY")}>
          сегодня
        </button>
        <button type="button" onClick={() => navigate("NEXT")}>
          вперед
        </button>
      </span>
      <span className="rbc-toolbar-label">{capitalizeFirstLetter(props.label)}</span>
    </div>
  );
};

export default CustomCalendarToolbar;
