import React from "react";
import { useConfirmPopup } from "../../../hooks/useConfirmPopup";
import { errorHandler } from "../../../utils/errorHandler";
import { ConfirmPopup } from "../../shared";

const ConstructorButtons = ({ onCreatePlan, disabled = false }) => {
  const { popupInfo, setPopupInfo, closePopup } = useConfirmPopup();

  const onCreateClick = () => {
    setPopupInfo({
      isOpened: true,
      onCancelClick: closePopup,
      onConfirmClick: onCreateConfirmClick,
      text: "Вы действительно хотите сохранить план павильона?",
      title: "Сохранение павильона",
    });
  };

  const onCreateConfirmClick = () => {
    try {
      onCreatePlan();

      closePopup();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <div className="create-event__constructor-buttons">
      {popupInfo.isOpened && (
        <ConfirmPopup popupInfo={popupInfo} onCloseClick={closePopup} />
      )}
      <button
        onClick={onCreateClick}
        className="red-btn create-event__create-constructor"
        disabled={disabled}
      >
        Создать план
      </button>
    </div>
  );
};

export default ConstructorButtons;
