import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchStand } from "../../redux/eventReducer";
import { errorHandler } from "../../utils/errorHandler";
import { Nav } from "../organizer-panel";
import Loader from "../shared/Loader";
import NavStandIcons from "./NavStandIcons";

const NavStand = () => {
  const role = useSelector((state) => state.user.profile.role);

  const { id } = useParams();

  const dispatch = useDispatch();

  const currentStand = useSelector((state) => state.events.currentStand);

  const [standStatusType] = useSelector(
    (state) => state.events.currentStand?.status_type
  ) || ["actual"];

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (role === "участник" && currentStand) {
      setIsLoading(false);
    }
  }, [currentStand, role]);

  useEffect(() => {
    try {
      if (role === "участник") {
        setIsLoading(true);
        dispatch(fetchStand({ standId: id }));
      }
    } catch (error) {
      errorHandler(error);
    }
  }, [dispatch, id, role]);

  if (role === "организатор") {
    return <Nav />;
  }

  return (
    <nav className="nav-profile">
      {isLoading ? (
        <div className="nav-profile__loader">
          <Loader />
        </div>
      ) : (
        <NavStandIcons statusType={standStatusType} />
      )}
    </nav>
  );
};

export default NavStand;
