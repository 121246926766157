import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { fetchStats } from "../../redux/eventReducer";

const FavoriteNav = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const stats = useSelector((state) => state.events.stats);

  useEffect(() => {
    dispatch(fetchStats());
  }, [dispatch, profile.role]);

  return (
    <nav className="favorite-nav">
      <NavLink to={"/favorite/actual"} activeClassName="active">
        <div className="favorite-nav__item">
          Актуальные <span>{stats.actual}</span>
        </div>
      </NavLink>
      <NavLink to={"/favorite/past"} activeClassName="active">
        <div className="favorite-nav__item">
          Прошедшие <span>{stats.pass}</span>
        </div>
      </NavLink>
      {(profile.role === "участник" || profile.role === "организатор") && (
        <>
          <NavLink to={"/favorite/filling"} activeClassName="active">
            <div className="favorite-nav__item">
              {profile.role === "организатор" ? "Оплата" : "На наполнении"}{" "}
              <span>{stats.filling}</span>
            </div>
          </NavLink>
          <NavLink to={"/favorite/requests"} activeClassName="active">
            <div className="favorite-nav__item">
              Заявки <span>{stats.requests}</span>
            </div>
          </NavLink>
        </>
      )}
    </nav>
  );
};

export default FavoriteNav;
