import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const MailingNav = () => {
  const { type, id } = useParams();
  const mailingTemplates = useSelector((state) => state.mailing.mailingTemplates);
  const mailings = useSelector((state) => state.mailing.mailings);

  return (
    <nav className="mailing-nav">
      <div className="favorite-nav favorite-nav--notification">
        <NavLink to={`/${type}/${id}/settings/mailing`} exact activeClassName="active">
          <div className="favorite-nav__item">
            Отправленные рассылки <span>{mailings ? mailings.length : 0}</span>
          </div>
        </NavLink>
        <NavLink
          to={`/${type}/${id}/settings/mailing/templates`}
          activeClassName="active"
        >
          <div className="favorite-nav__item">
            Шаблоны <span>{mailingTemplates ? mailingTemplates.length : 0}</span>
          </div>
        </NavLink>
      </div>
    </nav>
  );
};

export default MailingNav;
