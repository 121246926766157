import React, { useState } from "react";
import { ChromePicker } from "react-color";
import { cmykToRgb, rgbToCmyk } from "../../../utils/rgbToCmyk";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { editStandColor } from "../../../api/stand";
import { updateMaskColor } from "../../../redux/eventReducer";
import { errorHandler } from "../../../utils/errorHandler";
import { immediateToast } from "izitoast-react";
import ColorPopupFooter from "./ColorPopupFooter";

const ColorPicker = ({ colorMask, closePopup }) => {
  const dispatch = useDispatch();
  const { id, type } = useParams();

  const splitedRgbString = colorMask?.color?.split(",").map((s) => parseInt(s)) || [];

  const initialColor = colorMask.color
    ? { r: splitedRgbString[0], g: splitedRgbString[1], b: splitedRgbString[2] }
    : { r: 0, g: 0, b: 0 };

  const [cmykColor, setCmykColor] = useState(
    rgbToCmyk(initialColor.r, initialColor.g, initialColor.b, false)
  );

  const rgbColor = cmykToRgb(cmykColor.c, cmykColor.m, cmykColor.y, cmykColor.k, false);

  const onCmykColorChange = ({ c, m, y, k }) => {
    const cmykParams = { ...cmykColor };

    if (c || c === "") {
      cmykParams.c = c;
    }

    if (m || m === "") {
      cmykParams.m = m;
    }

    if (y || y === "") {
      cmykParams.y = y;
    }

    if (k || k === "") {
      cmykParams.k = k;
    }

    setCmykColor(cmykParams);
  };

  const onConfirmClick = async () => {
    try {
      const rgbString = `${rgbColor.r},${rgbColor.g},${rgbColor.b}`;

      await editStandColor({
        objectName: colorMask.name,
        color: rgbString,
        [`${type}Id`]: id,
      });
      dispatch(updateMaskColor({ id: colorMask.id, color: rgbString }));
      immediateToast("success", {
        message: "Цвет успешно обновлен.",
        position: "topCenter",
      });
      closePopup();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <div className="color-popup__main">
        <ChromePicker
          color={rgbColor}
          disableAlpha
          onChange={(color) => {
            const { r, g, b } = color.rgb;
            setCmykColor(rgbToCmyk(r, g, b));
          }}
        />
        <div className="color-popup__cmyk-inputs">
          <div className="color-popup__cmyk-item">
            <span>C</span>
            <input
              type="text"
              value={cmykColor.c}
              onChange={(e) => {
                onCmykColorChange({ c: e.target.value });
              }}
              className="default-input"
            />
          </div>
          <div className="color-popup__cmyk-item">
            <span>M</span>
            <input
              type="text"
              value={cmykColor.m}
              onChange={(e) => {
                onCmykColorChange({ m: e.target.value });
              }}
              className="default-input"
            />
          </div>
          <div className="color-popup__cmyk-item">
            <span>Y</span>
            <input
              type="text"
              value={cmykColor.y}
              onChange={(e) => {
                onCmykColorChange({ y: e.target.value });
              }}
              className="default-input"
            />
          </div>
          <div className="color-popup__cmyk-item">
            <span>K</span>
            <input
              type="text"
              value={cmykColor.k}
              onChange={(e) => {
                onCmykColorChange({ k: e.target.value });
              }}
              className="default-input"
            />
          </div>
        </div>
      </div>
      <ColorPopupFooter onCancelClick={closePopup} onConfirmClick={onConfirmClick} />
    </>
  );
};

export default ColorPicker;
