import { useState } from "react";

export const useFavoritePopup = () => {
  const [popupInfo, setPopupInfo] = useState({
    isOpened: false,
    selectedEvent: null,
  });

  const closePopup = () => {
    setPopupInfo((i) => ({ ...i, isOpened: false }));
  };

  return {
    popupInfo,
    setPopupInfo,
    closePopup,
  };
};
