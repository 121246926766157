import queryString from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import Chat from "../shared/Chat";

const Sidebar = () => {
  const history = useHistory();

  const closeSidebar = () => {
    history.push(
      queryString.exclude(history.location.pathname + history.location.search, [
        "sidebar",
      ])
    );
  };

  return (
    <div className="events-sidebar">
      <Chat isOnVrEvent={true} closePopup={closeSidebar} />
    </div>
  );
};

export default Sidebar;
