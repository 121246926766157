import classNames from "classnames";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { loginUser } from "../../api";
import { EntryHeader, EntryLogo, MainAbout } from "../../components/entry";
import { Button } from "../../components/shared";
import { setAccount, setProfileInfo } from "../../redux/userReducer";

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const [error, setError] = React.useState("");

  const isAllow = () => {
    if (validateEmail(email) && password.length >= 5) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async (evt) => {
    evt.preventDefault();
    try {
      const obj = {
        email: email,
        password: password,
      };
      const { data } = await loginUser(obj.email, obj.password);

      if (data && data.success && data.data.user) {
        let user = data.data.user;
        user.token = data.data.token;
        if (user) {
          dispatch(setProfileInfo(user));
          dispatch(setAccount(user));
          history.push("/events/list");
        }
      }
    } catch (error) {
      setError("Неверный или неподтвержденный логин/пароль");
    }
  };

  return (
    <div className="registration registration--login">
      <EntryLogo />
      <MainAbout noScroll />
      <main className="registration-form">
        <EntryHeader login />
        <div className="registration-form__main">
          <h3 className="registration-form__title">Вход JUSTEVENTS</h3>
          <form className="registration-form__inputs">
            <div className="input">
              <label className="input__label" htmlFor="currentPassword">
                E-mail*
              </label>
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError("");
                }}
                className={classNames(
                  "input__main",
                  { error: email && !validateEmail(email) },
                  { filled: validateEmail(email) }
                )}
                placeholder="Введите e-mail"
                type="email"
                id="email"
              />
              {email && !validateEmail(email) ? (
                <p className="input__text">Неверный формат электронной почты</p>
              ) : null}
            </div>
            <div className="input input--profile">
              <label className="input__label" htmlFor="password">
                Пароль
              </label>
              <input
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError("");
                }}
                className={classNames(
                  "input__main",
                  { error: password.length >= 1 && password.length < 5 },
                  { filled: password.length >= 5 }
                )}
                placeholder="Введите пароль"
                type="password"
                id="password"
              />
              <Link to="/entry/reset" className="input__password">
                Забыли пароль?
              </Link>
              {password.length >= 1 && password.length < 5 ? (
                <p className="input__text">Поле должно содержать не менее 5 символов</p>
              ) : null}
            </div>
            {error && <p className="registration-form__error">{error}</p>}
            <div className="registration-form__submit">
              <Button onClick={onSubmit} disabled={!isAllow() || error}>
                Войти
              </Button>
            </div>
          </form>
        </div>
        {/* <EntryFooter login /> */}
      </main>
    </div>
  );
};

export default Login;
