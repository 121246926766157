import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getMyEvents } from "../../api";
import { getStandInfo } from "../../api/stand";
import { useFavoritePopup } from "../../hooks/useFavoritePopup";
import { setCreateStandInfo } from "../../redux/appReducer";
import { errorHandler } from "../../utils/errorHandler";
import { prepareEventList } from "../../utils/prepareEventList";
import { FavoritePopup } from "../favorite";
import EventNotificationPopup from "./EventNotificationPopup";
import useAbortController from "./../../utils/useAbortController";
import { wikiInfoIcon } from "../../assets";

const Sidebar = () => {
  const localStorageEvents = JSON.parse(localStorage.getItem("sidebarEvents"));

  const history = useHistory();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.profile.role);
  const [events, setEvents] = useState(localStorageEvents || []);
  const abortController = useAbortController(false);

  const [eventNotificationPopup, setEventNotificationPopup] = useState(false);
  const [notificationText, setNotificationText] = useState("");

  const { closePopup, popupInfo, setPopupInfo } = useFavoritePopup();

  const onEventClick = async ({ startDate, id }) => {
    localStorage.setItem("goToSettings", "/favorite/actual");

    const selectedEvent = events.find((e) => e.id === id);

    if (role === "участник") {
      const { data } = await getStandInfo({ eventId: selectedEvent.id });

      const standInfo = data.data.info;

      const standTemplateInfo = data.data.stand_tmpl;

      dispatch(
        setCreateStandInfo({
          standId: data.data?.id || "",
          companyName: data.data?.company || "",
          branch: data.data?.occupation_id || "",
          logo: data.data?.logo || "",
          templateId: standTemplateInfo?.id || "",
          shortDescription: standInfo?.short || "",
          organization: standInfo?.organization || "",
          inn: standInfo?.inn || "",
          registrationNumber: standInfo?.registration_number || "",
          bic: standInfo?.bic || "",
          bankName: standInfo?.bank_name || "",
          billNumber: standInfo?.bill_number || "",
          correspondingNumber: standInfo?.corresponding_number || "",
          tarif: "",
          standPositionId: standTemplateInfo?.id || "",
        })
      );

      return setPopupInfo({
        isOpened: true,
        selectedEvent: { ...selectedEvent, type: "actual" },
      });
    }

    if (role === "организатор") {
      return setPopupInfo({
        isOpened: true,
        selectedEvent: { ...selectedEvent, type: "actual" },
      });
    }

    if (role === "посетитель" && startDate < new Date()) {
      return setPopupInfo({
        isOpened: true,
        selectedEvent,
      });
    }

    if (role === "посетитель" && startDate > new Date()) {
      setNotificationText("Мероприятие ещё не началось");
      return setEventNotificationPopup(true);
    }
  };

  const getEvents = useCallback(async () => {
    try {
      const { data } = await getMyEvents({ date_type: "actual" });

      if (abortController.signal.aborted) {
        return;
      }
      const eventList = data.data.data;

      const preparedEvents = prepareEventList(eventList);

      setEvents(preparedEvents);
      localStorage.setItem("sidebarEvents", JSON.stringify(preparedEvents));
    } catch (error) {
      errorHandler(error);
    }
  }, [abortController]);

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  return (
    <nav className="sidebar">
      <div className="sidebar__top">
        <Link to="/events/list">
          <div
            className={classNames("sidebar__item", {
              active:
                history.location.pathname.includes("/events") |
                history.location.pathname.includes("/favorite"),
            })}
          >
            <div className="sidebar__home-icon">
              <svg width="25" height="21" viewBox="0 0 25 21" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.0731 0C12.2362 0 12.3956 0.0491647 12.5304 0.141083L23.7887 7.70524C23.8869 7.76929 23.97 7.85385 24.0324 7.95308C24.0948 8.05232 24.135 8.16389 24.1501 8.28012C24.1653 8.39635 24.1551 8.51449 24.1203 8.62641C24.0855 8.73834 24.0268 8.84139 23.9484 8.9285L23.0201 9.98396C22.8895 10.1378 22.7062 10.2374 22.506 10.2634C22.3059 10.2894 22.1033 10.24 21.9376 10.1247L12.5304 3.80814C12.3956 3.71623 12.2362 3.66706 12.0731 3.66706C11.9099 3.66706 11.7505 3.71623 11.6157 3.80814L2.21936 10.1382C2.05371 10.2535 1.85106 10.303 1.65092 10.2769C1.45078 10.2509 1.26751 10.1513 1.13684 9.99749L0.208568 8.94203C0.13013 8.85492 0.071483 8.75187 0.0366585 8.63994C0.00183394 8.52802 -0.00834222 8.40988 0.00682958 8.29365C0.0220014 8.17742 0.0621613 8.06585 0.124549 7.96661C0.186936 7.86738 0.270072 7.78283 0.368241 7.71877L11.6157 0.141083C11.7505 0.0491647 11.9099 0 12.0731 0ZM12.5331 5.09911L20.3652 10.3981C20.4751 10.4737 20.5647 10.5752 20.6261 10.6937C20.6875 10.8122 20.7187 10.944 20.717 11.0774V19.5671C20.717 19.7824 20.6314 19.9889 20.4792 20.1412C20.3269 20.2934 20.1204 20.379 19.9051 20.379H15.5912C15.3759 20.379 15.1694 20.2934 15.0171 20.1412C14.8649 19.9889 14.7793 19.7824 14.7793 19.5671V14.325C14.7793 14.1096 14.6938 13.9031 14.5415 13.7509C14.3893 13.5986 14.1828 13.5131 13.9674 13.5131H10.1786C9.96325 13.5131 9.75674 13.5986 9.60448 13.7509C9.45222 13.9031 9.36668 14.1096 9.36668 14.325V19.5671C9.36668 19.7824 9.28114 19.9889 9.12888 20.1412C8.97662 20.2934 8.77011 20.379 8.55479 20.379H4.22468C4.00935 20.379 3.80284 20.2934 3.65058 20.1412C3.49832 19.9889 3.41278 19.7824 3.41278 19.5671V11.0774C3.41287 10.9425 3.44657 10.8097 3.51083 10.6911C3.57509 10.5725 3.66789 10.4718 3.78084 10.3981L11.6129 5.09911C11.7483 5.00602 11.9087 4.95618 12.073 4.95618C12.2373 4.95618 12.3977 5.00602 12.5331 5.09911Z"
                  fill={
                    history.location.pathname.includes("/events") ||
                    history.location.pathname.includes("/favorite")
                      ? "#fff"
                      : "#727E8E"
                  }
                />
              </svg>
            </div>
          </div>
        </Link>
        {events.map((event) => (
          <div
            key={event.id}
            onClick={() => onEventClick({ id: event.id, startDate: event.startDate })}
            className={classNames("sidebar__item sidebar__event", {
              active:
                history.location.pathname.includes(`/event/${event.id}`) ||
                history.location.pathname.includes(`/stand/${event.id}`),
            })}
          >
            <div className="sidebar__icon sidebar__icon-text">
              {event.title.slice(0, 4)}
            </div>
          </div>
        ))}
        {popupInfo.isOpened && (
          <FavoritePopup closePopup={closePopup} popupInfo={popupInfo} />
        )}
        {eventNotificationPopup && (
          <EventNotificationPopup
            popupText={notificationText}
            closePopup={() => setEventNotificationPopup(false)}
          />
        )}
      </div>
      <div className="sidebar__bottom">
        <a href="/wiki" target="_blank" rel="norefferer" className="sidebar__info-link">
          <img src={wikiInfoIcon} alt="" />
        </a>
      </div>
    </nav>
  );
};

export default Sidebar;
