import { immediateToast } from "izitoast-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOrderBanners } from "../../api/banners";
import { changeTariff } from "../../api/organizer";
import { pageNames } from "../../components/organizer-panel/CreateEvent/EventSidebar";
import { ConfirmPopup, Layout } from "../../components/shared";
import { NavStand, StandHeader } from "../../components/stand";
import { PromotionExample } from "../../components/stand/promotion";
import { TariffsNav } from "../../components/stand/tariffs";
import { useCreateEventPageDisabled } from "../../hooks/create-event/usePageDisabled";
import { useConfirmPopup } from "../../hooks/useConfirmPopup";
import { fetchEvent, setFillingPageName } from "../../redux/eventReducer";
import { errorMessage } from "../../utils/errorMessage";

const TariffsAds = () => {
  const { id: eventId } = useParams();
  const { popupInfo, setPopupInfo, closePopup } = useConfirmPopup();
  const [isPublished, setIsPublished] = useState(false);

  const dispatch = useDispatch();

  const currentEvent = useSelector((state) => state.events.currentEvent);
  const currentPageFillingIndex = currentEvent?.fillingPageIndex;

  const [banners, setBanners] = useState([]);

  const tariffBanners = useSelector((state) => state.events.currentEvent?.tariff_banners);
  const roomViewerUrl = useSelector((state) => state.app.settings.roomViewerUrl);
  const [bannerTypes, setBannerTypes] = useState([]);

  const onBannerPriceChange = (id, value) => {
    setBanners((prev) => prev.map((b) => (b.id === id ? { ...b, price: value } : b)));
  };

  useEffect(() => {
    const getInitialBanners = async () => {
      try {
        const { data } = await getOrderBanners({ eventId });
        const banners = data?.order_banners || [];
        setBannerTypes(banners);
      } catch (error) {
        console.log(error);
      }
    };

    getInitialBanners();
  }, [eventId]);

  useEffect(() => {
    if (tariffBanners && tariffBanners.length) {
      setBanners(tariffBanners);
    }
  }, [tariffBanners]);

  useEffect(() => {
    const isPublished = currentPageFillingIndex === 1.1 || currentPageFillingIndex >= 2;
    setIsPublished(isPublished);
  }, [currentPageFillingIndex]);

  // if not published, set initial banner types from orderbanners
  useEffect(() => {
    if (!isPublished && bannerTypes && bannerTypes.length) {
      setBanners(Array.from(bannerTypes).map((b) => ({ ...b, price: "" })));
    }
  }, [bannerTypes, isPublished, banners?.length]);

  const onPublishBtnClick = () => {
    setPopupInfo({
      title: "Публикация",
      text: "Вы действительно хотите опубликовать тарифы баннеров?",
      onCancelClick: closePopup,
      onConfirmClick: onPublishConfirmClick,
      isOpened: true,
    });
  };

  const onPublishConfirmClick = async () => {
    try {
      if (filteredBanners?.some((t) => String(t.price).trim() === "")) {
        errorMessage("Заполните все тарифы участия");
        closePopup();
        return;
      }

      await changeTariff({
        eventId,
        setting: "banners",
        items: banners,
      });

      dispatch(
        setFillingPageName({
          eventId,
          name: pageNames.JURIDICAL_INFO,
        })
      );

      dispatch(fetchEvent({ eventId }));

      immediateToast("success", {
        message: "Тарифы баннеров успешно опубликованы",
        position: "topCenter",
      });

      setIsPublished(true);
      closePopup();
    } catch (error) {
      immediateToast("error", {
        message: "Произошла ошибка",
        position: "topCenter",
      });

      console.log(error);
    }
  };

  const onEditBtnClick = () => {
    setPopupInfo({
      title: "Редактирование",
      text: "Вы действительно хотите редактировать уже опубликованные тарифы баннеров?",
      onCancelClick: closePopup,
      onConfirmClick: onEditConfirmClick,
      isOpened: true,
    });
  };

  const onEditConfirmClick = async () => {
    try {
      setIsPublished(false);
      closePopup();
    } catch (error) {
      immediateToast("error", {
        message: "Произошла ошибка",
        position: "topCenter",
      });

      console.log(error);
    }
  };

  const isPageDisabled = useCreateEventPageDisabled(pageNames.TARIFF_ADS);

  const filteredBanners = banners
    ? Array.from(banners).filter((b) => b.type !== "reception")
    : [];

  return (
    <Layout>
      {popupInfo.isOpened && (
        <ConfirmPopup popupInfo={popupInfo} onCloseClick={closePopup} />
      )}
      <div className="organizer-panel__main">
        <NavStand />
        <div className="stand-settings">
          <StandHeader title="Тарифы" withHint={isPageDisabled} />
          <div className="participants">
            <TariffsNav />
            <div className="participants-main">
              <div className="participants__tarifs">
                <h2 className="participants__tarifs-header">
                  Тарифы рекламы редактирование
                </h2>
                <div className="participants__stand-tarifs stand-tarifs">
                  <table className="table table-bordered participants-table stand-tarifs__table">
                    <thead className="table__head">
                      <tr>
                        <th>Тип баннера</th>
                        <th>Стоимость за 1 день</th>
                      </tr>
                    </thead>
                    <tbody className="table__body">
                      {filteredBanners.map((s, i) => (
                        <tr key={i}>
                          <td>{s.name}</td>
                          <td>
                            <input
                              type="text"
                              placeholder="Введите стоимость"
                              disabled={isPublished}
                              value={s.price}
                              onChange={(e) => onBannerPriceChange(s.id, e.target.value)}
                            />
                          </td>
                        </tr>
                      ))}
                      {!banners?.length && (
                        <tr>
                          <td
                            style={{
                              padding: 10,
                              fontSize: 14,
                            }}
                            colSpan="2"
                          >
                            Идёт загрузка...
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="stand-tarifs__buttons">
                    {isPublished ? (
                      <button
                        onClick={onEditBtnClick}
                        className="red-btn"
                        disabled={isPageDisabled}
                      >
                        Редактировать
                      </button>
                    ) : (
                      <button
                        onClick={onPublishBtnClick}
                        className="red-btn"
                        disabled={isPageDisabled}
                      >
                        Опубликовать
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="participants__ads-widget-wrapper">
                <PromotionExample
                  eventId={isNaN(eventId) ? null : eventId}
                  roomViewerUrl={roomViewerUrl}
                  withReception={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TariffsAds;
