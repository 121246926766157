import React from "react";
import { Sidebar, SidePopupChat, Header } from ".";

export const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Sidebar />
      <SidePopupChat />
      <Header />
      <div className="layout__main">{children}</div>
    </div>
  );
};
