import React from "react";
import { Element } from "react-scroll";
import { clearString } from "../../../../utils";

const DemoProducts = ({ colorTitle, colorText, products }) => {
  return (
    products &&
    products.length > 0 && (
      <Element name="Продукция" className="demo-section">
        <div className="demo__container">
          <h3 className="demo-section__title" style={{ color: colorTitle }}>
            Продукция
          </h3>
          <div className="demo-products__main">
            {products.map(
              (p, i) =>
                ((p && p.img) || (p && p.name)) && (
                  <div key={i} className="demo-product__item">
                    <img className="demo-product__photo" src={p.img} alt="" />
                    <div className="demo-product__name" style={{ color: colorText }}>
                      {clearString(p.name)}
                    </div>
                    {p.description && (
                      <div className="demo-product__desc" style={{ color: colorText }}>
                        {clearString(p.description)}
                      </div>
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      </Element>
    )
  );
};

export default DemoProducts;
