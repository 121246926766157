import cn from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { createEventHintSvg, createEventWarningSvg } from "../../../assets";
import ConstructorButtons from "./ConstructorButtons";

const EventHeader = ({
  text,
  onCreatePlan,
  warningText = "",
  SaveButton,
  btnsDisabled = false,
}) => {
  const currentEvent = useSelector((state) => state.events.currentEvent);
  const [eventStatusType] = currentEvent?.status_type || [];

  let hintText =
    !eventStatusType || eventStatusType === "requests"
      ? "Редактирование данного раздела будет невозможно после создания мероприятия."
      : "Данная страница недоступна к редактированию";
  let hintIcon = createEventHintSvg;

  if (warningText && eventStatusType === "requests") {
    hintText = warningText;
    hintIcon = createEventWarningSvg;
  }

  return (
    <>
      <div className="create-stand-content__header">
        <h2 className="create-stand-content__title">Создание мероприятия</h2>
        <div className="create-stand-content__buttons">
          {eventStatusType === "requests" && onCreatePlan && (
            <ConstructorButtons disabled={btnsDisabled} onCreatePlan={onCreatePlan} />
          )}
          {SaveButton}
        </div>
      </div>
      <div className="create-stand-content__posttitle">
        <span>{text}</span>
      </div>
      <div
        className={cn("create-stand-content__hint", {
          "create-stand-content__hint--warning":
            warningText && eventStatusType === "requests",
        })}
      >
        <img src={hintIcon} alt="" />
        {hintText}
      </div>
    </>
  );
};

export default EventHeader;
