import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { demoSiteBack, demoLogo } from "../../../../assets";

const DemoHeader = () => {
  const profile = useSelector((state) => state.user.profile);
  const { id } = useParams();
  const getBackLink = () => {
    if (profile.role === "участник") {
      return `/stand/${id}/settings/create-web`;
    } else if (profile.role === "организатор") {
      return `/event/${id}/settings/create-web`;
    }
  };

  return (
    <header className="demo-header">
      <div className="demo__container">
        <Link to={getBackLink()}>
          <div className="demo-header__main">
            <div className="demo-header__back">
              <img src={demoSiteBack} alt="Back Icon" />
            </div>
            <div className="demo-header__logo">
              <img src={demoLogo} alt="Logo Img" />
            </div>
            <div className="demo-header__text">Вернуться на платформу</div>
          </div>
        </Link>
      </div>
    </header>
  );
};

export default DemoHeader;
