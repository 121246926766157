import axios from "axios";
import moment from "moment";
import { getToken, instance } from ".";

const convertTbeg = (dateStart) => {
  return moment(dateStart).format("YYMMDD");
};

const convertTend = (dateEnd) => {
  return moment(dateEnd).add(1, "days").format("YYMMDD");
};

/**
 * @param placeType event or stand
 * @param placeId number
 * @param dateStart '%y%m%dT%H%M%S'
 * @param dateEnd '%y%m%dT%H%M%S'
 * @returns response
 *
 * @deprecated
 */
export const getAnalyticAttendance = ({ placeType, placeId, dateStart, dateEnd }) => {
  const tbeg = convertTbeg(dateStart);
  const tend = convertTend(dateEnd);

  return axios.get(
    `/srvanalytic/get?tbeg=${tbeg}&tend=${tend}&place=${placeType}&placeid=${placeId}&type=days`
  );
};

/**
 * @param eventId number
 * @param standId number
 * @param tbeg event start time %y%m%dT%H%M%S
 * @param tend event end time %y%m%dT%H%M%S
 * @returns response
 */
export const getAnalytics = ({ eventId, standId, tbeg, tend }) => {
  const token = getToken();
  const eid = eventId ? `&eventid=${eventId}` : '';
  const sid = standId ? `&standid=${standId}` : '';
  const url = `/srvanalytic/get?ac=je22dl6${eid}${sid}&tbeg=${tbeg}&tend=${tend}`;

  return instance.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};
