import React from "react";
import { infoIcon } from "../../assets";

const HeaderInfo = () => {
  return (
    <div className="header__info">
      <img src={infoIcon} alt="" />
      <span>
        Каждый понедельник технические работы, по сбоям обращайтесь в чат техподдержки или
        в группу{" "}
        <a
          href={
            process.env.REACT_APP_LINK_SUPPORT_TELEGRAM ||
            "https://t.me/+rDwaR05vKFVhNzE0"
          }
          target="_blank"
          rel="noreferrer"
        >
          телеграм
        </a>
        .
      </span>
    </div>
  );
};

export default HeaderInfo;
