import { immediateToast } from "izitoast-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { changeStandStatus } from "../../api/stand";
import { useConfirmPopup } from "../../hooks/useConfirmPopup";
import { errorHandler } from "../../utils/errorHandler";
import { ConfirmPopup, ViewPopup } from "../shared";
import { PlaySvg, MagnifierSvg } from "../../assets";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloneObject } from "../../utils";
import { useDispatch } from "react-redux";
import { fetchStandModeration } from "../../redux/eventReducer";

const ModerationStand = ({ afterModeration = () => {} }) => {
  const { mId: standId } = useParams();
  const { popupInfo, setPopupInfo, closePopup } = useConfirmPopup();
  const [viewPopup, setViewPopup] = useState(null);
  const [stand, setStand] = useState(null);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const moderationStand = useSelector((state) => state.events.moderationStand);

  useEffect(() => {
    const item = cloneObject(moderationStand);
    item.created_at = moment(item.created_at);
    if (item.media && item.media.length) {
      for (let k in item.media) {
        item.media[k].created_at = moment(item.media[k].created_at);
      }
    }
    setStand(item);
  }, [moderationStand]);

  useEffect(() => {
    dispatch(fetchStandModeration({ id: standId }));
  }, [standId, dispatch]);

  let comment = "";
  const onChangeTextarea = (e) => {
    comment = e.target.value;
  };

  const onDecline = () => {
    setPopupInfo({
      title: "Причина отклонения",
      customConfirmText: "Подтвердить",
      isOpened: true,
      additional: (
        <div>
          <textarea
            id="cancelation-comment"
            onChange={onChangeTextarea}
            required
            placeholder="Напишите причину отклонения заявки"
            className="moderation__textarea"
            name="text"
          ></textarea>
        </div>
      ),
      onConfirmClick() {
        if (comment) {
          (async () => {
            try {
              setIsLoading(true);
              const { data } = await changeStandStatus({
                id: standId,
                comment,
                status: "decline",
              });
              setIsLoading(false);
              if (data.message) {
                immediateToast("warning", {
                  message: data.message,
                  position: "topCenter",
                });
              }
              afterModeration();
            } catch (e) {
              setIsLoading(false);
              errorHandler(e);
            } finally {
              closePopup();
            }
          })();
        } else {
          immediateToast("error", {
            message: "Вы не указали причину",
            position: "topCenter",
          });
        }
      },
      onCancelClick() {
        closePopup();
      },
    });
  };

  const onApprove = async () => {
    setPopupInfo({
      title: "Подтверждение",
      customConfirmText: "Подтвердить",
      isOpened: true,
      additional: (
        <div>
          <p>Вы действительно подтверждаете стенд?</p>
        </div>
      ),
      onConfirmClick() {
        (async () => {
          try {
            setIsLoading(true);
            const { data } = await changeStandStatus({
              id: standId,
              comment,
              status: "approve",
            });
            setIsLoading(false);
            if (data.message) {
              immediateToast("success", {
                message: data.message,
                position: "topCenter",
              });
            }
            afterModeration();
          } catch (e) {
            setIsLoading(false);
            errorHandler(e);
          } finally {
            closePopup();
          }
        })();
      },
      onCancelClick() {
        closePopup();
      },
    });
  };

  const onCloseClick = () => {
    closePopup();
    setViewPopup(null);
  };

  const onViewPopup = (params) => {
    setViewPopup(params);
  };

  return (
    <div>
      {popupInfo.isOpened && (
        <ConfirmPopup
          popupInfo={popupInfo}
          onCloseClick={onCloseClick}
          isLoading={isLoading}
        />
      )}
      {viewPopup && (
        <ViewPopup
          link={viewPopup.link}
          type={viewPopup.type}
          onCloseClick={onCloseClick}
        />
      )}
      <div className="item-info__item item-info__item--documents">
        <div className="item-info__header item-info__header--participants">
          <div className="participants-cards__info participants-cards__info--documents">
            <h5 className="item-info__name item-info__name--participants">
              Основная информация
            </h5>
          </div>
          <div className="promotion-item__status">Стенд #{standId}</div>
        </div>
        <div className="item-info__main item-info__main--documents">
          {stand && (
            <div className="participants-cards__part participants-cards__part--small">
              <div className="item-info__data">
                <div className="item-info__point point">Название</div>
                <div className="item-info__response response">{stand.company}</div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">Логотип</div>
                <div className="item-info__response response">
                  <img width="100" src={stand.logo} alt="" />
                </div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">Ссылка на сайт</div>
                <div className="item-info__response response">
                  {stand?.logo_link ? (
                    <a href={stand.logo_link} target="_blank" rel="noreferrer">
                      {stand.logo_link}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">Краткое описание</div>
                <div className="item-info__response response">
                  {stand?.info?.short || "-"}
                </div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">ФИО</div>
                <div className="item-info__response response">
                  {stand?.info?.head_fio || "-"}
                </div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">E-mail</div>
                <div className="item-info__response response item-info__response response--email">
                  {stand.email || "-"}
                </div>
              </div>
              <div className="item-info__data ">
                <div className="item-info__point point">Телефон</div>
                <div className="item-info__response response ">
                  {stand?.info?.phone || "-"}
                </div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">Тариф</div>
                <div className="item-info__response response">
                  {stand.tariff_name} ({stand.tariff_price} руб.)
                </div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">
                  Модель стенда #{stand.tmpl_id}
                </div>
                <div className="item-info__response response">
                  <span
                    className="item-info__pointer"
                    onClick={() => {
                      onViewPopup({ link: stand.tmpl_image, type: "image" });
                    }}
                  >
                    <img width="100" src={stand.tmpl_image} alt="" />
                  </span>
                </div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">Создано</div>
                <div className="item-info__response response">
                  {stand.created_at.format("DD MMM YYYY HH:mm")}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="item-info__item item-info__item--documents">
        <div className="item-info__header item-info__header--participants">
          <div className="participants-cards__info">
            <h5 className="item-info__name item-info__name--participants">
              Юридическая информация
            </h5>
          </div>
        </div>
        <div className="item-info__main item-info__main--documents">
          {stand && stand.info && (
            <div className="participants-cards__part participants-cards__part--small">
              <div className="item-info__data">
                <div className="item-info__point point">Организация</div>
                <div className="item-info__response response">
                  {stand?.info?.organization || "-"}
                </div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">ИНН</div>
                <div className="item-info__response">{stand?.info?.inn || "-"}</div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">КПП</div>
                <div className="item-info__response">{stand?.info?.kpp || "-"}</div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">Телефон</div>
                <div className="item-info__response">{stand?.info?.phone || "-"}</div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">Юр. адрес</div>
                <div className="item-info__response">{stand?.info?.address || "-"}</div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">Основание</div>
                <div className="item-info__response">
                  {stand?.info?.justification || "-"}
                </div>
              </div>
              <div className="item-info__data ">
                <div className="item-info__point point">Регистрационный номер</div>
                <div className="item-info__response response ">
                  {stand?.info?.registration_number || "-"}
                </div>
              </div>
              <div className="item-info__data ">
                <div className="item-info__point point">Наименование банка</div>
                <div className="item-info__response response ">
                  {stand?.info?.bank_name || "-"}
                </div>
              </div>
              <div className="item-info__data ">
                <div className="item-info__point point">Номер расчетного счета</div>
                <div className="item-info__response response ">
                  {stand?.info?.bill_number || "-"}
                </div>
              </div>
              <div className="item-info__data ">
                <div className="item-info__point point">БИК</div>
                <div className="item-info__response response ">
                  {stand?.info?.bic || "-"}
                </div>
              </div>
              <div className="item-info__data ">
                <div className="item-info__point point">Корреспондирующий счёт</div>
                <div className="item-info__response response ">
                  {stand?.info?.corresponding_number || "-"}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {stand && stand.media && stand.media.length > 0 && (
        <div className="item-info__item item-info__item--documents">
          <div className="item-info__header item-info__header--participants">
            <div className="participants-cards__info participants-cards__info--documents">
              <h5 className="item-info__name item-info__name--participants">Лобби</h5>
            </div>
          </div>
          <div className="item-info__main">
            <div className="table-responsive">
              <table className="table table-bordered moderation-table">
                <thead className="moderation-table__thead table__head">
                  <tr className="moderation-table__tr">
                    <th
                      className="moderation-table__th"
                      style={{ width: "5%" }}
                      scope="col"
                    >
                      #
                    </th>
                    <th
                      className="moderation-table__th"
                      style={{ width: "10%" }}
                      scope="col"
                    >
                      Тип
                    </th>
                    <th
                      className="moderation-table__th"
                      style={{ width: "55%" }}
                      scope="col"
                    >
                      Файл
                    </th>
                    <th
                      className="moderation-table__th"
                      style={{ width: "30%" }}
                      scope="col"
                    >
                      Добавлено
                    </th>
                  </tr>
                </thead>
                <tbody className="moderation-table__tbody table__body">
                  {stand.media.map((file, k) => {
                    let typeName = <span className="badge badge-info">Изображение</span>;
                    let svg = MagnifierSvg;
                    if (file.type === "video") {
                      typeName = <span className="badge badge-danger">Видео</span>;
                    } else if (file.type === "presentation") {
                      typeName = <span className="badge badge-warning">Презентация</span>;
                    } else if (file.type === "audio") {
                      typeName = (
                        <span className="badge badge-primary">Аудиоприветствие</span>
                      );
                      svg = PlaySvg;
                    }

                    return (
                      <tr key={k} className="moderation-table__tr">
                        <td className="moderation-table__td">{file.id}</td>
                        <td className="moderation-table__td">{typeName}</td>
                        <td className="moderation-table__td">
                          {file.type !== "audio" && (
                            <div
                              onClick={() => {
                                onViewPopup({ link: file.src, type: file.type });
                              }}
                              className="moderation-table__preview"
                            >
                              <img src={file.preview} alt="" width="150" />
                              <span className="moderation-table__wrap-play-icon wrap-play-icon">
                                <img
                                  src={svg}
                                  alt=""
                                  className="moderation-table__play-icon play-icon"
                                />
                              </span>
                            </div>
                          )}
                          {file.type === "audio" && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span>Мужчина,</span>
                              <span>
                                <audio controls src={file.src}>
                                  Your browser does not support the
                                  <code>audio</code> element.
                                </audio>
                              </span>
                            </div>
                          )}
                        </td>
                        <td className="moderation-table__td">
                          {file.created_at.format("DD MMM YYYY HH:mm")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <div className="moderation__actions">
        <div className="moderation__btn-group">
          <button
            onClick={onApprove}
            style={{ marginRight: 15 }}
            className="btn btn-lg btn-success moderation__btn"
          >
            Принять
          </button>
          <button onClick={onDecline} className="btn btn-lg btn-danger moderation__btn">
            Отклонить
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModerationStand;
