import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

const TimePicker = ({
  value,
  onChange,
  className,
  placeholder,
  timeIntervals = 15,
  minTime,
  disabled = false,
}) => {
  const localeHeader = () => {
    const header = document.querySelector(".react-datepicker-time__header");
    if (header) {
      header.innerHTML = "Время";
    }
  };

  return (
    <DatePicker
      selected={value ? new Date(value) : null}
      onChange={onChange}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={timeIntervals}
      dateFormat="HH:mm"
      timeFormat="HH:mm"
      className={className}
      placeholderText={placeholder || ""}
      onInputClick={localeHeader}
      minTime={minTime || null}
      maxTime={minTime ? moment().endOf("day").toDate() : null}
      disabled={disabled}
    />
  );
};

export default TimePicker;
