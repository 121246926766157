import React from "react";
import { FavoritePopupButtons } from ".";
import { popupClose } from "../../assets";

const Popup = ({ popupInfo, closePopup }) => {
  return (
    <div className="event-popup">
      <div className="event-popup__main">
        <h5 className="event-popup__title">Мероприятие</h5>
        <div className="event-popup__text">Доступные действия</div>
        <div className="event-popup__buttons">
          <FavoritePopupButtons
            closePopup={closePopup}
            eventInfo={popupInfo.selectedEvent}
          />
        </div>
        <div onClick={closePopup} className="event-popup__close">
          <img src={popupClose} alt="close" />
        </div>
      </div>
      <div className="event-popup__overlay"></div>
    </div>
  );
};

export default Popup;
