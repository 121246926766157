import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authManager, userManager } from "../utils/managers";
import { getUser, logoutUser } from "../api";
import { errorHandler } from "../utils/errorHandler";
import { changeRole } from "../api/user";

const initialState = {
  accounts: [],
  byInvitation: false,
  token: null,
  profile: {
    id: 0,
    fullName: "",
    step: "",
    birth: "",
    country: "",
    avatar: "",
    role: "",
    position: "",
    mobile: "",
  },
};

export const fetchUser = createAsyncThunk("users/get", async () => {
  try {
    const { data } = await getUser();

    const resp = data.data?.user;
    if (!resp) {
      return;
    }

    return resp;
  } catch (error) {
    errorHandler(error);
  }
});

export const updateRole = createAsyncThunk("user/change-role", async (roleId) => {
  try {
    const { data } = await changeRole(roleId);

    const resp = data.data?.user;
    if (!resp) {
      return;
    }

    return resp;
  } catch (error) {
    errorHandler(error);
  }
});

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setProfileInfo(state, { payload }) {
      const user = userManager().prepare(payload);
      user.isActive = true;
      state.profile = user;
      authManager().setUser(user);
      state.token = authManager().getToken();
    },
    setAccounts(state) {
      let users = authManager().getUsers();
      let authUser = authManager().getUser();
      if (authUser) {
        state.profile = authUser;
      }

      if (users && Object.keys(users).length > 0) {
        for (let k in users) {
          let user = users[k];
          let acc = state.accounts.find((acc) => acc.id === user.id);
          if (!acc) {
            state.accounts.unshift(user);
          }
        }
      }
    },
    fullLogout(state) {
      state.accounts = [];
      state.profile = initialState.profile;
      (async () => {
        try {
          await logoutUser();
        } catch (e) {
          console.error(e);
        }
      })();

      state.token = null;
      authManager().fullLogout();
    },
    logout(state) {
      state.profile.isActive = false;
      state.token = null;
    },
    setStep(state, { payload }) {
      if (state.profile && state.profile?.step !== payload) {
        state.profile.step = payload;
      }
    },
    setRoleId(state, { payload }) {
      if (state.profile && state.profile?.roleId !== payload) {
        state.profile.roleId = parseInt(payload);
      }
    },
    setAccount(state, action) {
      let user = action.payload;
      let acc = state.accounts.find((acc) => acc.id === user.id);
      if (!acc) {
        user = userManager().prepare(user);
        state.accounts.push(user);
      }
    },
    setNewAvatar(state, action) {
      state.profile.avatar = action.payload;
      authManager().setUser(state.profile);
    },
    resetProfileInfo(state) {
      state.profile = initialState.profile;
      state.token = null;
    },
    resetAccounts(state) {
      state.accounts = [];
      state.token = null;
    },
  },
  extraReducers: {
    [fetchUser.fulfilled]: (state, { payload }) => {
      state.profile = {
        id: payload.id,
        fullName: payload.full_name,
        birth: payload.birth,
        country: payload.country,
        avatar: payload.avatar,
        role: payload.role,
        position: payload.position,
        mobile: payload.mobile,
        token: payload.token,
      };
    },
  },
});

export const {
  setNewAvatar,
  setStep,
  setRoleId,
  setAccount,
  setAccounts,
  logout,
  fullLogout,
  resetProfileInfo,
  resetAccounts,
  setProfileInfo,
} = userSlice.actions;
export default userSlice.reducer;
