import classNames from "classnames";
import { immediateToast } from "izitoast-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createWebinar, editWebinar } from "../../../api/stand";
import { popupClose, promotionDate } from "../../../assets";
import { useConfirmPopup } from "../../../hooks/useConfirmPopup";
import {
  addNewWebinar,
  deleteWebinar,
  updateWebinar,
} from "../../../redux/webinarReducer";
import { Validator } from "../../../utils/validator";
import { ConfirmPopup } from "../../shared";
import TimePicker from "../../shared/TimePicker";
import { errorHandler } from "../../../utils/errorHandler";
import { minDateForCalendar } from "../../../utils/minDateForCalendar";

const CreatePopup = ({ closePopup }) => {
  const { id, type } = useParams();
  const dispatch = useDispatch();

  const selectedWebinarType = useSelector((state) => state.webinars.type);
  const currentWebinarInfo = useSelector((state) => state.webinars.currentWebinar);

  const currentStand = useSelector((state) => state.events.currentStand);
  const currentEvent = useSelector((state) => state.events.currentEvent);

  const { popupInfo, setPopupInfo, closePopup: closeConfirmPopup } = useConfirmPopup();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [startDay, setStartDay] = useState("");
  const [startTime, setStartTime] = useState("");

  const [endDay, setEndDay] = useState("");
  const [endTime, setEndTime] = useState("");

  useEffect(() => {
    setTitle(currentWebinarInfo.title);
    setDescription(currentWebinarInfo.description);
    setStartDay(currentWebinarInfo.startDay ? new Date(currentWebinarInfo.startDay) : "");
    setStartTime(
      currentWebinarInfo.startTime ? new Date(currentWebinarInfo.startTime) : ""
    );

    setEndDay(currentWebinarInfo.endDay ? new Date(currentWebinarInfo.endDay) : "");
    setEndTime(currentWebinarInfo.endTime ? new Date(currentWebinarInfo.endTime) : "");
  }, [
    currentWebinarInfo.description,
    currentWebinarInfo.endDay,
    currentWebinarInfo.endTime,
    currentWebinarInfo.startDay,
    currentWebinarInfo.startTime,
    currentWebinarInfo.title,
  ]);

  const [errors, setErrors] = useState({});
  const isFormValid = (params) => {
    if (!params) {
      params = {
        title,
        description,
        startDay,
        startTime,
        endDay,
        endTime,
      };
    }
    let { msgs, valid } = Validator(params).form();

    if (!valid) {
      setErrors(msgs);
    } else {
      setErrors({});
    }

    return valid;
  };

  const onSubmit = () => {
    if (!isFormValid()) {
      return;
    }

    setPopupInfo({
      title: "Вебинар",
      text: "Вы действительно хотите создать вебинар?",
      isOpened: true,
      onCancelClick: closeConfirmPopup,
      customConfirmText: "Создать",
      onConfirmClick: async () => {
        try {
          const newWebinar = {
            id: currentWebinarInfo.id || null,
            title,
            startDay: startDay.toString(),
            startTime: startTime.toString(),
            endDay: endDay.toString(),
            endTime: endTime.toString(),
            description,
          };

          const startDateForApi =
            moment(startDay).format("YYYY-MM-DD") +
            " " +
            moment(startTime).add(1, "seconds").format("HH:mm:ss");

          const endDateForApi =
            moment(endDay).format("YYYY-MM-DD") +
            " " +
            moment(endTime).format("HH:mm:ss");

          const isAddNewFromDrafts =
            currentWebinarInfo.id && selectedWebinarType === "draft";

          if (isAddNewFromDrafts) {
            await editWebinar({
              name: title,
              description,
              timeStart: startDateForApi,
              timeEnd: endDateForApi,
              webinarId: currentWebinarInfo.id,
            });

            // await postWebinarOnModeration({
            //   webinarId: currentWebinarInfo.id,
            // });

            dispatch(
              addNewWebinar({
                webinarType: "actual",
                webinar: newWebinar,
                selectedWebinarType,
              })
            );

            dispatch(
              deleteWebinar({ webinarType: "draft", webinarId: currentWebinarInfo.id })
            );
          } else if (currentWebinarInfo.id) {
            await editWebinar({
              name: title,
              description,
              timeStart: startDateForApi,
              timeEnd: endDateForApi,
              webinarId: currentWebinarInfo.id,
            });

            dispatch(updateWebinar({ webinar: newWebinar }));
          } else {
            const { data } = await createWebinar({
              name: title,
              description,
              [`${type}Id`]: id,
              timeStart: startDateForApi,
              timeEnd: endDateForApi,
            });

            const webinarId = data.data.webinar_id;
            // await postWebinarOnModeration({ webinarId });

            newWebinar.id = webinarId;

            dispatch(
              addNewWebinar({
                webinarType: "actual",
                webinar: newWebinar,
                selectedWebinarType,
              })
            );
          }

          immediateToast("success", {
            message: "Вебинар успешно создан.",
            position: "topCenter",
          });

          closePopup();
        } catch (error) {
          errorHandler(error);
        }
      },
    });
  };

  const onDraftClick = async () => {
    try {
      if (!isFormValid()) {
        return;
      }

      const newWebinar = {
        id: currentWebinarInfo.id || null,
        title,
        startDay: startDay.toString(),
        startTime: startTime.toString(),
        endDay: endDay.toString(),
        endTime: endTime.toString(),
        description,
      };

      const startDateForApi =
        moment(startDay, "DD.MM.YYYY").format("YYYY-MM-DD") +
        " " +
        moment(startTime).add(1, "seconds").format("HH:mm:ss");

      const endDateForApi =
        moment(endDay, "DD.MM.YYYY").format("YYYY-MM-DD") +
        " " +
        moment(endTime).format("HH:mm:ss");

      if (currentWebinarInfo.id) {
        await editWebinar({
          name: title,
          description,
          timeStart: startDateForApi,
          timeEnd: endDateForApi,
          webinarId: currentWebinarInfo.id,
        });

        dispatch(updateWebinar({ webinar: newWebinar }));
      } else {
        const { data } = await createWebinar({
          name: title,
          description,
          [`${type}Id`]: id,
          timeStart: startDateForApi,
          timeEnd: endDateForApi,
        });

        const webinarId = data.data.webinar_id;
        newWebinar.id = webinarId;

        dispatch(
          addNewWebinar({
            webinar: newWebinar,
            webinarType: "draft",
            selectedWebinarType,
          })
        );
      }

      immediateToast("success", {
        message: "Черновик успешно создан.",
        position: "topCenter",
      });

      closePopup();
    } catch (error) {
      errorHandler(error);
    }
  };

  const isCreateButtonsDisabled = () => {
    const errorsLength = errors && Object.keys(errors).length;

    if (
      !title ||
      !description ||
      !startDay ||
      !startTime ||
      !endDay ||
      !endTime ||
      errorsLength > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  let minDate = new Date();
  let maxDate;

  if (type === "stand") {
    minDate = minDateForCalendar(currentStand?.event?.start_date);
    maxDate = new Date(currentStand?.event?.end_date);
  }

  if (type === "event") {
    minDate = minDateForCalendar(currentEvent.start_date);
    maxDate = new Date(currentEvent.end_date);
  }

  return (
    <div className="webinar__create-popup webinar-create">
      <div className="webinar-create__bg"></div>
      {popupInfo.isOpened && (
        <ConfirmPopup popupInfo={popupInfo} onCloseClick={closeConfirmPopup} />
      )}
      <div className="webinar-create__wrapper">
        <div className="webinar-create__header">
          <h2 className="webinar-create__title">Создание вебинара</h2>
          <button onClick={closePopup} className="webinar-create__close-btn">
            <img src={popupClose} alt="" />
          </button>
        </div>
        <div className="webinar-create__form">
          <div
            className={classNames("webinar-create__input-field", {
              "webinar-create__input-field--error": errors.title,
            })}
          >
            <label>Название вебинара</label>
            <input
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                isFormValid({ title: e.target.value });
              }}
              type="text"
              placeholder="Введите текст"
              className="default-input"
            />
            {errors.title && (
              <span className="webinar-create__input-error">{errors.title}</span>
            )}
          </div>
          <div
            className={classNames(
              "webinar-create__input-field",
              "webinar-create__input-field--description",

              {
                "webinar-create__input-field--error": errors.description,
              }
            )}
          >
            <label>Описание</label>
            <textarea
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                isFormValid({ description: e.target.value });
              }}
              placeholder="Введите текст"
              className="default-input"
            />
            {errors.description && (
              <span className="webinar-create__input-error">{errors.description}</span>
            )}
          </div>
          <div className="webinar-create__date-title">Дата начала</div>
          <div className="webinar-create__dates">
            <div
              className={classNames("webinar-create__date-wrapper", {
                "webinar-create__date-wrapper--error": errors.startDay,
              })}
            >
              <DatePicker
                selected={startDay ? new Date(startDay) : null}
                onChange={(date) => {
                  setStartDay(date);
                  setEndDay("");
                  setEndTime("");
                }}
                dateFormat="dd-MM-yyyy"
                className="webinar-create__date default-input"
                minDate={minDate}
                maxDate={maxDate}
                placeholderText="дд.мм.гг"
              />
              <img src={promotionDate} alt="" />
            </div>
            <div
              className={classNames("webinar-create__date-wrapper", {
                "webinar-create__date-wrapper--error": errors.startTime,
              })}
            >
              <TimePicker
                value={startTime}
                onChange={(date) => {
                  setStartTime(date);
                  setEndTime("");
                }}
                placeholder="00:00"
                className="webinar-create__date default-input"
              />
            </div>
          </div>
          <div className="webinar-create__date-title">Дата окончания</div>
          <div className="webinar-create__dates">
            <div
              className={classNames("webinar-create__date-wrapper", {
                "webinar-create__date-wrapper--error": errors.endDay,
              })}
            >
              <DatePicker
                selected={endDay ? new Date(endDay) : null}
                onChange={(date) => {
                  setEndDay(date);
                  setEndTime("");
                }}
                dateFormat="dd.MM.yyyy"
                className="webinar-create__date default-input"
                minDate={startDay || minDate}
                maxDate={
                  startDay ? new Date(startDay.getTime() + 3600 * 1000 * 24) : maxDate
                }
                placeholderText="дд.мм.гг"
              />
              <img src={promotionDate} alt="" />
            </div>
            <div
              className={classNames("webinar-create__date-wrapper", {
                "webinar-create__date-wrapper--error": errors.endTime,
              })}
            >
              <TimePicker
                value={endTime}
                onChange={(date) => setEndTime(date)}
                placeholder="00:00"
                className="webinar-create__date default-input"
                minTime={
                  moment(startDay).format("YYYY-MM-DD") ===
                    moment(endDay).format("YYYY-MM-DD") && startTime
                    ? startTime
                    : null
                }
              />
            </div>
          </div>
        </div>
        <div className="webinar-create__footer">
          <button
            disabled={isCreateButtonsDisabled()}
            onClick={onSubmit}
            className="webinar-create__create-btn red-btn"
          >
            Создать вебинар
          </button>
          {false && (!currentWebinarInfo.id || selectedWebinarType === "draft") && (
            <button
              disabled={isCreateButtonsDisabled()}
              onClick={onDraftClick}
              className="white-btn"
            >
              Создать черновик
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreatePopup;
