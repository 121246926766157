import React from "react";

const Banner = ({ logo, siteURL, type }) => {
  if (type === "small") {
    return (
      <div className="banner banner--small">
        <a href={siteURL} rel="noreferrer" target="_blank">
          <img src={logo} alt="#" />
        </a>
      </div>
    );
  }

  if (type === "big") {
    return (
      <div className="banner banner--big">
        <a href={siteURL} rel="noreferrer" target="_blank">
          <img src={logo} alt="#" />
        </a>
      </div>
    );
  }

  return null;
};

export default Banner;
