/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import { EntryLogo, MainAbout } from "../../components/entry";
import { Button } from "../../components/shared";

const ResetPasswordFinallly = () => {
  return (
    <div className="registration registration--reset">
      <EntryLogo />
      <MainAbout noScroll />
      <main className="registration-form">
        <div className="registration-form__main">
          <h3 className="registration-form__title">Сброс пароля JUSTEVENTS</h3>
          <p className="registration-form__finally">
            Вы успешно сбросили ваш пароль
          </p>
          <Link to="/entry/login" className="registration-reset__link">
            <Button>На главную</Button>
          </Link>
        </div>
      </main>
    </div>
  );
};

export default ResetPasswordFinallly;
