import { getToken, instance } from ".";

export const getWebinarLink = async (params) => {
  const token = getToken();

  let urlParams = new URLSearchParams(params);

  try {
    document.body.style.cursor = "progress";
    const { data } = await instance.get("/api/v1/webinar/link?" + urlParams, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    return error.response?.data;
  }finally{
    document.body.style.cursor = null;
  }
};

export const changeWebinarStatus = ({ id, comment, status }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append('status', status);
  formData.append('comment', comment);

  const url = `/api/v1/webinar/${id}/moderation`;

  return instance.post(url, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};


export const createWebinar = ({
    name,
    description,
    timeStart,
    timeEnd,
    standId,
    eventId,
  }) => {
  const token = getToken();

  let url = "";

  if (standId) {
    url = `/api/v1/stand/${standId}/webinar/create`;
  }

  if (eventId) {
    url = `/api/v1/event/${eventId}/webinar/create`;
  }

  const formData = new FormData();
  formData.append("name", name);
  formData.append("description", description);
  formData.append("time_start", timeStart);
  formData.append("time_end", timeEnd);

  return instance.post(url, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const editWebinar = ({ name, description, timeStart, timeEnd, webinarId }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("name", name);
  formData.append("description", description);
  formData.append("time_start", timeStart);
  formData.append("time_end", timeEnd);

  return instance.post(`/api/v1/webinar/${webinarId}`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getWebinars = ({ standId, eventId, type }) => {
  const token = getToken();
  let url = "";

  if (standId) {
    url = `/api/v1/stand/${standId}/webinars`;
  }

  if (eventId) {
    url = `/api/v1/event/${eventId}/webinars`;
  }

  if (type) {
    url = url + `?date_type=${type}`;
  }

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getWebinarStats = ({ standId, eventId, type }) => {
  const token = getToken();
  let url = "";

  if (standId) {
    url = `/api/v1/stand/${standId}/webinars/stats`;
  }

  if (eventId) {
    url = `/api/v1/event/${eventId}/webinars/stats`;
  }

  if (type) {
    url = url + `?date_type=${type}`;
  }

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getModerationWebinar = ({ id }) => {
  const token = getToken();

  let url = `/api/v1/webinar/${id}/moderation`;

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getModerationWebinars = ({ eventId }) => {
  const token = getToken();

  let url = `/api/v1/event/${eventId}/moderation/webinars`;

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const deleteWebinar = ({ webinarId }) => {
  const token = getToken();

  return instance.get(`/api/v1/webinar/${webinarId}/delete`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const postWebinarOnModeration = ({ webinarId }) => {
  const token = getToken();

  return instance.post(`/api/v1/webinar/${webinarId}/moderation`, null, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};
