import React from "react";
import moment from "moment";
import classNames from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";
import { useConfirmPopup } from "../../../hooks/useConfirmPopup";
import { ConfirmPopup } from "../../shared";
import { deleteBanner } from "../../../api/banners";
import { useDispatch } from "react-redux";
import { fetchMyBanners } from "../../../redux/bannerReducer";
import { successMessage } from "../../../utils/successMessage";

const PromotionItem = ({
  title,
  img,
  price,
  size,
  id,
  views,
  clicks,
  status,
  statusCode,
  endDate,
  onOpen,
  notable,
  org,
  createDate,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const momentEndDate = moment(new Date(endDate), "YYYY-MM-DD HH:mm:ss");
  const [popupIsOpen, setPopupIsOpen] = React.useState(false);

  const { closePopup, popupInfo, setPopupInfo } = useConfirmPopup();

  const { id: paramId, type } = useParams();

  const onDeleteClick = () => {
    setPopupIsOpen(false);

    setPopupInfo({
      isOpened: true,
      text: "Вы действительно хотите удалить рекламный баннер?",
      title: "Удаление баннера",
      onConfirmClick: () => {
        onDeleteConfirm();
        closePopup();
      },
      onCancelClick: closePopup,
    });
  };

  const onDeleteConfirm = async () => {
    try {
      await deleteBanner({ id });

      dispatch(
        fetchMyBanners({
          eventId: paramId,
        })
      );

      successMessage("Баннер успешно удален");
    } catch (error) {
      console.log(error);
    }
  };

  const onEditClick = () => {
    history.push(`/${type}/${paramId}/settings/promotion/create-ad/${id}`);
  };

  return (
    <div className="promotion-item">
      {popupInfo.isOpened && (
        <ConfirmPopup popupInfo={popupInfo} onCloseClick={closePopup} />
      )}
      <div className="promotion-item__header">
        <h5 className="promotion-item__title">{title}</h5>
        <div className="promotion-item__settings">
          {type !== "event" && (
            <div
              className={classNames("promotion-item__status", {
                "promotion-item__status--moderation": statusCode === 10,
                "promotion-item__status--payment": statusCode === 20,
                "promotion-item__status--active": statusCode === 15,
                "promotion-item__status--rejected": statusCode === 40,
              })}
            >
              {status}
            </div>
          )}
          <i
            onClick={() => setPopupIsOpen(!popupIsOpen)}
            className="promotion-item__button"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.33333 9.33268C2.59695 9.33268 2 8.73573 2 7.99935C2 7.26297 2.59695 6.66602 3.33333 6.66602C4.06971 6.66602 4.66667 7.26297 4.66667 7.99935C4.66667 8.73573 4.06971 9.33268 3.33333 9.33268ZM8 9.33268C7.26362 9.33268 6.66667 8.73573 6.66667 7.99935C6.66667 7.26297 7.26362 6.66602 8 6.66602C8.73638 6.66602 9.33333 7.26297 9.33333 7.99935C9.33333 8.73573 8.73638 9.33268 8 9.33268ZM11.3333 7.99935C11.3333 8.73573 11.9303 9.33268 12.6667 9.33268C13.403 9.33268 14 8.73573 14 7.99935C14 7.26297 13.403 6.66602 12.6667 6.66602C11.9303 6.66602 11.3333 7.26297 11.3333 7.99935Z"
                fill={popupIsOpen ? "#3B99CC" : "#727E8E"}
              />
            </svg>
          </i>
          {popupIsOpen && (
            <div className="promotion-item__settings-popup">
              <Link
                to={
                  !org
                    ? `/stand/${paramId}/settings/document-flow`
                    : `/event/${paramId}/settings/document-flow`
                }
                className="promotion-item__settings-popup-item"
              >
                Платежные документы
              </Link>
              {type === "event" && (
                <>
                  <button
                    onClick={onEditClick}
                    className="promotion-item__settings-popup-item"
                  >
                    Редактировать
                  </button>
                  <button
                    onClick={onDeleteClick}
                    className="promotion-item__settings-popup-item"
                  >
                    Удалить
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="promotion-item__main">
        <div
          onClick={onOpen}
          className={classNames("promotion-item__poster", {
            "promotion-item__poster--notable": notable,
          })}
        >
          <img src={img} alt="Banner img" />
          <div className="promotion-item__poster-overlay"></div>
        </div>
        <div className="promotion-item__info">
          <div className="contacts-cards__data contacts-cards__data--promotion">
            <div className="contacts-cards__point">ID</div>
            <div className="contacts-cards__response">{id}</div>
          </div>
          {size && (
            <div className="contacts-cards__data contacts-cards__data--promotion">
              <div className="contacts-cards__point">Размер</div>
              <div className="contacts-cards__response">{size}</div>
            </div>
          )}
          <div className="contacts-cards__data contacts-cards__data--promotion">
            <div className="contacts-cards__point">Дата создания</div>
            <div className="contacts-cards__response">
              {createDate && moment(new Date(createDate)).format("DD.MM.YYYY")}
            </div>
          </div>
          <div className="contacts-cards__data contacts-cards__data--promotion">
            <div className="contacts-cards__point">Бюджет</div>
            <div className="contacts-cards__response contacts-cards__response--promotion">
              {price} ₽
            </div>
          </div>
        </div>
      </div>
      <div className="promotion-item__footer">
        <div className="promotion-item__footer-item promotion-item__footer-item--views">
          {views} раз
        </div>
        <div className="promotion-item__footer-item promotion-item__footer-item--clicks">
          {clicks} кликов
        </div>
        <div className="promotion-item__footer-item promotion-item__footer-item--date">
          до {momentEndDate.format("DD.MM.YYYY")}
        </div>
      </div>
    </div>
  );
};

export default PromotionItem;
