import React from "react";
import { useHistory, useParams } from "react-router-dom";

const ParticipantsCard = ({
  fullName,
  email,
  mobile,
  date,
  img,
  standNumber,
  company,
  id,
}) => {
  const { id: eventId } = useParams();

  const history = useHistory();

  const onCardClick = (memberId) => {
    history.push(`/event/${eventId}/settings/participant/${memberId}`);
  };

  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => onCardClick(id)}
      className="contacts-cards__item"
    >
      <div
        style={{ height: 68 }}
        className="contacts-cards__header contacts-cards__header--participants"
      >
        <div className="participants-cards__info">
          <img src={img} alt="Company logo" />
          <h5 className="contacts-cards__name contacts-cards__name--participants">
            {company}
          </h5>
        </div>
        <div className="promotion-item__status">Стенд №{standNumber}</div>
      </div>
      <div className="contacts-cards__main">
        <div className="contacts-cards__data">
          <div className="contacts-cards__point">ФИО</div>
          <div className="contacts-cards__response">{fullName || "-"}</div>
        </div>
        <div className="contacts-cards__data">
          <div className="contacts-cards__point">E-mail</div>
          <div className="contacts-cards__response contacts-cards__response--email">
            {email || "-"}
          </div>
        </div>
        <div className="contacts-cards__data">
          <div className="contacts-cards__point">Телефон</div>
          <div className="contacts-cards__response ">{mobile || "-"}</div>
        </div>
      </div>
    </div>
  );
};

export default ParticipantsCard;
