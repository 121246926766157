/* eslint-disable no-empty */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { resendVerificationMessage } from "../../api";
import { EntryConfirmSvg } from "../../assets";
import { EntryLogo, MainAbout } from "../../components/entry";
import { errorHandler } from "../../utils/errorHandler";
import { successMessage } from "../../utils/successMessage";

const RegistrationConfirm = () => {
  const registration = useSelector((state) => state.app.registration);
  const [isSending, setIsSending] = useState(false);

  const onClick = async () => {
    setIsSending(true);
    try {
      const { data } = await resendVerificationMessage();
      if(data.success){
        successMessage(data.message);
      }
    } catch (error) { 
      errorHandler(error); 
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="registration-confirm">
      <EntryLogo />
      <MainAbout noScroll />
      <main className="registration-confirm__content">
        <h3 className="registration-confirm__title">Регистрация </h3>
        <div className="registration-confirm__main">
          <img
            className="registration-confirm__icon"
            src={EntryConfirmSvg}
            alt="Confirm Icon"
          />
          <h5 className="registration-confirm__notification">
            Мы отправили вам письмо по адресу
          </h5>
          <p className="registration-confirm__email">{registration.email}</p>
          <p className="registration-confirm__text">
            Нажмите по ссылке подтверждения в электронном письме, чтобы
            приступить к использованию JEP
          </p>
          <p className="registration-confirm__promt">
            Если вы не получали данное письмо:
          </p>
          <button onClick={onClick} className="registration-confirm__again">
            { isSending ? "Отправляем..." : "Отправить уведомление ещё раз" }
          </button>
        </div>
      </main>
    </div>
  );
};

export default RegistrationConfirm;
