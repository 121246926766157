import React from "react";
import { popupClose } from "../../assets";

// utils/useConfirmPopup для назначения пропов попапа
const ViewPopup = ({ link, type, onCloseClick }) => {
  return (
    <div className="confirmation-popup confirmation-popup--view">
      <div onClick={onCloseClick} className="confirmation-popup__bg"></div>
      <div className="confirmation-popup__container">
        <div className="confirmation-popup__wrapper">
          <div className="confirmation-popup__header">
            <button onClick={onCloseClick} className="confirmation-popup__close-btn">
              <img src={popupClose} alt="" />
            </button>
          </div>
          
          { type === "video" && <div className="confirmation-popup__output">
            <video controls src={link}></video>
          </div> }
          
          { type === "presentation" && <div className="confirmation-popup__output">
            <object data={link} type="application/pdf">
                <embed src={link} type="application/pdf" />
            </object>
          </div> }
          
          { type === "image" && <div className="confirmation-popup__output">
            <img src={link} alt=""/>
          </div> }
        </div>

      </div>
    </div>
  );
};

export default ViewPopup;
