import React from "react";
import { Button } from "../shared";

const EventNotificationPopup = ({
  closePopup,
  popupText,
  afterClose = () => {},
  customTitle = "",
}) => {
  const onClose = () => {
    afterClose();
    closePopup();
  };

  return (
    <div className="event-popup event-popup--notification">
      <div className="event-popup__main">
        <h5 className="event-popup__title">{customTitle || "Посещение выставки"}</h5>
        <div className="event-popup__text">{popupText}</div>
        <div className="event-popup__buttons event-popup__buttons--close">
          <Button
            onClick={onClose}
            className="event-popup__link event-popup__link--close"
          >
            Ок
          </Button>
        </div>
      </div>
      <div className="event-popup__overlay"></div>
    </div>
  );
};

export default EventNotificationPopup;
