import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getEventInfo } from "../../api";
import {
  BackToLobby,
  ControlPanel,
  EventLocation,
  Header,
  Members,
  Popup,
  Program,
  ShareContacts,
  Sidebar,
  SidePanel,
} from "../../components/vr-event";
import { fetchContacts } from "../../redux/chatReducer";
import {
  setCurrentEvent,
  setActiveStandIn3D,
  setActiveEventIn3D,
} from "../../redux/eventReducer";
import PopupWrapper from "./../../components/shared/PopupWrapper";
import Loader from "./../../components/shared/Loader";
import { Error } from "../../components/errors";

const VREventPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const parsedQuery = queryString.parse(location.search);
  const { ym } = window;

  const ymCounter = useSelector((state) => state.app.settings.ymCounter);

  // реф для взаимодействия с юнити плеером
  const iframeRef = useRef(null);

  const { id } = useParams();

  const [eventTitle, setEventTitle] = useState("");
  const [html, setHtml] = useState("");

  const [playerAvailable, setPlayerAvailable] = useState(true);

  const stand = useSelector((state) => state.events.currentStandInPlayer);
  const profile = useSelector((state) => state.user.profile);

  const isSelfUserStand = stand?.user_id === profile?.id;

  // включение/выключение звука в плеере
  const [isSoundOn, setIsSoundOn] = useState(true);

  useEffect(() => {
    dispatch(fetchContacts());
  }, [dispatch]);

  useEffect(() => {
    const turnOnSoundHandler = () => {
      const turnOnSoundMessage = JSON.stringify({
        type: "musicOn",
      });

      iframeRef.current.contentWindow.postMessage(turnOnSoundMessage, "*");
      setIsSoundOn(true);
    };

    if (!isSoundOn) {
      document.body.addEventListener("click", turnOnSoundHandler);
    }

    return () => {
      document.body.removeEventListener("click", turnOnSoundHandler);
    };
  }, [isSoundOn]);

  useEffect(() => {
    const fetchEventInfo = async () => {
      try {
        const { data } = await getEventInfo({ event_id: id });

        dispatch(setCurrentEvent(data.data.item));

        setEventTitle(data.data.item.name);
      } catch (error) {
        console.log(error);
      }
    };
    fetchEventInfo();
  }, [id, setEventTitle, dispatch]);

  // просмотр контента в режиме стенда
  const [isOnContentView, setIsOnContentView] = useState(false);

  useEffect(() => {
    const messageHandler = (evt) => {
      let message;
      try {
        message = JSON.parse(evt.data);
      } catch (e) {
        message = evt.data;
      }

      if (message.method === "ExhibitionLoaded") {
        dispatch(setActiveEventIn3D(id));
        ym(ymCounter, "hit", `/visitevent${id}`);
      }

      if (message.type === "3dplayer_current_state") {
        if (message.state === "Stand") {
          return setIsOnContentView(false);
        } else if (message.state === "Lobby") {
          dispatch(setActiveStandIn3D(0));
        }

        if (message.state === "Presentation" || message.state === "Video") {
          setIsOnContentView(true);
        }
      }

      if (message.type === "goToMemberStend") {
        let info = message.stand_info;
        try {
          info = JSON.parse(info);
        } catch (e) {}

        if (info && info.id) {
          dispatch(setActiveStandIn3D(info.id));
          ym(ymCounter, "hit", `/visitstand${info.id}`);
        }
      }

      if (message.type === "showHtml" && !html) {
        setHtml(message.html);
        setIsSoundOn(false);
      }

      if (message.type === "error") {
        setPlayerAvailable(false);
      }
    };

    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [dispatch, id, html, ymCounter, ym]);

  const onVideoClosed = () => {
    setHtml("");
    setIsSoundOn(true);
    iframeRef.current.contentWindow.postMessage(
      {
        type: "videoClosed",
      },
      "*"
    );
  };

  const shareContactsIsVisible =
    !isSelfUserStand &&
    !isOnContentView &&
    parsedQuery.location === "member" &&
    stand?.id;

  return (
    <div className="events-page">
      <div className="events-page__header">
        <Header title={eventTitle} />
      </div>
      {playerAvailable ? (
        <main className="events-page__main">
          {parsedQuery.location === "member" && (
            <BackToLobby isOnContentView={isOnContentView} frameRef={iframeRef} />
          )}
          <EventLocation frameRef={iframeRef} />

          {!!html && (
            <PopupWrapper title="Контент" onCloseClick={onVideoClosed}>
              <div className="events-page__html">
                <div dangerouslySetInnerHTML={{ __html: html }}></div>
                <div>
                  <Loader style={{ zIndex: -1 }} className="model-popup__loader" />
                </div>
              </div>
            </PopupWrapper>
          )}

          {(parsedQuery.popup === "program" && (
            <Popup title="Программа мероприятия" main={<Program />} />
          )) ||
            (parsedQuery.popup === "members" && (
              <Popup title="Участники" main={<Members frameRef={iframeRef} />} />
            )) ||
            null}

          <div className="events-page__control-panel">
            <ControlPanel isOnContentView={isOnContentView} />
          </div>
          {shareContactsIsVisible && (
            <ShareContacts selectedStandId={stand.id} isOnContentView={isOnContentView} />
          )}
          <div className="events-page__side-panel">
            {parsedQuery.sidebar ? (
              <Sidebar />
            ) : (
              <SidePanel setIsSoundOn={setIsSoundOn} frameRef={iframeRef} />
            )}
          </div>
        </main>
      ) : (
        <Error
          title="Похоже, что-то пошло не так"
          text="Плеер для данного мероприятия не доступен"
          isExtraBg
          withoutLogo
        />
      )}
    </div>
  );
};

export default VREventPage;
