export const convertMonth = (monthNumber) => {
  monthNumber = parseInt(monthNumber);
  if (monthNumber === 0) {
    return "Январь";
  }
  if (monthNumber === 1) {
    return "Февраль";
  }
  if (monthNumber === 2) {
    return "Март";
  }
  if (monthNumber === 3) {
    return "Апрель";
  }
  if (monthNumber === 4) {
    return "Май";
  }
  if (monthNumber === 5) {
    return "Июнь";
  }
  if (monthNumber === 6) {
    return "Июль";
  }
  if (monthNumber === 7) {
    return "Август";
  }
  if (monthNumber === 8) {
    return "Сентябрь";
  }
  if (monthNumber === 9) {
    return "Октябрь";
  }
  if (monthNumber === 10) {
    return "Ноябрь";
  }
  if (monthNumber === 11) {
    return "Декабрь";
  }
};
