import React from "react";
import { Link, useParams } from "react-router-dom";
import { promotionAd } from "../../../assets";

const PromotionLinks = () => {
  const { id, type } = useParams();

  return (
    <div className="promotion__buttons">
      <Link to={`/${type}/${id}/settings/promotion/create-ad`}>
        <div className="promotion-create">
          <div className="promotion-create__img">
            <img src={promotionAd} alt="Create site icon" />
          </div>
          <div className="promotion-create__main">
            <h5 className="promotion-create__title">Разместить рекламу</h5>
            <p className="promotion-create__text">
              Купить и разместить фото или видео баннер в разделе Лобби или основном зале
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default PromotionLinks;
