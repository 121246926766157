import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getProgramEvents } from "../../api/event";
import { saveProgram } from "../../api/program";
import { pageNames } from "../../components/organizer-panel/CreateEvent/EventSidebar";
import { Layout } from "../../components/shared";
import { NavStand, ProgramEvents, StandHeader } from "../../components/stand";
import { ProgramNav, ProgramReview } from "../../components/stand/program";
import { isProgramValid } from "../../components/stand/ProgramEvents";
import { useCreateEventPageDisabled } from "../../hooks/create-event/usePageDisabled";
import { fetchEvent, setFillingPageName } from "../../redux/eventReducer";
import { errorMessage } from "../../utils/errorMessage";
import { minDateForCalendar } from "../../utils/minDateForCalendar";
import { prepareOrganizerProgram } from "../../utils/prepareOrganizerProgram";
import { successMessage } from "../../utils/successMessage";

const initialProgram = [
  {
    date: "",
    events: [
      { timeStart: "", timeEnd: "", description: "", isWebinar: false },
      { timeStart: "", timeEnd: "", description: "", isWebinar: false },
    ],
  },
];

const Program = () => {
  const { viewType, id, type } = useParams();
  const dispatch = useDispatch();

  const currentStand = useSelector((state) => state.events.currentStand);
  const currentEvent = useSelector((state) => state.events.currentEvent) || {};

  const [program, setProgram] = useState(initialProgram);

  const [isFormSubmited, setIsFormSubmited] = useState(false);

  const [isShowErrors, setIsShowErrors] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchProgram = async () => {
      try {
        const { data } = await getProgramEvents({ type, standId: id });

        const preparedProgram = prepareOrganizerProgram(data.data);

        if (!isMounted) {
          return;
        }

        if (
          preparedProgram
            .map((pr) => {
              return {
                ...pr,
                events: [...pr.events].filter((ev) => !ev.isWebinar),
              };
            })
            .filter((pr) => pr.events.length > 0).length > 0
        ) {
          setIsFormSubmited(true);
        }

        setProgram(preparedProgram.length > 0 ? preparedProgram : initialProgram);
      } catch (error) {}
    };

    fetchProgram();

    return () => {
      isMounted = false;
    };
  }, [id, type]);

  const programWithoutWebinars = program
    .map((pr) => {
      return {
        ...pr,
        events: [...pr.events].filter((ev) => !ev.isWebinar),
      };
    })
    .filter((pr) => pr.events.length > 0);

  const onPublicClick = async () => {
    try {
      if (!isProgramValid(program)) {
        setIsShowErrors(true);
        errorMessage("Пожалуйста, заполните все обязательные поля");
        return;
      }

      const eventsData = [];

      programWithoutWebinars.forEach((pr) => {
        const dayStart = moment(pr.date).format("YYYY-MM-DD");

        [...pr.events].map((ev, ind) => {
          eventsData.push({
            id: ind + 1,
            name: ev.description,
            date_start:
              ev.timeStart && `${dayStart} ${moment(ev.timeStart).format("HH:mm:ss")} `,
            date_end:
              ev.timeEnd && `${dayStart} ${moment(ev.timeEnd).format("HH:mm:ss")} `,
          });

          return ev;
        });

        return pr;
      });

      await saveProgram({
        type,
        events: eventsData,
        standId: id,
      });

      dispatch(
        setFillingPageName({
          eventId: id,
          name: pageNames.RECEPTION,
        })
      );

      dispatch(fetchEvent({ eventId: id }));

      setIsFormSubmited(true);
      successMessage("Программа успешно сохранена");
    } catch (error) {
      console.log(error);
    }
  };

  // const onEditClick = () => {
  //   setIsFormSubmited(false);
  // };

  let minDate = new Date();
  let maxDate;

  if (type === "stand") {
    minDate = minDateForCalendar(currentStand?.event?.start_date);
    maxDate = new Date(currentStand?.event?.end_date);
  }

  if (type === "event") {
    minDate = minDateForCalendar(currentEvent.start_date);
    maxDate = new Date(currentEvent.end_date);
  }

  const isPageDisabled = useCreateEventPageDisabled(pageNames.PROGRAMM);

  return (
    <Layout>
      <div className="organizer-panel__main">
        <NavStand />
        <div className="stand-settings">
          <StandHeader title="Программа мероприятия" withHint={isPageDisabled} />
          <div className="program">
            <ProgramNav />
            <div className="program__main">
              {viewType === "edit" && (
                <>
                  <ProgramEvents
                    programEvents={program}
                    setProgramEvents={setProgram}
                    isDisabled={isFormSubmited}
                    isShowErrors={isShowErrors}
                    minDate={minDate}
                    maxDate={maxDate}
                    withDisabledWebinarInputs={true}
                  />
                  {/* <div className="program__buttons">
                    {isFormSubmited ? (
                      <button onClick={onEditClick} className="red-btn">
                        Редактировать
                      </button>
                    ) : (
                      <button onClick={onPublicClick} className="red-btn">
                        Опубликовать
                      </button>
                    )}
                  </div> */}
                  <div className="program__buttons">
                    <button
                      onClick={onPublicClick}
                      className="red-btn"
                      disabled={isPageDisabled}
                    >
                      Опубликовать
                    </button>
                  </div>
                </>
              )}
              {viewType === "review" && <ProgramReview programEvents={program} />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Program;
