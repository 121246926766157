import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLanding } from "../../api/landing";
import Loader from "../../components/shared/Loader";
import { DemoFooter, DemoHeader } from "../../components/stand/promotion/demo";
import {
  DemoEventAbout,
  DemoEventMain,
  DemoEventMeeting,
  DemoEventParticipants,
  DemoEventProgram,
} from "../../components/stand/promotion/demoEvent";
import { errorHandler } from "../../utils/errorHandler";

const DemoSiteEvent = ({ isDemoPreview }) => {
  const demo = useSelector((state) => state.app.demo);
  const [isLoading, setIsLoading] = useState(true);
  const slug = window.location.pathname.replace(/^\/event-[0-9]+-(.*)/, "$1");

  const { id } = useParams();

  const isDemo = isDemoPreview || slug === "/";
  const [siteInfo, setSiteInfo] = useState(demo);

  const [roomType, setRoomType] = useState(siteInfo?.eventType);

  useEffect(() => {
    const fetchSiteInfo = async () => {
      try {
        setIsLoading(true);
        let params = {};
        if (!id) {
          params = { slug };
        } else {
          params = { eventId: id };
        }

        const { data } = await getLanding(params);
        const output = data.data;

        setRoomType(output.room_type);

        let formData = output.main;
        if (isDemo) {
          formData = output.draft || output.main;
        }

        formData && setSiteInfo(formData);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!isDemo) {
      fetchSiteInfo();
    }
  }, [slug, isDemo, id]);

  if (isLoading && !isDemo) {
    return (
      <div className="demo">
        <div className="demo__loader">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="demo" id="demo-site">
      {isDemo && <DemoHeader />}
      <DemoEventMain
        title={siteInfo.header.title || ""}
        subtitle={siteInfo.header.subtitle || ""}
        urls={siteInfo.urls || {}}
        bg={siteInfo.header.background}
        colorTitle={siteInfo.color.title || ""}
        buttonColor={siteInfo.color.text || ""}
        colorBackground={siteInfo.color.background}
        startDate={siteInfo?.header?.startDate}
        endDate={siteInfo?.header?.endDate}
        contactInfo={siteInfo.header.contactInfo || {}}
        logo={siteInfo.main.logo}
        participants={siteInfo.main.participants}
        program={siteInfo.main.program}
        speakers={siteInfo.main.speakers}
        colorText={siteInfo.color.text || ""}
        roomType={roomType}
      />
      <DemoEventAbout
        text={siteInfo.main.about || ""}
        colorTitle={siteInfo.color.title || ""}
        colorText={siteInfo.color.text || ""}
      />
      {roomType !== "gallery" && (
        <DemoEventParticipants
          participants={siteInfo.main.participants}
          colorBackground={siteInfo.color.background}
          colorTitle={siteInfo.color.title || ""}
        />
      )}
      <DemoEventProgram
        colorTitle={siteInfo.color.title || ""}
        colorText={siteInfo.color.text || ""}
        program={siteInfo.main.program}
      />
      <DemoEventMeeting
        colorTitle={siteInfo.color.title || ""}
        colorText={siteInfo.color.text || ""}
        speakers={siteInfo.main.speakers}
      />
      <DemoFooter
        text={siteInfo.footer.text || ""}
        contactInfo={siteInfo.header.contactInfo || {}}
      />
    </div>
  );
};

export default DemoSiteEvent;
