import moment from "moment";
import React from "react";

const ParticipantMainInfo = ({ cardInfo }) => {
  return (
    <div className="contacts-cards__item contacts-cards__item--documents">
      <div className="contacts-cards__header contacts-cards__header--participants">
        <div className="participants-cards__info participants-cards__info--documents">
          <div className="contacts-cards__avatar">
            <img src={cardInfo.logo} alt={cardInfo.name} />
          </div>
          <h5 className="contacts-cards__name contacts-cards__name--participants">
            <span>Основная информация</span>
          </h5>
        </div>
        <div className="promotion-item__status">Стенд №{ cardInfo.position } (ID: { cardInfo.id })</div>
      </div>
      <div className="contacts-cards__main contacts-cards__main--documents">
        <div className="participants-cards__part participants-cards__part--small">
          <div className="contacts-cards__data">
            <div className="contacts-cards__point">ФИО</div>
            <div className="contacts-cards__response">
              {cardInfo?.info?.head_fio || "-"}
            </div>
          </div>
          <div className="contacts-cards__data">
            <div className="contacts-cards__point">E-mail</div>
            <div className="contacts-cards__response contacts-cards__response--email">
              {cardInfo.email || "-"}
            </div>
          </div>
          <div className="contacts-cards__data ">
            <div className="contacts-cards__point">Телефон</div>
            <div className="contacts-cards__response ">
              {cardInfo.phone || "-"}
            </div>
          </div>
          <div className="contacts-cards__data">
            <div className="contacts-cards__point">Адрес</div>
            <div className="contacts-cards__response ">
              {cardInfo?.info?.address || "-"}
            </div>
          </div>
          <div className="contacts-cards__data">
            <div className="contacts-cards__point">Создано</div>
            <div className="contacts-cards__response">
              {cardInfo.created_at
                ? moment(cardInfo.created_at).format(
                    "DD.MM.YYYY HH:mm"
                  )
                : "-"}
            </div>
          </div>
        </div>
        <div className="participants-cards__part">
          <div className="contacts-cards__data">
            <div className="contacts-cards__point contacts-cards__point--documents">
              Наименования учереждение банка
            </div>
            <div className="contacts-cards__response contacts-cards__response--documents">
              {cardInfo?.info?.bank_name || "-"}
            </div>
          </div>
          <div className="contacts-cards__data ">
            <div className="contacts-cards__point contacts-cards__point--documents">
              Организация
            </div>
            <div className="contacts-cards__response contacts-cards__response--documents">
              {cardInfo?.info?.organization || "-"}
            </div>
          </div>
          <div className="contacts-cards__data ">
            <div className="contacts-cards__point contacts-cards__point--documents">
              БИК
            </div>
            <div className="contacts-cards__response contacts-cards__response--documents">
              {cardInfo?.info?.bic || "-"}
            </div>
          </div>
          <div className="contacts-cards__data ">
            <div className="contacts-cards__point contacts-cards__point--documents">
              Номер расчетного счета
            </div>
            <div className="contacts-cards__response contacts-cards__response--documents">
              {cardInfo?.info?.bill_number || "-"}
            </div>
          </div>
          <div className="contacts-cards__data ">
            <div className="contacts-cards__point contacts-cards__point--documents">
              Корреспондентский счет
            </div>
            <div className="contacts-cards__response contacts-cards__response--documents">
              {cardInfo?.info?.corresponding_number || "-"}
            </div>
          </div>
          <div className="contacts-cards__data">
            <div className="contacts-cards__point contacts-cards__point--documents">
              ИНН / КПП
            </div>
            <div className="contacts-cards__response contacts-cards__response--documents">
              {cardInfo?.info?.inn || "-"} / {cardInfo?.info?.kpp || "-"}
            </div>
          </div>
          <div className="contacts-cards__data">
            <div className="contacts-cards__point contacts-cards__point--documents">
              ОГРН
            </div>
            <div className="contacts-cards__response contacts-cards__response--documents">
              {cardInfo?.info?.registration_number || "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipantMainInfo;
