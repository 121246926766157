import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  standContacts: [],
};

export const eventSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setStandContacts(state, { payload }) {
      state.standContacts = [...payload];
    },
    addStandContact(state, { payload }) {
      state.standContacts = [payload, ...state.standContacts];
    },
  },
});

export const { setStandContacts, addStandContact } = eventSlice.actions;
export default eventSlice.reducer;
