import React from "react";
import { useParams } from "react-router-dom";
import { exportParticipants } from "../../api/organizer";
import { Hint } from "../../components/organizer-panel/Hint";
import { Layout } from "../../components/shared";
import { NavStand } from "../../components/stand";
import { ParticipantsNav } from "../../components/stand/participants";
import { errorHandler } from "../../utils/errorHandler";
import { ParticipantsMembersList, ParticipantsSale } from "./index";

const Participants = () => {
  const { link, id } = useParams();

  const onPrintClick = async () => {
    try {
      const { data } = await exportParticipants({ eventId: id });

      const pdfUrl = data.data?.url;

      pdfUrl && window.open(pdfUrl, "_blank");
    } catch (error) {
      errorHandler(error);
    }
  };

  const onHeaderButtonClick = () => {
    return onPrintClick();
  };

  const withHint = false;

  return (
    <Layout>
      <div className="organizer-panel__main">
        <NavStand />
        <div className="stand-settings">
          <div className="stand-settings__header">
            <div className="stand-settings__about">
              <div className="stand-settings__about-info">
                <h5 className="stand-settings__title">Участники</h5>
                {withHint && (
                  <Hint hintText="Для редактирования заполните обязательные предыдущие разделы" />
                )}
              </div>
            </div>

            <div style={{ height: 40 }} className="stand-settings__button">
              {link === "list" && (
                <button
                  disabled={withHint}
                  className="red-btn button--mr10"
                  onClick={onHeaderButtonClick}
                >
                  Экспорт
                </button>
              )}
            </div>
          </div>
          <div className="participants">
            <ParticipantsNav />
            {link === "sale" && <ParticipantsSale />}
            {link === "list" && <ParticipantsMembersList />}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Participants;
