import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { fetchBanners } from "../../redux/bannerReducer";
import { Banner } from "./";

const AdBanners = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const reqBanners = useSelector((state) => state.banners.items);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    dispatch(
      fetchBanners({
        url: location.pathname,
      })
    );
  }, [dispatch, location.pathname]);

  useEffect(() => {
    setBanners(reqBanners);
  }, [reqBanners]);

  if (!banners || banners.length === 0) {
    return null;
  }

  return (
    <div className="banners">
      {banners &&
        banners.map((b, i) => {
          const parts = b.type?.split("_");
          const type = parts?.length > 0 && parts[1];
          return <Banner logo={b.image} key={i} siteURL={b.siteURL} type={type} />;
        })}
    </div>
  );
};

export default AdBanners;
