import { useEffect, useState } from "react";
import { getCountries } from "../api/countries";

export const useCountries = () => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const { data } = await getCountries();

        if (!data?.countries) return [];

        const preparedData = data.countries.map((c) => ({
          value: c.id,
          label: c.name,
        }));

        setCountries(preparedData);
      } catch (error) {}
    };

    fetchCountries();
  }, [setCountries]);

  return countries;
};
