import React from "react";
import { Link } from "react-router-dom";

const EntryHeader = ({ login }) => {
  return (
    <div className="registration-form__header">
      {login ? (
        <Link to="/entry/registration" className="entry__login">
          Нет аккаунта? <span>Регистрация</span>
        </Link>
      ) : (
        <Link to="/entry/login" className="entry__login">
          Уже есть аккаунт? <span>Войти</span>
        </Link>
      )}
    </div>
  );
};

export default EntryHeader;
