import { getToken, instance } from ".";
import { createEventPages } from "../components/organizer-panel/CreateEvent";

export const createEvent = ({ name, start_date, end_date, type_id }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("name", name);
  formData.append("start_date", start_date);
  formData.append("end_date", end_date);
  formData.append("type_id", type_id);

  return instance.post("/api/v1/event/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createEventStepTwo = ({ type, limit, limit_daily, questions, event_id }) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("type", type);
  formData.append("limit", limit);
  formData.append("limit_daily", limit_daily);
  questions.forEach((k, val) => {
    formData.append(`questions[${k}]`, val);
  });

  return instance.post(`/api/v1/event/${event_id}/registration`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createEventStepThree = ({ room_template_id, event_id }) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("room_template_id", room_template_id);

  return instance.post(`/api/v1/event/${event_id}/choose-tmpl`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createEventStepFour = ({
  organization_name,
  inn,
  registration_number,
  bank_name,
  bic,
  corresponding_number,
  event_id,
  bill_number,
}) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("organization_name", organization_name);
  formData.append("inn", inn);
  formData.append("registration_number", registration_number);
  formData.append("bank_name", bank_name);
  formData.append("bic", bic);
  formData.append("corresponding_number", corresponding_number);
  formData.append("bill_number", bill_number);

  return instance.post(`/api/v1/event/${event_id}/legal-info`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createEventStepFive = ({ tariff_id, event_id }) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("tariff_id", tariff_id);

  return instance.post(`/api/v1/event/${event_id}/choose-tariff`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setEventPlan = ({ eventId, data = {} }) => {
  const token = getToken();

  return instance.post(`/api/v1/event/${eventId}/plan`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getEventTypes = () => {
  const token = getToken();

  return instance.get("/api/v1/event/types", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const publishGallery = ({ id }) => {
  const token = getToken();

  return instance.get("/api/v1/event/" + id + "/gallery/publish", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getEventInfo = ({ eventId, isConstructor = false }) => {
  const token = getToken();
  let url = `/api/v1/event?id=${eventId}`;
  if (isConstructor) {
    url += "&is_constructor=true";
  }

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getRoomTemplates = () => {
  const token = getToken();

  return instance.get(`/api/v1/room-templates`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getProgramEvents = ({ type, standId }) => {
  const token = getToken();

  return instance.get(`/api/v1/${type}/${standId}/program`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getFillingPageIndex = ({ eventId }) => {
  const token = getToken();

  return instance.get(`api/v1/cache/get/filled-page-${eventId}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setFillingPageIndex = async ({ eventId, pageIndex }) => {
  try {
    const token = getToken();

    const { data: currentPageData } = await getFillingPageIndex({ eventId });

    const currentFillingPageIndex = currentPageData.data
      ? +JSON.parse(currentPageData.data)
      : 0;

    if (currentFillingPageIndex > pageIndex) {
      return;
    }

    const formData = new URLSearchParams();
    formData.append("data", pageIndex);

    return instance.post(`api/v1/cache/set/filled-page-${eventId}`, formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const getFillingPageName = async ({ eventId }) => {
  try {
    const token = getToken();

    const { data } = await instance.get(`api/v1/cache/get/filling-page-name-${eventId}`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    if (!data?.data) return "";

    return JSON.parse(data.data);
  } catch (error) {
    console.log(error);
  }
};

export const changeFillingPageName = async ({ eventId, name }) => {
  try {
    const token = getToken();

    const fillingPageName = await getFillingPageName({ eventId });
    const fillingPageIndex = createEventPages.findIndex(
      (p) => p.name === fillingPageName
    );

    const newFillingPageIndex = createEventPages.findIndex((p) => p.name === name);

    if (
      fillingPageName &&
      fillingPageIndex !== -1 &&
      newFillingPageIndex < fillingPageIndex
    ) {
      return {};
    }

    const formData = new URLSearchParams();
    formData.append("data", name);

    return instance.post(`api/v1/cache/set/filling-page-name-${eventId}`, formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });
  } catch (error) {
    console.log(error);
  }
};
