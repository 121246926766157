import classNames from "classnames";
import React from "react";
import { webinarDelete, webinarEdit, webinarStart } from "../../../assets";

const CardOptions = ({ onEditClick, onDeleteClick, cardType, onStartClick }) => {
  const isActual = cardType === "actual";
  const isModerating = cardType === "moderation";
  const isDrafts = cardType === "draft";
  const isRejected = cardType === "denied";

  return (
    !isModerating && (
      <div className="webinar-card__settings">
        {isActual && (
          <button onClick={onStartClick} className="webinar-card__settings-item">
            <img src={webinarStart} alt="" />
            Запустить вебинар
          </button>
        )}
        {(isActual || isDrafts || isRejected) && (
          <button onClick={onEditClick} className="webinar-card__settings-item">
            <img src={webinarEdit} alt="" />
            Редактировать
          </button>
        )}
        {(isActual || isDrafts || isRejected) && (
          <button
            className={classNames("webinar-card__settings-item", {
              "webinar-card__settings-item--danger": true,
            })}
            onClick={onDeleteClick}
          >
            <img src={webinarDelete} alt="" />
            Удалить
          </button>
        )}
      </div>
    )
  );
};

export default CardOptions;
