import classNames from "classnames";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { createAccount } from "../../../api";
import { registrationBack } from "../../../assets";
import { setAccount, setProfileInfo } from "../../../redux/userReducer";
import { errorHandler } from "../../../utils/errorHandler";
import { authManager } from "../../../utils/managers";
import { Button } from "../../shared";

const Form = () => {
  const [mobile, setMobile] = useState("");
  const [company, setCompany] = useState("");
  const [pos, setPos] = useState("");
  const [checkbox, setCheckBox] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();

  const [error] = useState("");

  const history = useHistory();

  const checkInput = (value) => {
    if (value.length >= 1 && value.length < 5) {
      return true;
    }
  };

  const isAllow = () => {
    if (
      mobile.split("").length >= 10 &&
      !checkInput(company) &&
      !checkInput(pos) &&
      checkbox &&
      password === confirmPassword
    ) {
      return true;
    }
  };

  const onSubmit = async (evt) => {
    evt.preventDefault();

    if (!isAllow()) {
      return;
    }

    try {
      const { data } = await createAccount(
        mobile,
        password,
        confirmPassword,
        company,
        pos
      );
      const userInfo = data.data.user;

      if (userInfo) {
        userInfo.token = authManager().getToken();

        dispatch(setProfileInfo(userInfo));
        dispatch(setAccount(userInfo));
      }

      history.push("/events/list");
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <form className="registration-form__inputs">
      <div className="input">
        <label className="input__label" htmlFor="mobile">
          Ваш контактный телефон*
        </label>
        <input
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          className={classNames(
            "input__main",
            {
              error: mobile && mobile.split("").length < 9,
            },
            { filled: mobile.split("").length >= 10 }
          )}
          placeholder="71234567890"
          type="tel"
          id="mobile"
          maxLength={11}
        />
        {mobile && mobile.length <= 10 ? (
          <p className="input__text">
            Номер телефона не может содержать меньше 11 символов*
          </p>
        ) : null}
      </div>
      <div className="input">
        <label className="input__label" htmlFor="company">
          Компания*
        </label>
        <input
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          className={classNames(
            "input__main",
            { error: checkInput(company) },
            { filled: company.length >= 3 }
          )}
          placeholder="Название компании"
          type="text"
          id="company"
        />
        {checkInput(company) ? (
          <p className="input__text">Поле должно содержать не менее 5 символов</p>
        ) : null}
      </div>

      <div className="input">
        <label className="input__label" htmlFor="pos">
          Должность/профессия*
        </label>
        <input
          value={pos}
          onChange={(e) => setPos(e.target.value)}
          className={classNames(
            "input__main",
            { error: checkInput(pos) },
            { filled: pos.length >= 3 }
          )}
          placeholder="Введите вашу должность или профессию"
          type="text"
          id="pos"
          autoComplete="off"
        />
        {checkInput(pos) ? (
          <p className="input__text">Поле должно содержать не менее 5 символов</p>
        ) : null}
      </div>
      <div className="input">
        <label className="input__label">Пароль*</label>
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={classNames(
            "input__main",
            { error: checkInput(password) },
            { filled: password.length >= 3 }
          )}
          placeholder="Введите пароль"
          type="password"
          autoComplete="off"
        />
        {checkInput(password) ? (
          <p className="input__text">Поле должно содержать не менее 5 символов</p>
        ) : null}
      </div>
      <div className="input">
        <label className="input__label">Повторите пароль*</label>
        <input
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className={classNames(
            "input__main",
            { error: checkInput(confirmPassword) },
            { filled: confirmPassword.length >= 3 }
          )}
          placeholder="Введите пароль"
          type="password"
        />
        {password && password !== confirmPassword && (
          <p className="input__text">Пароли не совпадают</p>
        )}
      </div>
      <div className="registration-form__agreement">
        <input
          checked={checkbox}
          onChange={() => setCheckBox(!checkbox)}
          type="checkbox"
          id="agreement"
          className="registration-form__agreement-input"
        />
        <label htmlFor="agreement">Даю согласие на обработку персональных данных</label>
      </div>
      {error && <p className="registration-form__error">{error}</p>}
      <div className="registration-final__footer">
        <Link to="/entry/registration/continue" className="registration-final__back">
          <img src={registrationBack} alt="Back Arrow" />
          <span>Назад</span>
        </Link>

        <Button
          className="registration-final__submit"
          onClick={onSubmit}
          disabled={!isAllow()}
        >
          Создать аккаунт
        </Button>
      </div>
    </form>
  );
};

export default Form;
