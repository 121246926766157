import { getToken, instance } from ".";

// Event
// {
//     "id": 2,
//     "name": "Second",
//     "date_start": "2021-02-03 02:23:23",
//     "date_end": "2021-02-03 03:23:23"
// }
export const saveProgram = ({ events, type, standId }) => {
  const token = getToken();

  return instance.post(
    `/api/v1/${type}/${standId}/program`,
    { events },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
};
