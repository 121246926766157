import React from "react";
import { useSelector } from "react-redux";
import { getMaskImage } from "./TexturePicker";

const ModelMask = ({ mask, width, height, canvasRefs }) => {
  const templateId = useSelector((state) => state.events.currentStand)?.tmpl?.id;

  if (!mask.color && mask.type !== "texture") return null;

  const isTexture = mask.color?.includes(".png");

  let maskContent = null;
  if (mask.color) {
    maskContent = (
      <canvas
        ref={(canvas) => (canvasRefs.current[mask.id] = canvas)}
        className="model__mask-canvas"
      ></canvas>
    );
  }

  if (isTexture) {
    maskContent = (
      <img
        src={getMaskImage({ mask: mask.color, templateId })}
        alt=""
        className="model__mask-canvas"
        style={{
          width,
          height,
        }}
      />
    );
  }

  return <div className="model__mask">{maskContent}</div>;
};

export default ModelMask;
