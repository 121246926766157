import React from "react";
import { popupClose } from "../../assets";

const PopupWrapper = ({ title, children, width = 607, onCloseClick }) => {
  return (
    <div className="popup-wrapper">
      <div className="popup-wrapper__bg"></div>
      <div style={{ width: width || "auto" }} className="popup-wrapper__content">
        <div className="popup-wrapper__header">
          <h3 className="popup-wrapper__title">{title}</h3>
          <button onClick={onCloseClick} className="popup-wrapper__close-btn">
            <img src={popupClose} alt="" />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default PopupWrapper;
