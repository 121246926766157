import queryString from "query-string";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getWebinarLink } from "../../api/webinar";
import { ChatSvg, WebinarSvg } from "../../assets";
import { fetchContactByStandId, fetchContacts, setTab } from "../../redux/chatReducer";
import { PanelLink } from "./";

const SidePanel = ({ setIsSoundOn, frameRef }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const parsedQuery = queryString.parse(history.location.search);

  const stand = useSelector((state) => state.events.currentStandInPlayer);

  const [webinarLink, setWebinarLink] = useState("");

  const { id: eventId } = useParams();

  const sidebarPath = (sidebarName) => {
    return (
      history.location.pathname +
      "?" +
      queryString.stringify({ ...parsedQuery, sidebar: sidebarName })
    );
  };

  const chatOnClick = () => {
    dispatch(fetchContacts());
    dispatch(setTab("messages"));
    dispatch(fetchContactByStandId(stand.id));
  };

  const webinarOnClick = async () => {
    try {
      const turnOffSoundMessage = JSON.stringify({
        type: "musicOff",
      });
      frameRef.current.contentWindow.postMessage(turnOffSoundMessage, "*");
      setIsSoundOn(false);

      // open webinar
      window.open(webinarLink);
    } catch (error) {
      console.log(error);
    }
  };

  const isEventWebinarStarted = webinarLink?.length > 0;

  useEffect(() => {
    const fetchEventWebinar = async () => {
      try {
        let type = "event";
        let id = eventId;
        if (stand?.id) {
          type = "stand";
          id = stand.id;
        }

        const { data } = await getWebinarLink({
          [`${type}_id`]: id,
        });

        if (!data || !data.link) {
          setWebinarLink("");
        } else {
          setWebinarLink(data.link);
        }
      } catch (error) {
        setWebinarLink("");
      }
    };

    const interval = setInterval(() => {
      fetchEventWebinar();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [eventId, stand?.id]);

  const isWebinarButtonActive =
    (stand && stand.id && isEventWebinarStarted) || (eventId && isEventWebinarStarted);

  return (
    <div className="side-panel">
      {[
        {
          path: sidebarPath("chat"),
          text: "Чат",
          img: ChatSvg,
          activeQueryParams: ["sidebar=chat"],
          onClick: stand && stand.id ? chatOnClick : null,
          isActive: true,
        },
        {
          path: history.location.pathname + history.location.search,
          text: "Вебинар",
          img: WebinarSvg,
          activeQueryParams: ["sidebar=vebinar"],
          onClick: isWebinarButtonActive ? webinarOnClick : null,
          isActive: isWebinarButtonActive,
          className: isWebinarButtonActive ? "panel-link--webinar-started" : "",
        },
      ].map((l, ind) => (
        <PanelLink
          key={ind}
          path={l.path}
          text={l.text}
          isActive={l.isActive}
          img={l.img}
          activeQueryParams={l.activeQueryParams}
          onClick={l.onClick}
          className={l.className || ""}
        />
      ))}
    </div>
  );
};

export default SidePanel;
