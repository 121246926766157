import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { list, table } from "../../../assets";

const ContactsNav = () => {
  const { id } = useParams();

  const role = useSelector((state) => state.user.profile.role);

  return (
    <nav className="stand-settings__contacts-nav">
      <NavLink
        to={
          role === "участник"
            ? `/stand/${id}/settings/contacts/list`
            : `/event/${id}/settings/contacts/list`
        }
        activeClassName="active"
      >
        <div className="event-calendar__nav-item">
          <img src={list} alt="Nav Icon" />
        </div>
      </NavLink>
      <NavLink
        to={
          role === "участник"
            ? `/stand/${id}/settings/contacts/cards`
            : `/event/${id}/settings/contacts/cards`
        }
        activeClassName="active"
      >
        <div className="event-calendar__nav-item">
          <img src={table} alt="Nav Icon" />
        </div>
      </NavLink>
    </nav>
  );
};

export default ContactsNav;
