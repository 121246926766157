import React from "react";
import ReactSelect from "react-select";

const Select = ({ options, value, placeholder, onChange }) => {
  return (
    <ReactSelect
      value={value}
      onChange={onChange}
      options={options}
      isSearchable={false}
      styles={{
        control: (provided, state) => ({
          ...provided,
          backgroundColor: "#fff",
          fontSize: 13,
          borderColor: state.isFocused
            ? "#3b99cc !important"
            : state.hasValue
            ? "#727e8e"
            : "#e9ecf5",
          borderWidth: "1px",
          boxShadow: "none",
          "&:hover": {
            borderColor: state.hasValue ? "#727e8e" : "transparent",
          },
        }),
      }}
      components={{ IndicatorSeparator: null }}
      placeholder={placeholder}
    />
  );
};

export default Select;
