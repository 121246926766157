import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Layout } from "../../components/shared";
import { NavStand, StandHeader } from "../../components/stand";
import {
  AnalyticsAttendance,
  AnalyticsAverageVisit,
  AnalyticsContacts,
  AnalyticsDemography,
  AnalyticsGeography,
  AnalyticsStandInfo,
} from "../../components/stand/analytics";
import { fetchAnalytics } from "../../redux/eventReducer";
import { exportPageToPdf } from "../../utils/exportPageToPdf";
import { useCreateEventPageDisabled } from "../../hooks/create-event/usePageDisabled";
import { pageNames } from "../../components/organizer-panel/CreateEvent/EventSidebar";

export const DefaultPieChartColors = [
  "#FFE615",
  "#00DB3B",
  "#FD344F",
  "#0E42F8",
  "#FF9C29",
  "#00E1F0",
  "#F272DE",
  "#7991E2",
  "#1CF29C",
];

export const forPercents = (val, total) => total && ((100.0 * val) / total).toFixed(2);

const downloadPdfHiddenElementsSelectors = [
  ".analytics-attendance__date-btns",
  ".analytics-stand-geography__more-btn",
  ".analytics-traffic__more-btn",
  ".analytics-contacts__more-btn",
  ".stand-settings__button",
  ".stand-analytics__contacts",
];

const Analytics = () => {
  const { type, id } = useParams();
  const dispatch = useDispatch();
  const geo = useSelector((state) => state.events.analytics?.geo);
  const dem = useSelector((state) => state.events.analytics?.dem);

  const currentEvent = useSelector((state) => state.events.currentEvent);
  const currentStand = useSelector((state) => state.events.currentStand);

  const start_date =
    type === "stand" ? currentStand?.event?.start_date : currentEvent?.start_date;
  const end_date =
    type === "stand" ? currentStand?.event?.end_date : currentEvent?.end_date;

  const isPageDisabled = useCreateEventPageDisabled(pageNames.ANALYTICS);
  const disablePage = isPageDisabled && new Date() < new Date(end_date);

  useEffect(() => {
    const eventId = type === "stand" ? currentStand?.event_id : currentEvent?.id;
    const standId = type === "stand" ? currentStand?.id : null;
    if (!start_date || !end_date || !eventId || isPageDisabled) return;
    // use plus minus day to load statistics
    const tbeg = moment(start_date).subtract(1, "days").format("YYMMDD[T]HHmmss");
    const tend = moment(end_date).add(1, "days").format("YYMMDD[T]HHmmss");
    dispatch(fetchAnalytics({ eventId, standId, tbeg, tend }));
  }, [
    dispatch,
    type,
    id,
    isPageDisabled,
    currentEvent,
    currentStand,
    start_date,
    end_date,
  ]);

  return (
    <Layout>
      <div className="organizer-panel__main">
        <NavStand />
        <div className="stand-settings">
          <StandHeader
            title="Аналитика"
            subtitle="Здесь вы можете посмотреть отчет о выставке"
            button="Скачать pdf отчет"
            onClickButton={() =>
              exportPageToPdf({
                wrapperSelector: ".stand-analytics__main",
                hiddenElementsSelectors: downloadPdfHiddenElementsSelectors,
                fileName: "analytics-report",
              })
            }
            withHint={disablePage}
            buttonDisabled={disablePage}
            hintText={"Для получения информации дождитесь окончания мероприятия"}
          />
          <div className="stand-analytics__main">
            <div className="stand-analytics__first-column">
              <AnalyticsStandInfo />
              <AnalyticsAttendance />
              {!!dem && dem.length > 0 && <AnalyticsDemography />}
              <AnalyticsContacts />
            </div>
            <div className="stand-analytics__second-column">
              {!!geo && geo.length > 0 && <AnalyticsGeography />}
              <AnalyticsAverageVisit />
              {/* <AnalyticsTrafficSources /> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Analytics;
