import React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  ModerationBanner,
  ModerationStand,
  ModerationWebinar,
} from "../../components/events";
import { Layout } from "../../components/shared";
import { NavStand, StandHeader } from "../../components/stand";

const Moderation = () => {
  let { mType, id } = useParams();
  const history = useHistory();

  const afterBannerModeration = () => {
    history.push(`/event/${id}/settings/moderation/banners/list`);
  };

  const afterStandModeration = () => {
    history.push(`/event/${id}/settings/moderation/stands/list`);
  };

  let titleBack = "Модерация стенда";
  if (mType === "webinar") {
    titleBack = "Модерация вебинара";
  } else if (mType === "banner") {
    titleBack = "Модерация баннера";
  }

  return (
    <Layout>
      <div className="organizer-panel__main">
        <NavStand />
        <div className="stand-settings">
          <div className="stand-settings">
            <StandHeader title={titleBack} back />
            <div className="manage-moderation">
              {mType === "stand" && (
                <ModerationStand afterModeration={afterStandModeration} />
              )}
              {mType === "banner" && (
                <ModerationBanner afterModeration={afterBannerModeration} />
              )}
              {mType === "webinar" && <ModerationWebinar />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Moderation;
