import classNames from "classnames";
import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { createEvent } from "../../../api/event";
import { promotionDate } from "../../../assets";
import { setCreateEventInfo } from "../../../redux/appReducer";
import { setFillingPageName } from "../../../redux/eventReducer";
import { errorHandler } from "../../../utils/errorHandler";
import { prepareDateForApi } from "../../../utils/prepareDateForApi";
import { Input } from "../../shared";
import { EventHeader, SaveButton } from "./";
import { pageNames } from "./EventSidebar";

const FirstStep = ({ disabled }) => {
  const { id: eventId } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();
  const createEventInfo = useSelector((state) => state.app.createEventInfo);
  const currentEvent = useSelector((state) => state.events.currentEvent) || {};
  const eventName = currentEvent.name || createEventInfo.eventName;
  const startDate = currentEvent.start_date || createEventInfo.startDate;
  const endDate = currentEvent.end_date || createEventInfo.endDate;

  const onEventChange = (e) => {
    dispatch(setCreateEventInfo({ eventName: e.target.value }));
  };

  const onStartDate = (date) => {
    dispatch(setCreateEventInfo({ startDate: prepareDateForApi(date) }));

    if (endDate && date > new Date(endDate)) {
      dispatch(setCreateEventInfo({ endDate: "" }));
    }
  };

  const onEndDate = (date) => {
    dispatch(setCreateEventInfo({ endDate: prepareDateForApi(date) }));
  };

  const onSave = async () => {
    try {
      if (eventId) {
        return history.push(`/organizer-panel/packages/${eventId}`);
      }

      const formatedStartDate = moment(startDate).format("DD/MM/YYYY");
      const formatedEndDate = moment(endDate).format("DD/MM/YYYY");

      const { data } = await createEvent({
        end_date: formatedEndDate,
        start_date: formatedStartDate,
        name: eventName,
        type_id: 1,
      });

      const createdEventId = data.data.event_id;

      dispatch(
        setFillingPageName({
          eventId: createdEventId,
          name: pageNames.TARIFF_CHOSE,
        })
      );

      return history.push(`/organizer-panel/short-desc/${createdEventId}`);
    } catch (error) {
      errorHandler(error);
    }
  };

  const isAllow = eventName.length >= 7 && startDate && endDate && !disabled;

  return (
    <div className="create-stand__content create-stand-content">
      <EventHeader
        text="Короткое описание"
        SaveButton={<SaveButton disabled={!isAllow} onClick={onSave} />}
      />
      <div className="create-stand-content__main">
        <div className="create-stand-content__form">
          <Input
            error={
              eventName.trim().length > 0 && eventName.trim().length < 7 ? (
                <p className="input__text">
                  Название мероприятия не может содержать меньше 7 символов
                </p>
              ) : null
            }
            maxLength={191}
            disabled={disabled}
            value={eventName}
            onChange={onEventChange}
            placeholder="Введите название мероприятия"
            isFilled={eventName.length >= 5}
            label="Название мероприятия*"
          />
          <div className="create-promotion__date">
            <div className="input input--promotion">
              <label className="input__label">Дата начала</label>
              <DatePicker
                selected={startDate ? new Date(startDate) : null}
                onChange={(date) => onStartDate(date)}
                dateFormat="dd-MM-yyyy"
                className={classNames("default-input", "input__date", {
                  "default-input--filled": startDate,
                })}
                minDate={new Date()}
                placeholderText="дд/мм/гг"
                disabled={disabled}
              />
              <img
                className="create-promotion__date-icon"
                src={promotionDate}
                alt="Reset Icon"
              />
            </div>
            <div className="input input--promotion">
              <label className="input__label">Дата окончания</label>
              <DatePicker
                selected={endDate ? new Date(endDate) : null}
                onChange={(date) => onEndDate(date)}
                dateFormat="dd-MM-yyyy"
                className={classNames("default-input", "input__date", {
                  "default-input--filled": endDate,
                })}
                minDate={startDate ? new Date(startDate) : new Date()}
                placeholderText="дд/мм/гг"
                disabled={disabled}
              />
              <img
                className="create-promotion__date-icon"
                src={promotionDate}
                alt="Reset Icon"
              />
            </div>
          </div>
        </div>
        <div className="create-stand-content__sidebar"></div>
      </div>
    </div>
  );
};

export default FirstStep;
