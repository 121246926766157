import React, { useState } from "react";
import { closePopupSvg } from "../../../assets";
import { ColorPicker, TexturePicker } from "./";

const paintTypes = [
  {
    text: "Сплошная заливка или тектура",
    type: "color",
  },
  {
    text: "Рисунок или тектура",
    type: "image",
  },
];

const ColorPopup = ({ closePopup, textures, allMasks }) => {
  const [paintType, setPaintType] = useState("color");

  const colorMask = allMasks.find((m) => m.type === "color") || {};

  return (
    <div className="color-popup">
      <div className="color-popup__bg"></div>
      <div className="color-popup__wrapper">
        <div className="color-popup__header">
          <h2>Окрашивание</h2>
          <button onClick={closePopup}>
            <img src={closePopupSvg} alt="" />
          </button>
        </div>
        <div className="color-popup__paint-types">
          {paintTypes.map((t, i) => (
            <label key={i} className="color-popup__paint-type">
              <input
                type="radio"
                name="paint-type"
                value={t.type}
                checked={paintType === t.type}
                onChange={(e) => setPaintType(e.target.value)}
              />
              <span>{t.text}</span>
            </label>
          ))}
        </div>
        {paintType === "color" && (
          <ColorPicker closePopup={closePopup} colorMask={colorMask} />
        )}
        {paintType === "image" && (
          <TexturePicker
            closePopup={closePopup}
            textures={textures}
            colorMask={colorMask}
          />
        )}
      </div>
    </div>
  );
};

export default ColorPopup;
