import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { convertRole } from "../utils/convertRole";
import { createContact, getChat, getContacts, markRead } from "../api/chat";
import { errorHandler } from "../utils/errorHandler";

export const parseContacts = (contacts) => {
  let items = [];

  contacts?.forEach((c) => {
    items.unshift({
      id: c.id.toString(),
      name: c.name,
      eventName: c.event_name,
      email: c.email,
      phone: c.phone,
      role: convertRole(c.role),
      eventId: c.event_id,
      standId: c.stand_id,
      photo: c.avatar,
      count: c.count,
    });
  });

  return items;
};

const parseMessages = (state, { id, messages }) => {
  if (messages) {
    let chatId = id.toString();
    let chatIndex = state.messages.findIndex((chat) => chat.id === chatId);
    let chat = null;
    if (chatIndex < 0) {
      chat = {
        id: chatId,
        messages: [],
      };
    } else {
      chat = state.messages[chatIndex];
    }

    let unread = 0;
    let check = false;
    for (let k in messages) {
      let m = messages[k];
      if (m && !chat.messages.find((it) => it.id === m.id)) {
        check = true;
        chat.messages.push({
          id: m.id,
          text: m.body,
          isSelf: m.is_your === 1,
          userName: m.sender.name,
          userImage: m.sender.avatar,
          time: m.date,
        });
      }
      if (!m.is_seen) {
        unread++;
      }
    }

    if(check){
      chat.messages.sort((a, b) => a.id - b.id);
      if (chatIndex < 0) {
        state.messages.push(chat);
      } else {
        state.messages[chatIndex] = chat;
      }
    }

    // state.unread = 0;
    let unreadAll = 0;
    state.contacts.forEach((item) => {
      if (item.id.toString() === chatId && item.count !== unread) {
        item.count = unread;
      }
      unreadAll += item.count;
    });
    if(state.unread !== unreadAll){
      state.unread = unreadAll;
    }
  }
};

const initialState = {
  tab: "chats",
  current: null,
  messages: [],
  contacts: [],
  unread: 0,
};

export const fetchContacts = createAsyncThunk("contacts/get", async (params) => {
  try {
    const { data } = await getContacts(params);

    const resp = data.data;
    if (!resp) {
      return;
    }

    return resp;
  } catch (error) {
    errorHandler(error);
  }
});

export const fetchChat = createAsyncThunk("chat/get-by-id", async (id) => {
  try {
    const { data } = await getChat({ chatId: id });

    const resp = data.data.items;
    if (!resp) {
      return;
    }

    return {
      id,
      messages: resp,
    };
  } catch (error) {
    errorHandler(error);
  }
});

export const fetchContactByStandId = createAsyncThunk(
  "contact/get-by-stand-id",
  async (id) => {
    try {
      const { data } = await createContact({ side: "stand", standId: id });

      const resp = data.data?.chat;
      if (!resp) {
        return;
      }

      return resp;
    } catch (error) {
      errorHandler(error);
    }
  }
);

export const markReadChat = createAsyncThunk("contact/mark-read-chat", async (id) => {
  try {
    const { data } = await markRead({ id });

    const resp = data?.success;
    if (!resp) {
      return;
    }

    return id;
  } catch (error) {
    errorHandler(error);
  }
});

export const chatSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    setTab(state, { payload }) {
      state.tab = payload;
    },
    setCurrentChat(state, { payload }) {
      state.current = payload;
    },
    setCount(state, { payload }) {
      state.current = payload;
    },
    setContacts(state, { payload }) {
      state.contacts = parseContacts(payload);
    },
    setMessages(state, { payload }) {
      parseMessages(state, payload);
    },
  },
  extraReducers: {
    [markReadChat.fulfilled]: (state, { payload: id }) => {
      if (id && state.contacts) {
        state.unread = 0;
        state.contacts.forEach((item) => {
          if (item.id.toString() === id.toString()) {
            item.count = 0;
          }
          state.unread += item.count;
        });
      }
    },
    [fetchContacts.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.contacts = parseContacts(payload);
        state.unread = 0;
        state.contacts.forEach((item) => {
          state.unread += item.count;
        });
      }
    },
    [fetchChat.fulfilled]: (state, { payload }) => {
      if (payload) {
        parseMessages(state, payload);
      }
    },
    [fetchContactByStandId.fulfilled]: (state, { payload }) => {
      if (payload) {
        let contact = parseContacts([payload]);
        if (contact) {
          contact = contact[0];
        }

        if (!state.contacts.find((con) => con.id === contact.id)) {
          state.contacts.push(contact);
        }
        state.current = contact;
      }
    },
  },
});

export const { setCurrentChat, setMessages, setContacts, setTab } = chatSlice.actions;
export default chatSlice.reducer;
