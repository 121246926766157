import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { pageNames } from "../../components/organizer-panel/CreateEvent/EventSidebar";
import { Layout } from "../../components/shared";
import { NavStand, StandHeader } from "../../components/stand";
import {
  WebinarCards,
  WebinarCreatePopup,
  WebinarNav,
} from "../../components/stand/webinar";
import { useCreateEventPageDisabled } from "../../hooks/create-event/usePageDisabled";
import {
  fetchWebinars,
  fetchWebinarStats,
  resetCurrentWebinar,
  resetWebinars,
} from "../../redux/webinarReducer";

const Webinar = () => {
  const { id, type } = useParams();

  const dispatch = useDispatch();

  const selectedCardsType = useSelector((state) => state.webinars.type);

  const [isCreatePopupOpened, setIsCreatePopupOpened] = useState(false);

  const onCreateClick = () => {
    setIsCreatePopupOpened(true);
    dispatch(resetCurrentWebinar());
  };

  useEffect(() => {
    const params = {
      type: selectedCardsType,
      [`${type}Id`]: id,
    };
    dispatch(resetWebinars());
    dispatch(fetchWebinars(params));
    dispatch(
      fetchWebinarStats({
        [`${type}Id`]: id,
      })
    );
  }, [dispatch, id, type, selectedCardsType]);

  const isPageDisabled = useCreateEventPageDisabled(pageNames.WEBINAR);

  return (
    <Layout>
      <div className="organizer-panel__main">
        {isCreatePopupOpened && (
          <WebinarCreatePopup closePopup={() => setIsCreatePopupOpened(false)} />
        )}
        <NavStand />
        <div className="stand-settings">
          <StandHeader
            button="Создать вебинар"
            onClickButton={onCreateClick}
            title={"Вебинар"}
            subtitle={"Настройте ваш вебинар"}
            buttonDisabled={isPageDisabled}
            withHint={false}
          />
          <main className="webinar">
            <WebinarNav />
            <WebinarCards openCreatePopup={() => setIsCreatePopupOpened(true)} />
          </main>
        </div>
      </div>
    </Layout>
  );
};

export default Webinar;
