import React from "react";
import { Element } from "react-scroll";
import { clearString } from "../../../../utils";

const DemoAbout = ({ text, colorTitle, colorText, logo }) => {
  if (!text || !logo) {
    return null;
  }

  return (
    <Element name="О компании" className="demo-section">
      <div className="demo__container">
        <h3 className="demo-section__title" style={{ color: colorTitle }}>
          О компании
        </h3>
        <div className="demo-about__main">
          {logo && (
            <div className="demo-about__logo">
              <img src={logo} className="" alt="" />
            </div>
          )}
          <div className="demo-section__text about" style={{ color: colorText }}>
            {clearString(text)}
          </div>
        </div>
      </div>
    </Element>
  );
};

export default DemoAbout;
