import moment from "moment";
import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeMessage } from "../../../api/chat";
import { TelegramSvg } from "../../../assets";
import { fetchChat, setMessages } from "../../../redux/chatReducer";
import { Message } from "./Message";

export const Messages = ({ mainRef }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const contact = useSelector((state) => state.chats.current);
  const [chatId, setChatId] = useState(contact ? contact.id : null);

  let messages = useMemo(() => [], []);
  let chat = useSelector((state) =>
    contact
      ? state.chats.messages.find((ch) => {
          return ch.id === chatId;
        })
      : null
  );

  if (chat && chat.messages) {
    messages = chat.messages;
  }

  useEffect(() => {
    if (contact && contact.id) {
      setChatId(contact.id);
    }
  }, [contact]);

  useEffect(() => {
    if (chatId && chatId !== "new") {
      dispatch(fetchChat(chatId));
    }
  }, [dispatch, chatId]);

  const [newMessageText, setNewMessageText] = useState("");
  const onMessageTextChange = (evt) => {
    setNewMessageText(evt.target.value);
  };

  const [formError, setFormError] = useState("");

  const onSubmit = async (evt) => {
    try {
      evt.preventDefault();
      const messageText = newMessageText.trim();

      if (messageText.length === 0) {
        return setFormError("Введите текст сообщения");
      }

      const { data } = await storeMessage({
        chatId: contact.id,
        text: messageText,
      });

      let respChatId = data.data.chat_id.toString();

      dispatch(
        setMessages({
          id: respChatId,
          messages: [
            {
              id: data.data.id,
              sender: {
                id: profile.id,
                name: profile.fullName,
                avatar: profile.avatar,
              },
              body: messageText,
              is_your: 1,
              date: moment().format(),
            },
          ],
        })
      );

      setNewMessageText("");
      setFormError("");

      scrollToBottom();
    } catch (error) {
      console.error(error);
    }
  };

  const messagesRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    mainRef.current.scrollTop = messagesRef.current?.clientHeight;
  }, [mainRef]);

  useEffect(() => {
    scrollToBottom();
  }, [mainRef, scrollToBottom]);

  return (
    <>
      <div className="side-chat__messages" ref={messagesRef}>
        {messages.map((mes) => (
          <Message
            key={mes.id}
            userImage={mes.userImage}
            text={mes.text}
            isSelf={mes.isSelf}
            time={mes.time}
            userName={mes.userName}
          />
        ))}
      </div>
      <div className="side-chat__footer">
        <form onSubmit={onSubmit} className="side-chat__message-form">
          <input
            placeholder="Напишите свой текст..."
            type="text"
            className={`side-chat__text-input ${
              formError ? " side-chat__text-input--error" : ""
            }`}
            value={newMessageText}
            onChange={onMessageTextChange}
          />
          <button type="submit" className="side-chat__send-btn">
            <img src={TelegramSvg} alt="" />
          </button>
        </form>
        {formError && <span className="side-chat__form-error">{formError}</span>}
      </div>
    </>
  );
};
