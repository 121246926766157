import { getToken, instance, updateUser } from ".";
import { updateBankInfo, updateOrganizationInfo } from "./member";
import moment from "moment";

export const changeRole = (roleId) => {
  const token = getToken();

  return instance.get(`/api/v1/user/change-role/${roleId}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const saveJuridicalInfo = async ({
  organizationName,
  inn,
  registrationNumber,
  bankName,
  bic,
  billNumber,
  correspondingNumber,
  contactPhone,
  user,
  kpp,
  justification,
  address,
}) => {
  await Promise.all([
    updateOrganizationInfo({
      inn,
      organization_name: organizationName,
      registration_number: registrationNumber,
      kpp,
      justification,
      address,
    }),
    updateBankInfo({
      bank_name: bankName,
      bic,
      bill_number: billNumber,
      corresponding_number: correspondingNumber,
      valute: 1,
    }),
  ]);

  const validatedBirth = user.birth && moment(user.birth).format("DD.MM.YYYY");

  const { data } = await updateUser({
    birth: validatedBirth,
    company: "",
    country_id: user.countryId,
    email: user.email,
    full_name: user.fullName,
    phone: contactPhone,
    position: user.position,
  });

  return data?.data?.user;
};
