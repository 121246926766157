export const getCropedFile = async (cropInfo) => {
  if (!cropInfo) throw new Error("Image crop not found");

  const canvas = cropInfo.canvas;
  const imageExt = cropInfo.imageExt;

  const canvasBlob = await new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      `image/${imageExt}`,
      1
    );
  });

  return new File([canvasBlob], "image", {
    lastModified: new Date().getTime(),
    type: canvasBlob.type,
  });
};
