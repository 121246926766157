import { getToken, instance } from ".";

export const registerOrganizer = ({
  organization_name,
  inn,
  registration_number,
  phone_number,
  valute,
  bank_name,
  bill_number,
  bic,
  corresponding_number,
  password,
  password_confirmation,
}) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("organization_name", organization_name);
  formData.append("inn", inn);
  formData.append("registration_number", registration_number);
  formData.append("phone_number", phone_number);
  formData.append("valute", valute);
  formData.append("bank_name", bank_name);
  formData.append("bill_number", bill_number);
  formData.append("bic", bic);
  formData.append("corresponding_number", corresponding_number);
  formData.append("password", password);
  formData.append("password_confirmation", password_confirmation);

  return instance.post("/api/v1/create-organizer", formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getModerationStats = ({ id }) => {
  const token = getToken();

  return instance.get(`/api/v1/event/${id}/moderation/stats`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createEvent = ({ name, start_date, end_date, type_id }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("name", name);
  formData.append("start_date", start_date);
  formData.append("end_date", end_date);
  formData.append("type_id", type_id);

  return instance.post("/api/v1/event/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createEventDetailDescription = ({
  type,
  limit,
  limitDaily,
  questions,
  eventId,
  logo,
  shortDescription,
}) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("visit_type", type);
  formData.append("visit_limit", limit);
  formData.append("visit_limit_daily", limitDaily);
  questions.forEach((k, val) => {
    formData.append(`questions[${val}]`, k);
  });
  formData.append("logo", logo);
  formData.append("short_description", shortDescription);

  return instance.post(`/api/v1/event/${eventId}/registration`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createEventChooseTemplate = ({ tmplId, eventId }) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("tmpl_id", tmplId);

  return instance.post(`/api/v1/event/${eventId}/choose-tmpl`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createEventLegalInfo = ({
  organization,
  directorName,
  registrationCode,
  actionBasis,
  juridicalAdress,
  contactPhone,
  inn,
  registrationNumber,
  bankName,
  withVAT,
  bic,
  correspondingNumber,
  eventId,
  billNumber,
}) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("organization", organization);
  formData.append("inn", inn);
  formData.append("vat", withVAT);
  formData.append("head_fio", directorName);
  formData.append("kpp", registrationCode);
  formData.append("justification", actionBasis);
  formData.append("address", juridicalAdress);
  formData.append("phone", contactPhone);
  formData.append("registration_number", registrationNumber);
  formData.append("bank_name", bankName);
  formData.append("bic", bic);
  formData.append("corresponding_number", correspondingNumber);
  formData.append("bill_number", billNumber);

  return instance.post(`/api/v1/event/${eventId}/legal-info`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createEventChooseTariff = ({ eventId, tariffId }) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("tariff_id", tariffId);

  return instance.post(`/api/v1/event/${eventId}/choose-tariff`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getEventTypes = () => {
  const token = getToken();

  return instance.get("/api/v1/event/types", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setEventSiteForm = ({ form, eventId }) => {
  const token = getToken();
  const formData = new URLSearchParams();
  formData.append("data", form);
  return instance.post(`/api/v1/cache/set/${eventId}-event-site-form`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getEventSiteForm = ({ eventId }) => {
  const token = getToken();

  return instance.get(`/api/v1/cache/get/${eventId}-event-site-form`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setEventSiteHeaderBg = ({ eventId, bg }) => {
  if (!bg) {
    return;
  }
  const token = getToken();

  const formData = new FormData();
  formData.append("file", bg);

  return instance.post(`/api/v1/cache/file/${eventId}-event-site-bg`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setEventSiteLogo = ({ logo, id }) => {
  if (!logo) {
    return;
  }
  const token = getToken();

  const formData = new FormData();
  formData.append("file", logo);

  return instance.post(`/api/v1/cache/file/${id}-event-site-logo`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setEventSiteParticipantImage = ({ image, eventId, participantNumber }) => {
  if (!image) {
    return;
  }
  const token = getToken();

  const formData = new FormData();
  formData.append("file", image);

  return instance.post(
    `/api/v1/cache/file/${eventId}-event-site-participant-img${participantNumber}`,
    formData,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
};

export const setEventSiteSpeakerImage = ({ image, eventId, speakerNumber }) => {
  if (!image) {
    return;
  }
  const token = getToken();

  const formData = new FormData();
  formData.append("file", image);

  return instance.post(
    `/api/v1/cache/file/${eventId}-event-site-speaker-img${speakerNumber}`,
    formData,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
};

export const setEventWebinar = ({ webinar, eventId }) => {
  const token = getToken();
  const formData = new URLSearchParams();
  formData.append("data", webinar);
  return instance.post(`/api/v1/cache/set/${eventId}-event-webinar`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getEventWebinar = ({ eventId }) => {
  const token = getToken();

  return instance.get(`/api/v1/cache/get/${eventId}-event-webinar`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setEventLogo = ({ logo, eventId }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("file", logo);

  return instance.post(`/api/v1/cache/file/${eventId}-event-logo`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setEventPdf = ({ pdf, eventId }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("file", pdf);

  return instance.post(`/api/v1/cache/file/${eventId}-event-pdf`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setFirstEventImage = ({ image, eventId }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("file", image);

  return instance.post(`/api/v1/cache/file/${eventId}-event-first-image`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setSecondEventImage = ({ image, eventId }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("file", image);

  return instance.post(`/api/v1/cache/file/${eventId}-event-second-image`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setEventModelSettings = ({ settings, eventId }) => {
  const token = getToken();
  const formData = new URLSearchParams();
  formData.append("data", settings);

  return instance.post(`/api/v1/cache/set/${eventId}-event-settings`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setEventMedia = ({ eventId, mediaType, file, rect, thumb, link, name }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("rect", rect);
  if (file) formData.append("file", file);
  if (thumb) formData.append("thumb", thumb);
  if (link) formData.append("link", link);
  if (name) formData.append("name", name);

  return instance.post(`/api/v1/event/${eventId}/media/${mediaType}`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getEventModelSettings = ({ eventId }) => {
  const token = getToken();

  return instance.get(`/api/v1/cache/get/${eventId}-event-settings`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getRoomTemplates = ({ type }) => {
  const token = getToken();

  return instance.get(`/api/v1/room-templates?type=${type}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getStandTariffs = ({ eventId }) => {
  const token = getToken();

  return instance.get(`/api/v1/event/${eventId}/tariff`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getParticipants = (eventId) => {
  const token = getToken();

  return instance.get(`/api/v1/event/${eventId}/participants`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getStands = ({ eventId }) => {
  const token = getToken();

  return instance.get(`/api/v1/event/${eventId}/stands`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getParticipantInfo = ({ eventId, participantId }) => {
  const token = getToken();

  return instance.get(`/api/v1/event/${eventId}/participant/${participantId}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const changeTariff = ({ eventId, setting, items }) => {
  const token = getToken();

  return instance.post(
    `/api/v1/event/${eventId}/tariff/${setting}`,
    { items },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
};

export const exportParticipants = ({ eventId }) => {
  const token = getToken();

  return instance.get(`/api/v1/event/${eventId}/participants/export`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createMailing = ({ eventId, standId, file }) => {
  const token = getToken();

  let type = "stand";
  let id = standId;
  if (eventId) {
    type = "event";
    id = eventId;
  }

  const formData = new FormData();
  formData.append("file", file);

  return instance.post(`/api/v1/${type}/${id}/create/mailing`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const sendEmails = ({ eventId, standId, emails }) => {
  const token = getToken();

  const formData = new FormData();

  emails.forEach((item, ind) => {
    formData.append(`emails[${ind}]`, item);
  });

  let type = "stand";
  let id = standId;
  if (eventId) {
    type = "event";
    id = eventId;
  }

  return instance.post(`/api/v1/${type}/${id}/send-emails`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};
