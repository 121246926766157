import { immediateToast } from "izitoast-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { createMailing } from "../../api/organizer";
import { pageNames } from "../../components/organizer-panel/CreateEvent/EventSidebar";
import { Layout } from "../../components/shared";
import PopupInvitation from "../../components/shared/PopupInvitation";
import { NavStand, StandHeader } from "../../components/stand";
import {
  MailingNav,
  MailingSent,
  MailingTemplates,
} from "../../components/stand/mailing";
import { useCreateEventPageDisabled } from "../../hooks/create-event/usePageDisabled";
import { fetchMailings, fetchMailingTemplates } from "../../redux/mailingReducer";
import { errorHandler } from "../../utils/errorHandler";

const Mailing = () => {
  const { type, id, tabType } = useParams();
  const history = useHistory();
  const [popupMailing, setPopupMailing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMailingTemplates({ type, id }));
    dispatch(fetchMailings({ type, id }));
  }, [dispatch, type, id]);

  const onSaveMailing = async (file) => {
    try {
      const { data } = await createMailing({ type, id, file });
      if (data.success) {
        immediateToast("success", {
          message: data.message,
          position: "topCenter",
        });
        setTimeout(() => {
          setPopupMailing(false);
        }, 2000);
      }
      console.log(data);
    } catch (e) {
      errorHandler(e);
    }
  };

  const isPageDisabled = useCreateEventPageDisabled(pageNames.MAILING);

  const validFileExtensions = [".xls", ".xlsx", ".txt"];

  const createMailingClick = () => {
    history.push(`/${type}/${id}/settings/mailing/templates`);
  };

  return (
    <Layout>
      <div className="organizer-panel__main">
        {popupMailing && (
          <PopupInvitation
            onClose={() => setPopupMailing(false)}
            onSave={onSaveMailing}
            validFileExtensions={validFileExtensions}
            title="Стандартная рассылка"
            typeWarnMsg='txt или xls файл, где адреса разделены знаком ";"'
          />
        )}
        <NavStand />
        <div className="stand-settings">
          <StandHeader
            title="Настраиваемая рассылка"
            withHint={isPageDisabled}
            buttons={[
              <button
                disabled={isPageDisabled}
                className="red-btn"
                onClick={createMailingClick}
              >
                Создать рассылку
              </button>,
            ]}
            classes="stand-settings__header--mailing"
          />
          <div className="mailing">
            <MailingNav />
            {!tabType && (
              <div className="mailer-inner">
                <MailingSent />
              </div>
            )}
            {tabType === "templates" && <MailingTemplates />}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Mailing;
