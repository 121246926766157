import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getToken } from "../../api";
import {
  setCurrentStandPosition,
  setCurrentStandInPlayer,
} from "../../redux/eventReducer";
import { downloadPdf } from "../../utils/downloadPdf";

const EventLocation = ({ frameRef }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);

  const currentEvent = useSelector((state) => state.events.currentEvent);
  const parsedQuery = queryString.parse(history.location.search);

  const currentLocation = parsedQuery.location;

  const { id } = useParams();
  const playerAddress = useSelector((state) => state.app.settings.playerUrl);

  const [isPlayerLoaded, setIsPlayerLoaded] = useState(false);

  const [playerAddressHere, setPlayerAddressHere] = useState(null);

  useEffect(() => {
    if (playerAddress && playerAddressHere !== playerAddress) {
      setPlayerAddressHere(playerAddress);
    }
  }, [playerAddress, playerAddressHere]);

  //стандартное перемещение по нажатию на кнопку
  useEffect(() => {
    if (!isPlayerLoaded) {
      return;
    }

    const currentMemberId = parsedQuery.memberId
      ? parsedQuery.memberId.replace(/\D/g, "")
      : null;

    if (!currentLocation) {
      history.push(history.location.pathname + "?location=lobby");
    }

    if (currentMemberId && currentLocation !== "member") {
      const newQuery = queryString.exclude(
        history.location.pathname + history.location.search,
        ["memberId"]
      );

      history.push(newQuery);
    }

    if (currentLocation === "lobby") {
      return frameRef.current.contentWindow.postMessage("idb4", "*");
    }

    if (currentLocation === "place") {
      return frameRef.current.contentWindow.postMessage("idb5", "*");
    }

    if (currentLocation === "member" && currentMemberId) {
      return frameRef.current.contentWindow.postMessage(
        JSON.stringify({
          type: "goToStand",
          id: `place${currentMemberId}`,
        }),
        "*"
      );
    }

    if (currentLocation === "expo") {
      return frameRef.current.contentWindow.postMessage("idb6", "*");
    }
  }, [currentLocation, frameRef, history, isPlayerLoaded, parsedQuery.memberId]);

  // обработка перемещения с помощью кнопок в unity
  useEffect(() => {
    const messageHandler = (evt) => {
      let message;
      try {
        message = JSON.parse(evt.data);
      } catch (e) {
        message = evt.data;
      }

      if (message.type === "goToMemberStend" && message.stand_info) {
        const standInfo = message.stand_info || JSON.parse(message.stand_info);
        const standNumber = parseInt(message.stendNumb.replace(/\D/g, ""));
        dispatch(setCurrentStandPosition(standNumber));
        if (standInfo && standInfo.id) {
          dispatch(setCurrentStandInPlayer(standInfo));
        } else {
          dispatch(setCurrentStandInPlayer(null));
        }
        history.push(
          history.location.pathname + `?location=member&memberId=${message.stendNumb}`
        );
      }
    };

    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [dispatch, currentEvent, history]);

  // скачивание пдф файла
  useEffect(() => {
    const messageHandler = (evt) => {
      let message;
      try {
        message = JSON.parse(evt.data);
      } catch (e) {
        message = evt.data;
      }

      if (message.method === "downloadPresentation" && message.filename) {
        const fileUrl = message.filename;

        downloadPdf(fileUrl);
      }
    };

    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  // передача токена в iframe
  // обработка полной загрузки плеера
  useEffect(() => {
    const messageHandler = (evt) => {
      let message;
      try {
        message = JSON.parse(evt.data);
      } catch (e) {
        message = evt.data;
      }

      if (message.method === "GetAuthTokenCB") {
        const token = getToken();

        frameRef.current.contentWindow.postMessage(
          JSON.stringify({
            type: "setAuthToken",
            token: token,
            exhibition_id: `events:${id}`,
            user_id: profile.id,
          }),
          "*"
        );
      } else if (message.method === "ExhibitionLoaded") {
        setIsPlayerLoaded(true);
      }
    };

    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [frameRef, history.location.search, id, parsedQuery.memberId, profile.id]);

  // переход в лобби по нажатию на зеленый круг
  useEffect(() => {
    const messageHandler = (evt) => {
      let message;
      try {
        message = JSON.parse(evt.data);
      } catch (e) {
        message = evt.data;
      }

      if (message.state === "Visitor" && currentLocation === "lobby") {
        history.push(history.location.pathname + "?location=place");
      }
    };

    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [currentLocation, history]);

  return (
    <div className="event-location">
      {playerAddressHere && (
        <iframe
          src={playerAddressHere}
          className="event-location__frame"
          frameBorder="0"
          title="event-location"
          ref={frameRef}
        ></iframe>
      )}
    </div>
  );
};

export default EventLocation;
