import classNames from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWebinarType } from "../../../redux/webinarReducer";

const links = [
  {
    text: "Актуальные",
    type: "actual",
  },
  {
    text: "На модерации",
    type: "moderation",
  },
  {
    text: "Черновики",
    type: "draft",
  },
  {
    text: "Отклоненные",
    type: "denied",
  },
];

const Nav = () => {
  const dispatch = useDispatch();

  const onNavItemClick = (type) => {
    dispatch(setWebinarType(type));
  };
  const webinarType = useSelector((state) => state.webinars.type);
  const stats = useSelector((state) => state.webinars.stats);

  return (
    <div className="webinar__nav">
      {links.map((l, i) => (
        <div
          key={i}
          onClick={() => onNavItemClick(l.type)}
          className={classNames("favorite-nav__item", {
            "favorite-nav__item--active": webinarType === l.type,
          })}
        >
          {l.text} <span>{stats[l.type]}</span>
        </div>
      ))}
    </div>
  );
};

export default Nav;
