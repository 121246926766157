import classNames from "classnames";
import { immediateToast } from "izitoast-react";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getEventInfo } from "../../api";
import { getProgramEvents } from "../../api/event";
import { getLanding, storeLanding, uploadFile } from "../../api/landing";
import { getParticipants } from "../../api/organizer";
import {
  addSection,
  avatarPlus,
  CreateWebDelete,
  promotionDate,
  resetProfile,
} from "../../assets";
import { pageNames } from "../../components/organizer-panel/CreateEvent/EventSidebar";
import { Layout, Loader } from "../../components/shared";
import { NavStand, ProgramEvents } from "../../components/stand";
import { UserImage } from "../../components/stand/create-web/UserImage";
import {
  CreatedWebInfo,
  CreateWebSuccessPopup,
  LeaveCreateWebHandler,
  PromotionHeader,
  PromotionWebColors,
  PromotionWebHeader,
} from "../../components/stand/promotion";
import { useCreateEventPageDisabled } from "../../hooks/create-event/usePageDisabled";
import {
  setDemoBackgroundColor,
  setDemoTextColor,
  setDemoTitleColor,
  setNewDemoSite,
} from "../../redux/appReducer";
import { errorHandler } from "../../utils/errorHandler";
import { errorMessage } from "../../utils/errorMessage";
import {
  generateTextAboutAvailableResolution,
  generateTextErrorMaxResolution,
  generateTextErrorMaxSize,
  generateTextErrorMinResolution,
} from "../../utils/generateTextMessage";
import { getImageSize } from "../../utils/getImageSize";
import { prepareDateForApi } from "../../utils/prepareDateForApi";
import { prepareOrganizerProgram } from "../../utils/prepareOrganizerProgram";

const allowedImageFileType = "image/png, image/jpeg, image/jpg";

const CreateWebOrg = () => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const currentEvent = useSelector((state) => state.events.currentEvent);
  const [isLoading, setIsLoading] = useState(false);

  const eventType = currentEvent?.room_type;
  const isEventGallery = eventType === "gallery";

  const demoInfo = useSelector((state) => state.app.demo);
  const color = useSelector((state) => state.app.demo.color);
  const role = useSelector((state) => state.user.profile.role);
  const [landingUrl, setLandingUrl] = useState("");

  const [isSiteCreated, setIsSiteCreated] = useState(demoInfo.isCreated);

  const MaxImageSizeMb = 5;
  const MinImageResolution = { width: 1136, height: 640 };
  const MaxImageResolution = { width: 1920, height: 1080 };
  const TextAboutAllowedResolution = `Разрешение: ${generateTextAboutAvailableResolution(
    MinImageResolution,
    MaxImageResolution
  )}`;

  const [autocompletedFields, setAutocompletedFields] = useState({
    title: "",
    startDate: "",
    endDate: "",
    logo: "",
    about: "",
    program: [
      {
        date: "",
        events: [
          { timeStart: "", timeEnd: "", description: "" },
          { timeStart: "", timeEnd: "", description: "" },
        ],
      },
    ],
  });

  useEffect(() => {
    const fetchSiteInfo = async () => {
      try {
        let data;
        setIsLoading(true);

        try {
          const { data: landingData } = await getLanding({ eventId: id });

          data = landingData;
        } catch {
          throw new Error();
        }

        const output = data?.data;
        let formData;

        if (output) {
          formData = output.draft;

          if (!formData) {
            formData = output.main;
          }

          setLandingUrl(output.url || "");
        }

        const { data: eventData } = await getEventInfo({ event_id: id });
        const eventInfo = eventData.data.item;

        const { data: programEventsData } = await getProgramEvents({
          type: "event",
          standId: id,
        });

        const programEventsInfo = programEventsData.data;

        const fetchedProgram = prepareOrganizerProgram(programEventsInfo, {
          withWebinarSignature: true,
        });

        setAutocompletedFields((prev) => ({
          ...prev,
          title: eventInfo.name || "",
          startDate: eventInfo.start_date || "",
          endDate: eventInfo.end_date || "",
          logo: eventInfo.logo || "",
          about: eventInfo.short_description || "",
          program: fetchedProgram,
        }));

        // автозаполнение полей, если лендинг создается впервые
        if (!formData) {
          const [{ data: participantsData }] = await Promise.all([getParticipants(id)]);

          const participantsInfo = participantsData.data.items;

          if (participantsInfo) {
            setParticipants(
              participantsInfo
                .filter((part) => (part.is_lobby === 0 ? true : false))
                .map((part) => ({
                  name: part.name || "",
                  description: part.description || "",
                  image: part.logo,
                }))
            );
          }

          if (eventInfo) {
            setTitle(eventInfo.name || "");
            setStartDate(eventInfo.start_date || "");
            setEndDate(eventInfo.end_date || "");
            setLogo(eventInfo.logo || "");
            setAbout(eventInfo.short_description || "");

            setFooterText(eventInfo.info?.organization || "");
          }

          if (programEventsInfo) {
            setProgram(fetchedProgram);
          }

          setIsLoading(false);

          return;
        }

        setIsSiteCreated(true);

        dispatch(setDemoTextColor(formData.color.text));
        dispatch(setDemoTitleColor(formData.color.title));
        dispatch(setDemoBackgroundColor(formData.color.background));

        setTitle(formData.header.title);
        setSubtitle(formData.header.subtitle || "");
        setStartDate(formData.header.startDate);
        setEndDate(formData.header.endDate);
        setContactInfo(formData.header.contactInfo);
        setHeaderBg(formData.header.background);
        setVisibleMobileMain(formData.header.visibleMobile);

        setAbout(formData.main.about);
        setLogo(formData.main.logo);

        setParticipants(formData.main.participants);

        setProgram(formData.main.program);

        setSpeakers(formData.main.speakers);

        setFooterText(formData.footer.text);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!demoInfo.isDemo) {
      fetchSiteInfo();
    }
  }, [id, dispatch, demoInfo.isDemo]);

  const [visibleColors, setVisibleColors] = useState(true);
  const [visibleHeader, setVisibleHeader] = useState(true);
  const [visibleMain, setVisibleMain] = useState(true);
  const [visibleParticipants, setVisibleParticipants] = useState(true);
  const [visibleProgram, setVisibleProgram] = useState(true);
  const [visibleSpeakers, setVisibleSpeakers] = useState(true);

  const [logo, setLogo] = useState(demoInfo.main.logo);
  const [headerBg, setHeaderBg] = useState(demoInfo.header.background);
  const [title, setTitle] = useState(demoInfo.header.title);
  const [subtitle, setSubtitle] = useState(demoInfo.header.subtitle);
  const [startDate, setStartDate] = useState(demoInfo.header.startDate);
  const [endDate, setEndDate] = useState(demoInfo.header.endDate);

  const [about, setAbout] = useState(demoInfo.main.about);
  const [contactInfo, setContactInfo] = useState(demoInfo.header.contactInfo);
  const [visibleMobileMain, setVisibleMobileMain] = useState(
    demoInfo.header.visibleMobile
  );

  const [footerText, setFooterText] = useState(demoInfo.footer.text);

  const [successPopup, setSuccessPopup] = useState(false);

  const [program, setProgram] = useState(demoInfo.main.program);

  // name, description, image
  const [participants, setParticipants] = useState(demoInfo.main.participants);

  const onParticipantNameChange = ({ index, newValue }) => {
    setParticipants((prev) =>
      [...prev].map((part, ind) => (ind === index ? { ...part, name: newValue } : part))
    );
  };

  const onParticipantDescriptionChange = ({ index, newValue }) => {
    setParticipants((prev) =>
      [...prev].map((part, ind) =>
        ind === index ? { ...part, description: newValue } : part
      )
    );
  };

  const onParticipantImageChange = async ({ index, event }) => {
    const image = event.target.files[0];

    if (!image) {
      return;
    }

    // check size
    if (image.size > MaxImageSizeMb * 1024 * 1024) {
      return errorMessage(generateTextErrorMaxSize(MaxImageSizeMb));
    }

    // check resolution
    const { width, height } = await getImageSize(image);
    if (!width && !height) {
      return;
    }
    if (width < MinImageResolution.width || height < MinImageResolution.height) {
      return errorMessage(
        generateTextErrorMinResolution(
          MinImageResolution.width,
          MinImageResolution.height
        )
      );
    }
    if (width > MaxImageResolution.width || height > MaxImageResolution.height) {
      return errorMessage(
        generateTextErrorMaxResolution(
          MaxImageResolution.width,
          MaxImageResolution.height
        )
      );
    }

    try {
      const { data } = await uploadFile({
        eventId: id,
        file: image,
      });

      setParticipants((prev) =>
        [...prev].map((part, ind) =>
          ind === index ? { ...part, image: data.data.url } : part
        )
      );
    } catch (e) {}
  };

  const onClearImgParticipants = ({ index }) => {
    setParticipants((prev) =>
      [...prev].map((part, ind) => (ind === index ? { ...part, image: "" } : part))
    );
  };

  const [speakers, setSpeakers] = useState(demoInfo.main.speakers);

  const onSpeakerNameChange = ({ index, newValue }) => {
    setSpeakers((prev) =>
      [...prev].map((sp, ind) => (index === ind ? { ...sp, fullName: newValue } : sp))
    );
  };

  const onSpeakerPosChange = ({ index, newValue }) => {
    setSpeakers((prev) =>
      [...prev].map((sp, ind) => (index === ind ? { ...sp, pos: newValue } : sp))
    );
  };

  const onSpeakerImageChange = async ({ index, event }) => {
    const image = event.target.files[0];

    if (!image) {
      return;
    }

    // check size
    if (image.size > MaxImageSizeMb * 1024 * 1024) {
      return errorMessage(generateTextErrorMaxSize(MaxImageSizeMb));
    }

    // check resolution
    const { width, height } = await getImageSize(image);
    if (!width && !height) {
      return;
    }
    if (width < MinImageResolution.width || height < MinImageResolution.height) {
      return errorMessage(
        generateTextErrorMinResolution(
          MinImageResolution.width,
          MinImageResolution.height
        )
      );
    }
    if (width > MaxImageResolution.width || height > MaxImageResolution.height) {
      return errorMessage(
        generateTextErrorMaxResolution(
          MaxImageResolution.width,
          MaxImageResolution.height
        )
      );
    }

    try {
      const { data } = await uploadFile({
        eventId: id,
        file: image,
      });

      setSpeakers((prev) =>
        [...prev].map((sp, ind) => (index === ind ? { ...sp, image: data.data.url } : sp))
      );
    } catch (e) {}
  };

  const onSpeakerImageClear = ({ index }) => {
    setSpeakers((prev) =>
      [...prev].map((sp, ind) => (index === ind ? { ...sp, image: "" } : sp))
    );
  };

  const onHeaderBgChange = async (e) => {
    const bgFile = e.target.files[0];

    if (!bgFile) {
      return;
    }

    // check size
    if (bgFile.size > MaxImageSizeMb * 1024 * 1024) {
      return errorMessage(generateTextErrorMaxSize(MaxImageSizeMb));
    }

    // check resolution
    const { width, height } = await getImageSize(bgFile);
    if (!width && !height) {
      return;
    }
    if (width < MinImageResolution.width || height < MinImageResolution.height) {
      return errorMessage(
        generateTextErrorMinResolution(
          MinImageResolution.width,
          MinImageResolution.height
        )
      );
    }
    if (width > MaxImageResolution.width || height > MaxImageResolution.height) {
      return errorMessage(
        generateTextErrorMaxResolution(
          MaxImageResolution.width,
          MaxImageResolution.height
        )
      );
    }

    try {
      const { data } = await uploadFile({
        eventId: id,
        file: bgFile,
      });

      setHeaderBg(data.data.url);
    } catch (e) {}
  };

  const onLogoChange = async (e) => {
    const logo = e.target.files[0];

    if (!logo) {
      return;
    }

    // check size
    if (logo.size > MaxImageSizeMb * 1024 * 1024) {
      return errorMessage(generateTextErrorMaxSize(MaxImageSizeMb));
    }

    // check resolution
    const { width, height } = await getImageSize(logo);
    if (!width && !height) {
      return;
    }
    if (width < MinImageResolution.width || height < MinImageResolution.height) {
      return errorMessage(
        generateTextErrorMinResolution(
          MinImageResolution.width,
          MinImageResolution.height
        )
      );
    }
    if (width > MaxImageResolution.width || height > MaxImageResolution.height) {
      return errorMessage(
        generateTextErrorMaxResolution(
          MaxImageResolution.width,
          MaxImageResolution.height
        )
      );
    }

    try {
      const { data } = await uploadFile({
        eventId: id,
        file: logo,
      });
      setLogo(data.data.url);
    } catch (error) {}
  };

  const getFormDataForApi = ({ isDemo }) => {
    return {
      color: {
        text: color.text,
        title: color.title,
        background: color.background,
      },
      header: {
        title: title,
        subtitle: subtitle,
        background: headerBg,
        startDate: prepareDateForApi(startDate),
        endDate: prepareDateForApi(endDate),
        contactInfo,
        visibleMobile: visibleMobileMain,
      },
      main: {
        about: about,
        participants: visibleParticipants
          ? participants.filter((part) => part.image)
          : [],
        program: visibleProgram ? program.filter((pr) => pr.date) : [],
        speakers: visibleSpeakers
          ? speakers.filter((sp) => sp.fullName || sp.pos || sp.image)
          : [],
        logo,
        visibleParticipants,
        visibleProgram,
        visibleSpeakers,
      },
      footer: {
        text: footerText,
      },
      isDemo,
      isCreated: isSiteCreated,
      eventType,
    };
  };

  const prepareDemoData = () => {
    const data = getFormDataForApi({ isDemo: true });

    dispatch(setNewDemoSite(data));
  };

  const onNewDemoSite = () => {
    prepareDemoData();
    onDemo();
  };

  const onSubmit = async (e, isDraft) => {
    e.preventDefault();
    try {
      const params = getFormDataForApi({ isDemo: false });
      const { data } = await storeLanding({
        eventId: id,
        data: params,
        title,
        isDraft,
      });
      if (data.data?.url) {
        setLandingUrl(data.data?.url);
      }

      setIsSiteCreated(true);
      dispatch(setNewDemoSite(params));

      if (isDraft) {
        return immediateToast("success", {
          message: "Черновик успешно сохранен",
          position: "topCenter",
        });
      }

      setSuccessPopup(true);
    } catch (error) {
      errorHandler(error);
    }
  };

  const onDemo = () => {
    history.push(`/event/${id}/settings/create-web/demo`);
  };

  const isPageDisabled = useCreateEventPageDisabled(pageNames.LANDING);

  return (
    <Layout>
      <div className="organizer-panel__main">
        {successPopup && (
          <CreateWebSuccessPopup
            url={landingUrl}
            onClose={() => setSuccessPopup(false)}
          />
        )}
        {!isPageDisabled && (
          <LeaveCreateWebHandler
            message={(location) => {
              return location.pathname.startsWith(
                `/event/${id}/settings/create-web/demo`
              ) || location.pathname.startsWith(`/event/${id}/site`)
                ? true
                : "Вы действительно хотите покинуть страницу? Все несохраненные изменения будут потеряны";
            }}
          />
        )}
        <NavStand />
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div className="stand-settings">
            <PromotionHeader
              title="Создать веб сайт"
              subtitle={
                role === "участник"
                  ? "Генерация landing page на основе информации о стенде"
                  : "Генерация landing page на основе информации о мероприятии"
              }
              button="Предпросмотр сайта"
              onClickButton={onNewDemoSite}
              disabled={isPageDisabled}
              withHint={isPageDisabled}
              prepareDemoData={prepareDemoData}
            />
            {landingUrl && <CreatedWebInfo url={landingUrl} />}
            <form className="create-promotion__main create-promotion__main--web">
              <div className="create-promotion__item create-promotion__item--colors">
                <PromotionWebHeader
                  title="Цветовая палитра"
                  setVisible={() => setVisibleColors(!visibleColors)}
                />
                {visibleColors && <PromotionWebColors />}
              </div>
              <div className="create-promotion__item create-promotion__item--colors">
                <PromotionWebHeader
                  title={"Шапка"}
                  setVisible={() => setVisibleHeader(!visibleHeader)}
                />
                {visibleHeader && (
                  <div className="web-item web-item--top">
                    <div className="web-item__form">
                      <div className="input">
                        <label className="input__label">Заголовок</label>
                        <div className="input__wrapper">
                          <input
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className={classNames("input__main")}
                            placeholder="Приветствие"
                            type="text"
                          />
                          <img
                            src={resetProfile}
                            alt=""
                            className="input__reset-icon"
                            onClick={() => setTitle(autocompletedFields.title)}
                          />
                        </div>
                      </div>
                      <div className="input">
                        <label className="input__label">Подзаголовок</label>
                        <input
                          value={subtitle}
                          onChange={(e) => setSubtitle(e.target.value)}
                          className={classNames("input__main")}
                          placeholder="Подзаголовок"
                          type="text"
                        />
                      </div>
                      <div className="create-promotion__date">
                        <div className="input input--promotion">
                          <label
                            className="input__label"
                            style={{ position: "relative" }}
                          >
                            Дата начала
                            <img
                              src={resetProfile}
                              alt=""
                              className="input__reset-icon"
                              onClick={() => setStartDate(autocompletedFields.startDate)}
                            />
                          </label>
                          <DatePicker
                            selected={startDate ? new Date(startDate) : null}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="dd-MM-yyyy"
                            className={classNames("input__main", "input__date", {
                              filled: startDate,
                            })}
                            minDate={new Date()}
                            placeholderText="дд/мм/гг"
                          />

                          <img
                            className="create-promotion__date-icon"
                            src={promotionDate}
                            alt="Reset Icon"
                          />
                        </div>
                        <div className="input input--promotion">
                          <label
                            className="input__label"
                            style={{ position: "relative" }}
                          >
                            Дата окончания
                            <img
                              src={resetProfile}
                              alt=""
                              className="input__reset-icon"
                              onClick={() => setEndDate(autocompletedFields.endDate)}
                            />
                          </label>
                          <DatePicker
                            selected={endDate ? new Date(endDate) : null}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="dd-MM-yyyy"
                            className={classNames("input__main", "input__date", {
                              filled: endDate,
                            })}
                            minDate={new Date()}
                            placeholderText="дд/мм/гг"
                          />
                          <img
                            className="create-promotion__date-icon"
                            src={promotionDate}
                            alt="Reset Icon"
                          />
                        </div>
                      </div>
                      {visibleMobileMain && (
                        <div className="input">
                          <label className="input__label">Контактная информация</label>
                          <input
                            value={contactInfo?.mobile}
                            style={{ marginBottom: 10 }}
                            onChange={(e) =>
                              setContactInfo((prev) => ({
                                email: prev.email,
                                mobile: e.target.value,
                              }))
                            }
                            className={classNames("input__main")}
                            placeholder="Телефон"
                            type="mobile"
                          />
                          <input
                            value={contactInfo?.email}
                            onChange={(e) =>
                              setContactInfo((prev) => ({
                                mobile: prev.mobile,
                                email: e.target.value,
                              }))
                            }
                            className={classNames("input__main")}
                            placeholder="Email"
                            type="email"
                          />
                        </div>
                      )}
                      {!visibleMobileMain && (
                        <div
                          onClick={() => setVisibleMobileMain(true)}
                          className="popup-user__add popup-user__add--web"
                        >
                          Добавить контактную информацию
                        </div>
                      )}
                    </div>
                    <div className="web-item__dropfield">
                      {headerBg && <p className="web-item__img-title">Фон</p>}
                      <div className="dropfield">
                        <div className="dropfield__main">
                          {headerBg && (
                            <>
                              <div className="web-item__img-delete background-organizer">
                                <img
                                  onClick={() => setHeaderBg("")}
                                  src={CreateWebDelete}
                                  alt="delete"
                                />
                              </div>
                              <img
                                className="dropfield__image big"
                                src={headerBg}
                                alt="Background"
                              />
                            </>
                          )}
                          <input
                            onChange={onHeaderBgChange}
                            className="dropfield__input"
                            type="file"
                            id="file"
                            accept={allowedImageFileType}
                          />
                          {!headerBg && (
                            <div className="dropfield__content">
                              <div className="dropfield__btn">
                                <div className="dropfield__plus">
                                  <img src={avatarPlus} alt="Add icon" />
                                </div>
                                <div className="dropfield__text">
                                  <span>
                                    Загрузить медиа для фона
                                    <br />
                                  </span>
                                  Или перетащите файл сюда
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="dropfield__notation">
                          <div>Используйте только .jpg, .png типы файлов</div>
                          <div>Максимальный размер: {MaxImageSizeMb} Мб</div>
                          <div>{TextAboutAllowedResolution}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="create-promotion__item create-promotion__item--colors">
                <PromotionWebHeader
                  title={"Основная часть"}
                  setVisible={() => setVisibleMain(!visibleMain)}
                />
                {visibleMain && (
                  <div className="web-item web-item--main">
                    <div className="web-item__about">
                      <div className="web-item__about-form">
                        <div className="web-item__about-input">
                          <div className="input">
                            <label className="input__label">Описание мероприятия</label>
                            <div className="input__wrapper">
                              <textarea
                                value={about || ""}
                                onChange={(e) => setAbout(e.target.value)}
                                className={classNames("input__main")}
                                placeholder="Введите описание мероприятия."
                                type="text"
                              />
                              <img
                                src={resetProfile}
                                alt=""
                                className="input__reset-icon input__reset-icon--on-top"
                                onClick={() => setAbout(autocompletedFields.about)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="web-item__dropfield">
                          {logo && <p className="web-item__img-title">Логотип</p>}
                          <div className="dropfield">
                            <div className="dropfield__main">
                              {logo && (
                                <img
                                  className="dropfield__image big"
                                  src={logo}
                                  alt="#"
                                />
                              )}
                              <img
                                src={resetProfile}
                                alt=""
                                className="input__reset-icon input__reset-icon--on-bottom"
                                onClick={() => setLogo(autocompletedFields.logo)}
                                style={{ zIndex: 100 }}
                              />
                              <input
                                onChange={onLogoChange}
                                className="dropfield__input"
                                type="file"
                                id="file"
                                accept={allowedImageFileType}
                              />
                              {!logo && (
                                <div className="dropfield__content">
                                  <div className="dropfield__btn">
                                    <div className="dropfield__plus">
                                      <img src={avatarPlus} alt="Add icon" />
                                    </div>
                                    <div className="dropfield__text">
                                      <span>
                                        Загрузить лого
                                        <br />
                                      </span>
                                      Или перетащите файл сюда
                                    </div>
                                  </div>
                                </div>
                              )}
                              {logo && (
                                <div className="web-item__img-delete">
                                  <img
                                    onClick={() => setLogo("")}
                                    src={CreateWebDelete}
                                    alt="delete"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="dropfield__notation">
                              <div>Используйте только .jpg, .png типы файлов</div>
                              <div>Максимальный размер: {MaxImageSizeMb} Мб</div>
                              <div>{TextAboutAllowedResolution}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!isEventGallery && (
                      <div
                        className={classNames("web-item__product", {
                          clear: !visibleParticipants,
                        })}
                      >
                        <h5 className="web-item__product-title">
                          Участники мероприятия{" "}
                          {visibleParticipants ? (
                            <img
                              onClick={() => setVisibleParticipants(false)}
                              className="web-item__product-delete"
                              src={CreateWebDelete}
                              alt="delete"
                            />
                          ) : (
                            <img
                              onClick={() => setVisibleParticipants(true)}
                              className="web-item__product-delete"
                              src={addSection}
                              alt="add"
                            />
                          )}
                        </h5>
                        {visibleParticipants &&
                          participants.map((participant, i) => (
                            <div key={i + 1} className="web-item__product-item">
                              <UserImage
                                image={participant.image}
                                onClear={onClearImgParticipants}
                                onChange={onParticipantImageChange}
                                index={i}
                                title="Фотография участника"
                              />
                              <div className="web-item__product-inputs">
                                <div className="input">
                                  <label className="input__label">Участник {i + 1}</label>
                                  <div className="web-item__product-input">
                                    <input
                                      value={participant.name || ""}
                                      onChange={(e) =>
                                        onParticipantNameChange({
                                          index: i,
                                          newValue: e.target.value,
                                        })
                                      }
                                      className={classNames("input__main")}
                                      placeholder="Название организации"
                                      type="text"
                                    />
                                    {participants.length === i + 1 && i !== 0 && (
                                      <img
                                        onClick={() =>
                                          setParticipants((prev) =>
                                            [...prev].slice(0, prev.length - 1)
                                          )
                                        }
                                        className="web-item__product-delete"
                                        src={CreateWebDelete}
                                        alt="delete"
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="input">
                                  <label className="input__label">Описание</label>
                                  <div className="web-item__product-input">
                                    <input
                                      value={participant.description || ""}
                                      onChange={(e) =>
                                        onParticipantDescriptionChange({
                                          index: i,
                                          newValue: e.target.value,
                                        })
                                      }
                                      className={classNames("input__main")}
                                      placeholder="Отрасль или описание компании"
                                      type="text"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        {visibleParticipants && participants.length < 8 && (
                          <div
                            onClick={() =>
                              setParticipants((prev) => [
                                ...prev,
                                { name: "", description: "", image: "" },
                              ])
                            }
                            className="popup-user__add popup-user__add--web popup-user__add--product"
                          >
                            Добавить участника
                          </div>
                        )}
                      </div>
                    )}
                    <div
                      className={classNames("web-item__product", {
                        clear: !visibleProgram,
                      })}
                    >
                      <h5
                        className="web-item__product-title"
                        style={{ position: "relative" }}
                      >
                        Программа мероприятия
                        {visibleProgram ? (
                          <>
                            <img
                              onClick={() => setVisibleProgram(false)}
                              className="web-item__product-delete"
                              src={CreateWebDelete}
                              alt="delete"
                            />
                            <img
                              src={resetProfile}
                              alt=""
                              className="input__reset-icon"
                              style={{
                                position: "static",
                                transform: "none",
                                marginLeft: 15,
                              }}
                              onClick={() => setProgram(autocompletedFields.program)}
                            />
                          </>
                        ) : (
                          <img
                            onClick={() => setVisibleProgram(true)}
                            className="web-item__product-delete"
                            src={addSection}
                            alt="add"
                          />
                        )}
                      </h5>
                      {visibleProgram && (
                        <ProgramEvents
                          programEvents={program}
                          setProgramEvents={setProgram}
                          isDisabled={false}
                          isShowErrors={false}
                          minDate={
                            currentEvent?.start_date && new Date(currentEvent?.start_date)
                          }
                          maxDate={
                            currentEvent?.end_date && new Date(currentEvent?.end_date)
                          }
                        />
                      )}
                    </div>
                    <div
                      className={classNames("web-item__product", {
                        clear: !visibleSpeakers,
                      })}
                    >
                      <h5 className="web-item__product-title">
                        Спикеры мероприятия{" "}
                        {visibleSpeakers ? (
                          <img
                            onClick={() => setVisibleSpeakers(false)}
                            className="web-item__product-delete"
                            src={CreateWebDelete}
                            alt="delete"
                          />
                        ) : (
                          <img
                            onClick={() => setVisibleSpeakers(true)}
                            className="web-item__product-delete"
                            src={addSection}
                            alt="add"
                          />
                        )}
                      </h5>
                      {visibleSpeakers &&
                        speakers.map((speaker, i) => (
                          <div key={i + 1} className="web-item__product-item">
                            <UserImage
                              image={speaker.image}
                              onClear={onSpeakerImageClear}
                              onChange={onSpeakerImageChange}
                              index={i}
                              title="Фотография спикера"
                            />
                            <div className="web-item__product-inputs">
                              <div className="input">
                                <label className="input__label">Спикер {i + 1}</label>
                                <div className="web-item__product-input">
                                  <input
                                    value={speaker.fullName || ""}
                                    onChange={(e) =>
                                      onSpeakerNameChange({
                                        index: i,
                                        newValue: e.target.value,
                                      })
                                    }
                                    className={classNames("input__main")}
                                    placeholder="ФИО спикера"
                                    type="text"
                                    maxLength={191}
                                  />
                                </div>
                              </div>
                              <div className="input">
                                <label className="input__label">Описание</label>
                                <div className="web-item__product-input">
                                  <input
                                    value={speaker.pos || ""}
                                    onChange={(e) =>
                                      onSpeakerPosChange({
                                        index: i,
                                        newValue: e.target.value,
                                      })
                                    }
                                    className={classNames("input__main")}
                                    placeholder="Описание"
                                    type="text"
                                    maxLength={191}
                                  />
                                  {speakers.length === i + 1 && i !== 0 && (
                                    <img
                                      onClick={() =>
                                        setSpeakers((prev) =>
                                          [...prev].slice(0, prev.length - 1)
                                        )
                                      }
                                      className="web-item__product-delete"
                                      src={CreateWebDelete}
                                      alt="delete"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      {visibleSpeakers && participants.length < 4 && (
                        <div
                          onClick={() =>
                            setSpeakers((prev) => [
                              ...prev,
                              { fullName: "", image: "", pos: "" },
                            ])
                          }
                          className="popup-user__add popup-user__add--web popup-user__add--product"
                        >
                          Добавить спикера
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="create-promotion__buttons">
                <button onClick={onSubmit} className="red-btn" disabled={isPageDisabled}>
                  Создать веб сайт
                </button>
                <button
                  onClick={(e) => onSubmit(e, true)}
                  className="white-btn"
                  disabled={isPageDisabled}
                >
                  Сохранить черновик
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CreateWebOrg;
