import { getToken, instance } from ".";

export const getNotifications = ({ userId }) => {
  const token = getToken();

  return instance.get(`/api/v1/onnotif/events?user_id=${userId}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const markNotificationSeen = ({ id }) => {
  const token = getToken();

  return instance.post(
    `/api/v1/onnotif/events/${id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
};

export const createNotification = ({ templateId, userId, data }) => {
  const token = getToken();

  return instance.post(
    `/api/v1/onnotif/events`,
    {
      template_id: templateId,
      user_id: userId,
      data,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
};
