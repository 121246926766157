import React from "react";
import { Element } from "react-scroll";

const DemoEventParticipants = ({ participants, colorBackground, colorTitle }) => {
  return (
    participants &&
    participants.length > 0 && (
      <Element
        name={"Участники"}
        className="demo-event__participants"
        style={{ backgroundColor: colorBackground }}
      >
        <div className="demo__container">
          <h3 style={{ color: colorTitle }} className="demo-event__participants-title">
            Участники
          </h3>
          <div className="demo-event__participants-main">
            {participants.map((participant, index) => (
              <div key={index} className="demo-event__participants-item">
                <img src={participant.image} alt="#" />
              </div>
            ))}
          </div>
        </div>
      </Element>
    )
  );
};

export default DemoEventParticipants;
