import React, { useRef, useState } from "react";
import { Element } from "react-scroll";
import {
  landingBlockConstructor,
  landingBlockJE,
  landingBlockTasks,
  landingPlayButton,
} from "../../assets";
import RegistrationLink from "./RegistrationLink";

const AboutUs = () => {
  const [isPlayButtonVisible, setIsPlayButtonVisible] = useState(true);

  const videoRef = useRef(null);

  const onPlayButtonClick = () => {
    if (!videoRef.current) {
      return;
    }

    videoRef.current.currentTime = 0;
    videoRef.current.play();

    setIsPlayButtonVisible(false);
  };

  return (
    <Element name="landing-about-us" className="landing__about-us landing-about-us">
      <div className="landing__block-title">о нас</div>
      <div className="landing-about-us__main">
        <div className="landing-about-us__blocks">
          <div className="landing-about-us__block">
            <div className="landing-about-us__block-text">
              <h2>
                <span></span> инновационный конструктор JUST EVENTS
              </h2>
              <p className="landing-about-us__block-paragraph">
                <span>Повысить эффективность </span> своего мероприятия и
                <span> снизить риски </span> отмены офлайн выставки, конференции, шоу
                легко благодаря выходу в онлайн с инновационным конструктором JUST EVENTS.
              </p>
              <p className="landing-about-us__block-paragraph">
                Платформа онлайн мероприятий открывает перед Вами
                <span> безграничные возможности </span>
                для реализации самых смелых решений.
              </p>
            </div>
            <div className="landing-about-us__block-img landing-about-us__block-img--right">
              <img src={landingBlockConstructor} alt="" />
            </div>
          </div>
          <div className="landing-about-us__block">
            <div className="landing-about-us__block-img landing-about-us__block-img--left">
              <img src={landingBlockJE} alt="" />
            </div>
            <div className="landing-about-us__block-text">
              <h2>
                <span></span> JUST EVENTS - это
              </h2>
              <p className="landing-about-us__block-paragraph">
                ... –
                <span> это комплексное решение для гибридного или онлайн события. </span>{" "}
                Простой интерфейс, ассортимент готовых опций, широкие возможности
                брендирования 3D стендов позволят погрузить экспонентов в реалистичную и
                интерактивную атмосферу выставки
              </p>
              <p className="landing-about-us__block-paragraph">
                ... – <span> это простое решение для проведения мероприятий </span> любой
                сложности от моно-презентации и галерейной выставки до полномасштабных
                международных трейд шоу.
              </p>
            </div>
          </div>
          <div className="landing-about-us__block">
            <div className="landing-about-us__block-text">
              <h2>
                <span></span> Решение задач
              </h2>
              <div className="landing-about-us__block-paragraph">
                <span>
                  Дополняя свое мероприятие онлайн версией, Вы решаете сразу несколько
                  задач:
                </span>
                <ul>
                  <li>Расширяете географию</li>
                  <li> Получаете неограниченную площадь для выставления стендов</li>
                  <li> Увеличиваете количество рекламных площадей </li>
                  <li>
                    Позволяете экспонентам работать с максимальной эффективностью 24/7
                  </li>
                  <li> Повышаете посещаемость</li>
                  <li> Автоматизируете бизнес-процессы</li>
                  <li> Получаете максимально точную и полную статистику мероприятия</li>
                  <li>
                    Открываете возможность посещать Ваше мероприятие, не выходя из дома
                  </li>
                </ul>
              </div>
            </div>
            <div className="landing-about-us__block-img landing-about-us__block-img--right">
              <img src={landingBlockTasks} alt="" />
            </div>
          </div>
        </div>

        <RegistrationLink />
        <div className="landing-about-us__video">
          {isPlayButtonVisible && (
            <div onClick={onPlayButtonClick} className="landing-about-us__play">
              <button>
                <img src={landingPlayButton} alt="" />
              </button>
            </div>
          )}
          <video
            ref={videoRef}
            controls={!isPlayButtonVisible}
            autoPlay={false}
            loop={false}
            muted={false}
            preload="metadata"
          >
            <source src="/video/landing-video.mp4#t=4" type="video/mp4" />
          </video>
        </div>
      </div>
    </Element>
  );
};

export default AboutUs;
