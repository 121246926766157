import React from "react";

const ContactsCard = ({ name, email, mobile, company, avatar, role }) => {
  return (
    <div className="contacts-cards__item">
      <div className="contacts-cards__header">
        <div className="contacts-cards__avatar">
          <img src={avatar} alt="" />
        </div>
        <div className="contacts-cards__info">
          <h5 className="contacts-cards__name">{name}</h5>
        </div>
      </div>
      <div className="contacts-cards__main">
        <div className="contacts-cards__data">
          <div className="contacts-cards__point">Компания</div>
          <div className="contacts-cards__response">{company}</div>
        </div>
        <div className="contacts-cards__data ">
          <div className="contacts-cards__point">E-mail</div>
          <div className="contacts-cards__response contacts-cards__response--email">
            {email}
          </div>
        </div>
        <div className="contacts-cards__data">
          <div className="contacts-cards__point">Телефон</div>
          <div className="contacts-cards__response">{mobile}</div>
        </div>
      </div>
    </div>
  );
};

export default ContactsCard;
