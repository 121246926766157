import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getCropedFile } from "../../../utils/crop/getCropedFile";
import { ModelDropField } from "../3dmodel";

export const LogoDropfield = ({ setLogoParams, imageFromServer }) => {
  const [logoIsLoading, setLogoIsLoading] = useState("");

  const [cropInfo, setCropInfo] = useState({});
  const [loadedFileInfo, setLoadedFileInfo] = useState({});

  useEffect(() => {
    const watchLogoChanges = async () => {
      try {
        const cropFile = await getCropedFile(cropInfo);

        setLogoParams({
          file: loadedFileInfo.file || "",
          thumb: cropFile || "",
          link: loadedFileInfo.url || "",
          name: "",
          fileType: "logo",
        });
      } catch (error) {}
    };

    watchLogoChanges();
  }, [cropInfo, loadedFileInfo.file, loadedFileInfo.url, setLogoParams]);

  return (
    <div className="upload-material-popup__logo">
      <ModelDropField
        imageFromServer={imageFromServer}
        isLoading={logoIsLoading}
        setIsLoading={setLogoIsLoading}
        setCropInfo={setCropInfo}
        banner={{
          type: "image",
        }}
        setLoadedFileInfo={setLoadedFileInfo}
        allowedFilesType={["image/jpeg", "image/png"]}
        isWithoutCrop={false}
        minImageResolution={{ width: 225, height: 113 }}
        maxImageResolution={{ width: 1920, height: 1080 }}
        customInputTitle={"Загрузить логотип"}
        withFileAnotation
        withInsertFullCheckbox
        aspect={undefined}
      />
    </div>
  );
};
