import classNames from "classnames";
import { immediateToast } from "izitoast-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMemberFullInfo, updateOrganizationInfo } from "../../api/member";
import { infoSvg } from "../../assets";
import { NavProfile } from "../../components/profile";
import { Button, Header, Sidebar } from "../../components/shared";
import { errorHandler } from "../../utils/errorHandler";

const OrganizationMember = () => {
  const profile = useSelector((state) => state.user.profile);
  const [organizationName, setOrganizationName] = useState("");
  const [inn, setInn] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [juridicalAddress, setJuridicalAddress] = useState("");
  const [actionBasis, setActionBasis] = useState("");
  const [registrationCode, setRegistrationCode] = useState("");

  const isAllow = () => {
    if (
      organizationName.length >= 5 &&
      inn.length >= 10 &&
      registrationNumber.length === 13 &&
      (registrationCode === '' || (registrationCode.length === 9))
    ) {
      return true;
    }
    return false;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!isAllow()) {
        return;
      }

      await updateOrganizationInfo({
        organization_name: organizationName,
        inn,
        registration_number: registrationNumber,
        kpp: registrationCode || '',
        justification: actionBasis || '',
        address: juridicalAddress || '',
      });

      immediateToast("success", {
        message: "Данные успешно сохранены.",
        position: "topCenter",
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    const fetchMemberFullInfo = async () => {
      try {
        const { data } = await getMemberFullInfo();
        const memberInfo = data?.data?.info;

        if (!memberInfo) return;

        setInn(memberInfo.inn || "");
        setRegistrationNumber(memberInfo.registration_number || "");
        setOrganizationName(
          memberInfo.organization_name || memberInfo.organization || ""
        );
        setJuridicalAddress(memberInfo.address || "");
        setActionBasis(memberInfo.justification || "");
        setRegistrationCode(memberInfo.kpp || "");
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchMemberFullInfo();
  }, []);

  return (
    <div className="page-wrapper-documents">
      <div className="app">
        <Sidebar />
        <Header userName={profile.fullName} />
        <div className="page__main">
          <NavProfile />
          <div className="personal">
            <div className="main">
              <h3 className="main__title">Профиль ({profile.role})</h3>
              <h5 className="main__subtitle">Информация об организации</h5>
              <form method="post" className="main__form">
                <div className="input">
                  <label className="input__label" htmlFor="fullName">
                    Полное наименование организации*
                  </label>
                  <input
                    className={classNames(
                      "input__main",
                      {
                        error: organizationName.length > 0 && organizationName.length < 5,
                      },
                      { filled: organizationName.length >= 5 }
                    )}
                    placeholder="Например ООО Рога и Копыта или ИП Сидоров Петр"
                    type="text"
                    id="fullName"
                    value={organizationName}
                    onChange={(e) => setOrganizationName(e.target.value)}
                    maxLength={190}
                  />
                  {organizationName.length > 0 && organizationName.length < 5 ? (
                    <p className="input__text">
                      Полное наименование организации не может содержать меньше 5 символов
                    </p>
                  ) : null}
                </div>
                <div className="input input--create-stand">
                  <div className="create-stand-content__input-alert">
                    <label>ИНН Организации*</label>
                    <div className="create-stand-content__input-icon">
                      <img src={infoSvg} alt="#" />
                      <div className="create-stand-content__input-promt inn">
                        Идентификационный номер налогоплательщика для уплаты налогов.
                        Обязательный реквизит любой организации, состоящий из 10 арабских
                        цифр в строгой последовательности
                      </div>
                    </div>
                  </div>
                  <input
                    className={classNames(
                      "input__main",
                      {
                        error: inn.length > 0 && inn.length < 10,
                      },
                      { filled: inn.length >= 10 }
                    )}
                    placeholder="ИНН Организации"
                    type="text"
                    value={inn}
                    onChange={(e) => setInn(e.target.value.replace(/[^\d]/g, ""))}
                    minLength="10"
                    maxLength="12"
                  />
                  {inn.length > 0 && inn.length < 10 ? (
                    <p className="input__text">
                      ИНН организации не может содержать меньше 10 символов
                    </p>
                  ) : null}
                </div>
                <div className="input input--create-stand">
                  <div className="create-stand-content__input-alert">
                    <label>Основной регистрационный номер*</label>
                    <div className="create-stand-content__input-icon">
                      <img src={infoSvg} alt="#" />
                      <div className="create-stand-content__input-promt registrationNumber">
                        ОГРН (основной государственный регистрационный номер) —
                        государственный регистрационный номер записи о создании
                        юридического лица (13 цифр)
                      </div>
                    </div>
                  </div>
                  <input
                    className={classNames(
                      "input__main",
                      {
                        error:
                          registrationNumber.length > 0 &&
                          registrationNumber.length !== 13,
                      },
                      { filled: registrationNumber.length === 13 }
                    )}
                    placeholder="Основной регистрационный номер"
                    type="text"
                    value={registrationNumber}
                    onChange={(e) =>
                      setRegistrationNumber(e.target.value.replace(/[^\d]/g, ""))
                    }
                    minLength="13"
                    maxLength="13"
                  />
                  {registrationNumber.length > 0 && registrationNumber.length !== 13 ? (
                    <p className="input__text">
                      Основной регистрационный номер не может содержать меньше 13 символов
                    </p>
                  ) : null}
                </div>
                <div className="input input--create-stand">
                  <div className="create-stand-content__input-alert">
                    <label>КПП</label>
                  </div>
                  <input
                    className={classNames(
                      "input__main",
                      { error: registrationCode.length > 0 && registrationCode.length !== 9 },
                      { filled: registrationCode === '' || registrationCode.length === 9 }
                    )}
                    placeholder="КПП"
                    type="text"
                    value={registrationCode}
                    onChange={(e) =>
                      setRegistrationCode(e.target.value.replace(/[^\d]/g, ""))
                    }
                    minLength="9"
                    maxLength="9"
                  />
                  {registrationCode.length > 0 && registrationCode.length !== 9 ? (
                    <p className="input__text">
                      КПП не может содержать меньше 9 символов
                    </p>
                  ) : null}
                </div>
                <div className="input input--create-stand">
                  <div className="create-stand-content__input-alert">
                    <label>Основание действия генерального директора</label>
                  </div>
                  <input
                    className={classNames(
                      "input__main",
                      { filled: actionBasis === '' || actionBasis.length > 0 }
                    )}
                    placeholder="Основание действия генерального директора"
                    type="text"
                    value={actionBasis}
                    onChange={(e) => setActionBasis(e.target.value)}
                    maxLength={255}
                  />
                </div>
                <div className="input input--create-stand">
                  <div className="create-stand-content__input-alert">
                    <label>Юридический адрес</label>
                  </div>
                  <input
                    className={classNames(
                      "input__main",
                      { filled: juridicalAddress === '' || juridicalAddress.length > 0 }
                    )}
                    placeholder="Юридический адрес"
                    type="text"
                    value={juridicalAddress}
                    onChange={(e) => setJuridicalAddress(e.target.value)}
                    maxLength={255}
                  />
                </div>
                <Button onClick={onSubmit} disabled={!isAllow()} className="button__save">
                  Сохранить
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationMember;
