import React from "react";
import { Element } from "react-scroll";
import { clearString } from "../../../../utils";

const DemoAdvantages = ({ advantages, colorTitle, colorText }) => {
  return (
    advantages?.length > 0 && (
      <Element name="Наши преимущества" className="demo-section">
        <div className="demo__container">
          <h3 className="demo-section__title" style={{ color: colorTitle }}>
            Наши преимущества
          </h3>
          <div className="demo-advantages__main">
            {advantages.map(
              (item, i) =>
                item && (
                  <div key={i} className="demo-advantages__item">
                    <i>
                      <svg
                        width="7"
                        height="7"
                        viewBox="0 0 7 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="3.5" cy="3.5" r="3.5" fill={colorTitle} />
                      </svg>
                    </i>
                    <div
                      className="demo-section__text demo-section__text--advantages"
                      style={{ color: colorText }}
                    >
                      {clearString(item)}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </Element>
    )
  );
};

export default DemoAdvantages;
