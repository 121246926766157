import { immediateToast } from "izitoast-react";
import moment from "moment";
import "moment/locale/ru";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useDispatch, useSelector } from "react-redux";
import { getEventsList } from "../../api";
import { AdBanners, CustomCalendarToolBar, EventsNav } from "../../components/events";
import { FavoritePopup } from "../../components/favorite";
import {
  EventConfirmPopup,
  EventNotificationPopup,
  Header,
  Loader,
  Sidebar,
  SidePopupChat,
} from "../../components/shared";
import { useFavoritePopup } from "../../hooks/useFavoritePopup";
import { setResetStandInfo } from "../../redux/appReducer";
import { errorHandler } from "../../utils/errorHandler";
import { prepareEventList } from "../../utils/prepareEventList";

const CalendarEvents = () => {
  moment.updateLocale("ru", {
    week: {
      dow: 1,
    },
  });

  const role = useSelector((state) => state.user.profile.role);
  const dispatch = useDispatch();

  const [allEvents, setAllEvents] = useState([]);

  const [inputSearch, setInputSearch] = useState("");
  const [filterEvents, setFilterEvents] = useState(allEvents);

  // ссылка для попапа
  const [selectedEventLink, setSelectedEventLink] = useState(null);
  const [popupOpened, setPopupOpened] = useState(false);
  const [popupText, setPopupText] = useState("");

  // текст для попапа уведомления
  const [eventNotificationPopup, setEventNotificationPopup] = useState(false);
  const [customNotificationTitle, setCustomNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");

  const { closePopup, popupInfo, setPopupInfo } = useFavoritePopup();

  const onSearch = (e) => {
    let newValue = e.target.value;

    setInputSearch(newValue);

    newValue = newValue?.trim().toLowerCase();

    newValue
      ? setFilterEvents(
          [...allEvents].filter(
            (ev) =>
              ev.title?.toLowerCase().includes(newValue) ||
              ev.text?.toLowerCase().includes(newValue)
          )
        )
      : setFilterEvents(allEvents);
  };

  const localizer = momentLocalizer(moment);

  const customSlotPropGetter = (currentEvent) => {
    const isToday = new Date(currentEvent.start).getDate() === new Date().getDate();
    return {
      className: currentEvent.color,
      style: {
        borderColor: isToday ? "transparent" : "initial",
      },
    };
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const getEvents = async () => {
      try {
        setIsLoading(true);
        const { data } = await getEventsList({ dateType: "actual" });

        if (!isMounted) {
          return;
        }

        const allEvents = data?.data?.data;

        if (!allEvents) return;

        setAllEvents(prepareEventList(allEvents, { withoutDebug: true }));
      } catch (error) {
        errorHandler(error);
      }
      setIsLoading(false);
    };

    getEvents();

    return () => {
      isMounted = false;
    };
  }, [role]);

  useEffect(() => {
    setFilterEvents(allEvents);
  }, [allEvents]);

  return (
    <div className="app">
      {popupOpened && (
        <EventConfirmPopup
          eventLink={selectedEventLink}
          closePopup={() => setPopupOpened(false)}
          eventText={popupText}
        />
      )}
      {eventNotificationPopup && (
        <EventNotificationPopup
          popupText={notificationText}
          closePopup={() => setEventNotificationPopup(false)}
          customTitle={customNotificationTitle}
        />
      )}
      {popupInfo.isOpened && (
        <FavoritePopup popupInfo={popupInfo} closePopup={closePopup} />
      )}
      <SidePopupChat />
      <Sidebar />
      <Header />
      <div className="page__main page__main--events">
        <div className="event-calendar">
          <main className="event-calendar__main">
            <div className="event-calendar__header">
              <h3 className="event-calendar__title">Event Календарь</h3>
              <div className="event-calendar__search">
                <form
                  className="event-calendar__form"
                  onSubmit={(e) => e.preventDefault()}
                  action="#"
                >
                  <input
                    value={inputSearch}
                    onChange={onSearch}
                    className="event-calendar__input"
                    type="text"
                    placeholder="Поиск мероприятия"
                  />
                  <div className="event-calendar__icon"></div>
                </form>
              </div>
              <EventsNav />
            </div>
            <div className="calendar table-responsive">
              {isLoading ? (
                <div className="event-calendar__loader">
                  <Loader />
                </div>
              ) : (
                <Calendar
                  popup
                  events={filterEvents}
                  localizer={localizer}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 550 }}
                  toolbar={true}
                  onSelectEvent={(event) => {
                    localStorage.setItem("goToSettings", "/events/calendar");
                    dispatch(setResetStandInfo());

                    setCustomNotificationTitle("");

                    if (role === "организатор" && event.isEventCreated) {
                      return setPopupInfo({
                        isOpened: true,
                        selectedEvent: { ...event, type: "actual" },
                      });
                    }

                    if (role === "участник" && event.type === "gallery") {
                      setNotificationText(
                        "На индивидуальных презентациях недоступна аренда стендов. Для посещения измените роль на Посетитель."
                      );
                      setCustomNotificationTitle("Создание стенда");
                      return setEventNotificationPopup(true);
                    }

                    // стенд не создан
                    if (
                      role === "участник" &&
                      !event.isStandCreated &&
                      event.availableStandsCount > 0
                    ) {
                      if (event.visitTypeId === 20) {
                        immediateToast("info", {
                          message: "Регистрация участников только по приглашениям.",
                          position: "topCenter",
                        });
                      } else {
                        setPopupText("Желаете перейти к созданию заявки?");
                        setSelectedEventLink(`/events/create/${event.id}?currentStep=1`);
                        return setPopupOpened(true);
                      }
                    }

                    if (
                      role === "участник" &&
                      !event.isStandCreated &&
                      event.availableStandsCount === 0
                    ) {
                      setNotificationText("Все стенды уже заняты");
                      return setEventNotificationPopup(true);
                    }

                    if (
                      role === "участник" &&
                      event.isStandCreated &&
                      event.standStatusType
                    ) {
                      return setPopupInfo({
                        isOpened: true,
                        selectedEvent: { ...event, type: event.standStatusType },
                      });
                    }

                    // зарегистрирован
                    if (
                      role === "посетитель" &&
                      event.isUserRegistered &&
                      new Date() < event.startDate
                    ) {
                      setNotificationText(
                        "Вы уже зарегистрированы, мероприятие ещё не началось"
                      );
                      return setEventNotificationPopup(true);
                    }

                    if (
                      role === "посетитель" &&
                      event.isUserRegistered &&
                      new Date() >= event.startDate
                    ) {
                      return setPopupInfo({
                        isOpened: true,
                        selectedEvent: event,
                      });
                    }

                    // не зарегистрирован
                    if (role === "посетитель" && !event.isUserRegistered) {
                      setSelectedEventLink(`/events/registration?id=${event.id}`);
                      setPopupText(`Желаете перейти к выставке ${event.title}?`);
                      return setPopupOpened(true);
                    }
                  }}
                  eventPropGetter={customSlotPropGetter}
                  components={{ toolbar: CustomCalendarToolBar }}
                />
              )}
            </div>
          </main>
          <AdBanners />
        </div>
      </div>
    </div>
  );
};

export default CalendarEvents;
