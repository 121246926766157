import queryString from "query-string";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ParticipantsCard,
  ParticipantsTable,
} from "../../components/stand/participants";

const ParticipantsMembersList = () => {
  const history = useHistory();
  const parsedQuery = queryString.parse(history.location.search);

  const viewType = parsedQuery.view;

  const members = useSelector((state) => state.events.members);
  let cards = [];
  if (members && members.length > 0) {
    cards = [...members.map((card) => (
      <ParticipantsCard
        id={card.id}
        key={card.id}
        fullName={card.person}
        email={card.email}
        mobile={card.phone}
        img={card.logo}
        standNumber={card.position}
        company={card.name}
      />
    ))];
  }

  return (
    <div className={`participants-main participants-main--${viewType}`}>
      {viewType === "table" && <ParticipantsTable members={members} />}
      {viewType === "cards" && cards}
    </div>
  );
};

export default ParticipantsMembersList;
