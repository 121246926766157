import cn from "classnames";
import { immediateToast } from "izitoast-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setEventMedia } from "../../api/organizer";
import { confirmStandMedia } from "../../api/stand";
import { ChangeColor } from "../../assets";
import { imageIcon, pdfIcon, videoIcon } from "../../assets/banner";
import { Hint } from "../../components/organizer-panel";
import { pageNames } from "../../components/organizer-panel/CreateEvent/EventSidebar";
import { Button, ConfirmPopup, Layout } from "../../components/shared";
import { NavStand } from "../../components/stand";
import {
  ColorPopup,
  LogoDropfield,
  ModelAudioPanel,
  ModelMask,
  Popup,
} from "../../components/stand/3dmodel";
import { useCreateEventPageDisabled } from "../../hooks/create-event/usePageDisabled";
import { useConfirmPopup } from "../../hooks/useConfirmPopup";
import {
  confirmStandMediaId,
  fetchEvent,
  fetchModelMasks,
  fetchStand,
  fetchStandMedia,
  setFillingPageName,
} from "../../redux/eventReducer";
import { getImageSize } from "../../utils/getImageSize";

export const bannerIcons = [
  {
    type: "video",
    icon: videoIcon,
  },
  {
    type: "image",
    icon: imageIcon,
  },
  {
    type: "presentation",
    icon: pdfIcon,
  },
];

const offsets = {
  // top left [x, y]
  19: [600, 700],
  20: [500, 700],
  21: [600, 700],
  22: [450, 700],
  23: [500, 1100],
  24: [500, 1100],
  25: [250, 1100],
  26: [600, 800],
  27: [600, 1000],
  28: [600, 1000],
  29: [1200, 1400],
};

const getColorButtonStyles = ({ bgSize, maskSizeDivider, standTemplateId }) => {
  let bgWidthFactor = 1;

  if (standTemplateId === 25) {
    bgWidthFactor = 1.35;
  }

  return {
    top: bgSize.height / (2 * maskSizeDivider),
    left: (bgSize.width * bgWidthFactor) / (2 * maskSizeDivider),
  };
};

const Model = () => {
  const { id, type } = useParams();
  const isEvent = type === "event";
  const isStand = type === "stand";

  const currentEvent = useSelector((state) => state.events.currentEvent);
  const [eventStatusType] = currentEvent?.status_type || [];
  const isGallery = currentEvent?.room_type === "gallery";

  const currentStand = useSelector((state) => state.events.currentStand);
  const [standStatusType] = currentStand?.status_type || [];
  const standTemplateId = currentStand?.tmpl?.id;

  // делитель, для пропорционального уменьшения баннеров, масок и т.д.
  const [maskSizeDivider, setMaskSizeDivider] = useState(1);

  const dispatch = useDispatch();

  const media = useSelector((state) => state.events.media);

  const [banner, setBanner] = useState(null);
  const [bannerPopupOpened, setBannerPopupOpened] = useState(false);

  const masks = useSelector((state) => state.events.modelMasks);
  const bgMask = masks?.find((m) => m.name === "bg");
  const [bgSize, setBgSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getBgSize = async () => {
      try {
        if (!bgMask.img) return;

        const { width, height } = await getImageSize("", bgMask.img);

        setBgSize({
          width,
          height,
        });
      } catch (error) {}
    };

    getBgSize();
  }, [bgMask]);

  const [colorPopupOpened, setColorPopupOpened] = useState(false);

  const onColorPopupClose = () => {
    setColorPopupOpened(false);
  };

  useEffect(() => {
    dispatch(fetchStandMedia({ [`${type}Id`]: id }));
    dispatch(fetchModelMasks({ [`${type}Id`]: id }));
  }, [dispatch, id, type]);

  const onBannerPopupClose = () => {
    setBannerPopupOpened(false);
  };

  const templateOffsets = isStand ? offsets[standTemplateId] : [];
  const leftOffset = templateOffsets?.[0];
  const topOffset = templateOffsets?.[1];

  const planJson = useMemo(() => {
    if (media?.banners) {
      const banners = Array.from(media.banners);

      if (isStand && currentStand) {
        const logoStyles = currentStand?.tmpl?.additional?.logo_transform || {};

        banners.push({
          id: Math.random(),
          order: null,
          src: currentStand.logo,
          logoLink: currentStand.logo_link,
          mediatype: "image",
          rect: "logo-rect",
          styles: {
            top: parseInt(logoStyles.top) - topOffset,
            left: parseInt(logoStyles.left) - leftOffset,
            width: parseInt(logoStyles.width),
            height: parseInt(logoStyles.height),
          },
          type: "image",
          additionalType: "logo",
        });
      }

      return banners.map((b) => {
        const bannerIsLogo = b.rect === "logo-rect";
        const top =
          (parseInt(b.styles.top) - (isStand && !bannerIsLogo ? topOffset : 0)) /
          maskSizeDivider;
        const left =
          (parseInt(b.styles.left) - (isStand && !bannerIsLogo ? leftOffset : 0)) /
          maskSizeDivider;

        return {
          ...b,
          styles: {
            ...b.styles,
            width: parseInt(b.styles.width) / maskSizeDivider,
            height: parseInt(b.styles.height) / maskSizeDivider,
            top,
            left,
            right: b.styles.right && parseInt(b.styles.right) / maskSizeDivider,
          },
        };
      });
    }

    return [];
  }, [currentStand, isStand, leftOffset, maskSizeDivider, media?.banners, topOffset]);

  const { closePopup, popupInfo, setPopupInfo } = useConfirmPopup();

  const onStandCreateConfirmClick = async () => {
    try {
      if (type === "event" && eventStatusType === "actual") {
        await confirmStandMedia({ eventId: id });
      }

      if (type === "event" && eventStatusType !== "actual") {
        if (isGallery) {
          dispatch(
            setFillingPageName({
              eventId: id,
              name: pageNames.GALLERY,
            })
          );
        } else {
          dispatch(
            setFillingPageName({
              eventId: id,
              name: pageNames.TARIFF_STANDS,
            })
          );
        }
      }

      if (type === "event" && isGallery) {
      }

      if (type === "stand" && standStatusType === "actual") {
        await confirmStandMedia({ standId: id });
        dispatch(fetchStand({ standId: id }));
      }

      if (type === "stand" && standStatusType !== "actual") {
        dispatch(confirmStandMediaId(id));
      }

      if (type === "event") {
        dispatch(fetchEvent({ eventId: id }));
      }

      const messageText = isStand
        ? "Стенд успешно сохранен."
        : "Ресепшн успешно сохранен.";

      immediateToast("success", {
        message: messageText,
        position: "topCenter",
      });
      closePopup();
    } catch (error) {
      const errorText = isStand ? "Стенд не сохранился." : "Ресепшн не сохранился.";

      immediateToast("warning", {
        message: errorText,
        position: "topCenter",
      });
      closePopup();
    }
  };

  const onStandCreateClick = () => {
    const popupText = isStand
      ? "Вы уверены, что хотите создать стенд?"
      : "Вы уверены, что хотите опубликовать ресепшн?";

    const popupTitle = isStand ? "Стенд" : "Ресепшн";

    setPopupInfo({
      text: popupText,
      title: popupTitle,
      onCancelClick: closePopup,
      onConfirmClick: onStandCreateConfirmClick,
      isOpened: true,
      customConfirmText: isStand ? "Создать" : "Опубликовать",
    });
  };

  const modelRef = useRef(null);

  const imageHeight = parseInt(bgSize.height);
  const imageWidth = parseInt(bgSize.width);

  const canvasRefs = useRef({});
  useEffect(() => {
    const convertFetchedMasksToCanvases = () => {
      try {
        if (!imageHeight || !imageWidth) {
          return;
        }

        masks?.map((m) => {
          const img = new Image();

          let planHeight = modelRef.current.clientHeight + 300;
          let planWidth = modelRef.current.clientWidth - 400;

          // if (document.documentElement.clientWidth < 1250) {
          //   // для адаптивности не ограничиваем высоту 3д модели
          //   // и добавляем скролл на странице
          //   planHeight = 9999;
          //   planWidth = modelRef.current.clientWidth - 100;
          // }

          let newSizeDividor = 1.3;

          if (planHeight < imageHeight) {
            newSizeDividor = imageHeight / planHeight;
          }

          if (planWidth < imageWidth && imageWidth / planWidth > newSizeDividor) {
            newSizeDividor = imageWidth / planWidth;
          }

          img.src = m.img;
          img.crossOrigin = "Anonymous";

          setMaskSizeDivider(newSizeDividor);

          if (!m.color) {
            return m;
          }

          const canvas = canvasRefs.current[m.id];
          const ctx = canvas.getContext("2d");

          canvas.width = imageWidth / newSizeDividor;
          canvas.height = imageHeight / newSizeDividor;

          img.onload = function () {
            ctx.drawImage(
              img,
              0,
              0,
              this.width,
              this.height, // source rectangle
              0,
              0,
              canvas.width,
              canvas.height
            ); // destination rectangle

            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

            editPixels(imageData.data, m.color);

            drawEditedImage(imageData);
          };

          function editPixels(imgData, color) {
            const splitedColor = color.split(",");

            const c_rgb = {
              r: parseInt(splitedColor[0]),
              g: parseInt(splitedColor[1]),
              b: parseInt(splitedColor[2]),
            };

            for (var i = 0; i < imgData.length; i += 4) {
              imgData[i] = c_rgb.r;
              imgData[i + 1] = c_rgb.g;
              imgData[i + 2] = c_rgb.b;
            }
          }

          function drawEditedImage(newData) {
            ctx.putImageData(newData, 0, 0);
          }

          return m;
        });
      } catch (error) {}
    };

    convertFetchedMasksToCanvases();

    window.addEventListener("resize", convertFetchedMasksToCanvases);

    return () => {
      window.removeEventListener("resize", convertFetchedMasksToCanvases);
    };
  }, [imageHeight, imageWidth, isStand, masks]);

  const planRef = useRef(null);

  const isPageDisabled = useCreateEventPageDisabled(pageNames.RECEPTION);

  const doLogoUpload = useCallback(
    async (file) => {
      if (!isEvent || !currentEvent?.id) return;
      const params = {
        eventId: currentEvent?.id,
        mediaType: "image",
        file,
        rect: "logo",
      };
      const { data } = await setEventMedia(params);
      return data;
    },
    [currentEvent?.id, isEvent]
  );

  const onBannerIconClick = ({ banner, iconType }) => {
    setBanner({ ...banner, type: iconType });
    setBannerPopupOpened(true);
  };

  const textures = currentStand?.tmpl?.additional?.textures;

  return (
    <Layout>
      <div className="organizer-panel__main">
        {popupInfo.isOpened && (
          <ConfirmPopup popupInfo={popupInfo} onCloseClick={closePopup} />
        )}
        {colorPopupOpened && (
          <ColorPopup
            closePopup={onColorPopupClose}
            textures={textures}
            allMasks={masks}
          />
        )}
        {bannerPopupOpened && <Popup banner={banner} onClose={onBannerPopupClose} />}
        <NavStand />
        <div
          className={cn("model", {
            "model--event": isEvent,
            "model--stand": !isEvent,
          })}
          ref={modelRef}
        >
          {type === "event" && isPageDisabled && (
            <div style={{ position: "absolute", left: 150, top: 15 }}>
              <Hint hintText="Для редактирования заполните обязательные предыдущие разделы" />
            </div>
          )}
          <div className="model__create-btn">
            <Button onClick={onStandCreateClick} disabled={isPageDisabled}>
              {isStand && "Создать стенд"}
              {isEvent && "Опубликовать"}
            </Button>
          </div>
          <div
            className="model__plan-wrapper"
            style={{
              width: bgSize.width && parseInt(bgSize.width) / maskSizeDivider,
              height:
                (bgSize.height && parseInt(bgSize.height) / maskSizeDivider) -
                (isEvent ? bgSize.height / 11 : 0),
              top: isStand && topOffset ? -topOffset / 16 : "",
            }}
          >
            <div
              style={{
                width: bgSize.width && parseInt(bgSize.width) / maskSizeDivider,
                height: bgSize.height && parseInt(bgSize.height) / maskSizeDivider,
              }}
              className="model__plan"
              ref={planRef}
            >
              <div className="model__banners">
                {planJson?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        ...item.styles,
                        background: item.src ? "#000" : "#3f4551",
                      }}
                      className="model__banner"
                    >
                      <div
                        style={{ borderRadius: "inherit" }}
                        className="model__banner-image"
                      >
                        {item.src ? <img src={item.src} alt={item.type} /> : ""}
                      </div>
                      <div className="model__banner-icons">
                        {bannerIcons
                          .filter((i) =>
                            // only image for logo banner
                            item.additionalType === "logo" ? i.type === "image" : true
                          )
                          .map((i, ind) => (
                            <img
                              key={ind}
                              onClick={() =>
                                onBannerIconClick({ banner: item, iconType: i.type })
                              }
                              src={i.icon}
                              alt=""
                            />
                          ))}
                      </div>
                    </div>
                  );
                })}
              </div>
              {masks.map((m, i) => (
                <ModelMask
                  mask={m}
                  height={imageHeight / maskSizeDivider}
                  width={imageWidth / maskSizeDivider}
                  canvasRefs={canvasRefs}
                  key={m.id || i}
                />
              ))}
              {masks.filter((m) => m.name !== "bg").length !== 0 && (
                <button
                  onClick={() => setColorPopupOpened(true)}
                  style={getColorButtonStyles({
                    bgSize,
                    maskSizeDivider,
                    standTemplateId,
                  })}
                  className="model__change-color-btn"
                >
                  <img src={ChangeColor} alt="" />
                </button>
              )}
              {bgMask?.img && (
                <div className="model__wrap-image">
                  <img src={bgMask.img} alt="" />
                </div>
              )}
            </div>
          </div>
          <div
            className="model__side-panel"
            style={{
              marginTop: isEvent ? "" : 82,
              marginRight: isEvent ? "" : 20,
            }}
          >
            {isEvent && (
              <LogoDropfield
                doUpload={doLogoUpload}
                actualImage={currentEvent?.lobby?.logo}
              />
            )}
            <ModelAudioPanel
              loadedAudio={media?.audio_greeting}
              planHeight={planRef.current?.clientHeight}
              disabled={isPageDisabled}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Model;
