import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MailingSentSearch from "./MailingSentSearch";

const MailingSent = () => {
  const [query, setQuery] = useState("");
  const mailingSentItems = useSelector((state) => state.mailing.mailings);

  const [showedMailingSetItems, setShowedMailingSetItems] = useState(mailingSentItems);

  useEffect(() => {
    setShowedMailingSetItems(mailingSentItems);
  }, [setShowedMailingSetItems, mailingSentItems]);

  const handleQueryChange = (query) => {
    if (query === "") {
      setShowedMailingSetItems(mailingSentItems);
    } else {
      const filteredMailingSetItems = mailingSentItems.filter((s, i) => {
        return s.subject.toLowerCase().includes(query.toLowerCase());
      });

      setShowedMailingSetItems(filteredMailingSetItems);
    }
    setQuery(query);
  };

  return (
    <div className={"mailingsent_inner"}>
      <div>
        <div className={"mailing-subheader"}>
          <div className={"mailingwrapper"}>
            <div className={"mailingwrapperinner"}>
              <MailingSentSearch query={query} onQueryChange={handleQueryChange} />
              <table className="table table-bordered participants-table stand-tarifs__table mailingsent-table">
                <thead className="table__head">
                  <tr>
                    <th>Название</th>
                    <th>Дата рассылки</th>
                    <th>Отправлено</th>
                  </tr>
                </thead>
                <tbody className="table__body">
                  {query.length > 0 && showedMailingSetItems.length === 0 && (
                    <tr>
                      <td>Ничего не найдено</td>
                    </tr>
                  )}
                  {showedMailingSetItems.map((s, i) => (
                    <tr className={"mailingsenttr"}>
                      <td>
                        {s.subject} от {moment(s.created_at).format("YYYY-MM-DD")}
                      </td>
                      <td>{moment(s.created_at).format("YYYY-MM-DD")}</td>
                      <td>{s.recipients.length}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className={"col-12 col-md-9 col-xl-10"}></div>
      </div>
    </div>
  );
};
export default MailingSent;
