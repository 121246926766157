import React from "react";

export const MemberCard = ({
  image,
  groupName,
  isLobby,
  standNumber,
  fullName,
  email,
  numbers,
  onClick,
}) => {
  return (
    <li onClick={onClick} className="members-popup__item member-item">
      <div>
        <div className="member-item__header">
          <div className="member-item__image">
            <img src={image} alt="#" className="" />
          </div>
          <span className="member-item__name">{groupName}</span>
          <div className="member-item__stend">{!isLobby ? `Стенд #${standNumber}` : "Лобби"}</div>
        </div>
        <div className="member-item__main">
          <div className="member-item__sector">
            <h3>ФИО</h3>
            <span className="member-item__info">{fullName}</span>
          </div>
          <div className="member-item__sector">
            <h3>E-mail</h3>
            <span className="member-item__info">{email}</span>
          </div>
          <div className="member-item__sector">
            <h3>Телефон</h3>
            <span className="member-item__info">{numbers}</span>
          </div>
        </div>
      </div>
    </li>
  );
};
