import { authManager } from "./managers";
import { immediateToast } from "izitoast-react";

export const errorHandler = (e) => {
  console.log(e);
  console.log(e.response);
  const msg = e.response?.data?.message;
  const success = e.response?.data?.success;
  const validMsgs = e.response?.data?.data;
  if (msg === "Unauthenticated.") {
    const token = authManager().getToken();
    authManager().logout();
    authManager().deleteAuthToken();
    authManager().removeToken(token);
    // immediateToast("warning", {
    //   message: "Сессия истекла. Пожалуйста авторизируйтесь.",
    //   position: "topCenter",
    // });
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
  } else if (!success) {
    if (msg === "errors" && validMsgs && Object.keys(validMsgs).length > 0) {
      for (let k in validMsgs) {
        let m = validMsgs[k];
        if (typeof m === "string") {
          immediateToast("error", {
            message: m,
            position: "topCenter",
          });
        }
      }
    } else {
      immediateToast("error", {
        message: msg || "Произошла ошибка",
        position: "topCenter",
      });
    }
  }
};
