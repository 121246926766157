import React, {useEffect, useState} from "react";
import { MemberCard } from "./MemberCard";
import {fetchMembers} from "../../../redux/eventReducer";
import {useDispatch, useSelector} from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { popupClose } from "../../../assets";

const Members = ({ frameRef }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const members = useSelector(state => state.events.members);
  // копия списка members для поиска участника
  const [membersFiltered, setMembersFiltered] = useState(members);

  const [inputSearch, setInputSearch] = useState("");

  useEffect(() => {
    dispatch(fetchMembers(id));
  }, [dispatch, id]);

  // обновить membersFiltered при обновлении members
  useEffect(() => {
    setMembersFiltered(members);
  }, [members]);

  // применить поиск
  useEffect(() => {
    const inputLower = inputSearch.toLowerCase();
    setMembersFiltered(
      members.filter(e =>
        String(e.name).toLowerCase().includes(inputLower) ||
        String(e.person).toLowerCase().includes(inputLower) ||
        String(e.email).toLowerCase().includes(inputLower) ||
        String(e.phone).toLowerCase().includes(inputLower)));
  }, [inputSearch, members]);

  const onClick = (m) => {
    history.push(
      history.location.pathname + `?location=member&memberId=place${m.position}`
    );
    if(m.is_lobby) {
      frameRef.current.contentWindow.postMessage("idb4", "*");
    }else{
      frameRef.current.contentWindow.postMessage("idb5", "*")
    }
    setTimeout(() => {
      frameRef.current.contentWindow.postMessage(
        JSON.stringify({
          type: "goToStand",
          id: `place${m.is_lobby? 0 : m.position}`, // имя лобби - place0
        }),
        "*"
      );
    }, 100);
  };

  const onSearch = (e) => {
    let newValue = e.target.value;
    setInputSearch(newValue);
  };

  // вернуть только опубликованные стенды (status_code === 15)
  return (
    <div className="members-popup">
      <div>
        <form
          className="event-calendar__form"
          onSubmit={(e) => e.preventDefault()}
          action="#"
        >
          <input
            value={inputSearch}
            onChange={onSearch}
            className="event-calendar__input"
            type="text"
            placeholder="Поиск участника"
          />
          {/* <div className="event-popup__close"></div> */}
          <div onClick={()=>setInputSearch("")} className="event-popup__close"
              style={{top: "25%", filter: "opacity(30%)"}}>
            <img src={popupClose} alt="close" />
          </div>
        </form>
      </div>
      <br></br>
      <ul className="members-popup__list">
        {membersFiltered.map((m) => 15 !== m.status_code ? null : (
          <MemberCard
            key={m.id}
            image={m.logo}
            groupName={m.name}
            standNumber={m.position}
            isLobby={m.is_lobby}
            fullName={m.person}
            email={m.email}
            numbers={m.phone}
            onClick={() => { onClick(m); }}
            id={m.id}
          />
        )).filter(v => v !== null)}
      </ul>
    </div>
  );
};

export default Members;
