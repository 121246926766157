import React from "react";

const PopupEditButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="red-btn event-popup__button">
      Редактировать
    </button>
  );
};

export default PopupEditButton;
