import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getStands } from "../../api/organizer";
import PlanExample from "../../components/events/PlanExample";
import {
  ParticipantsOrder,
  ParticipantsStand,
} from "../../components/stand/participants";
import { PromotionExample } from "../../components/stand/promotion";

const ParticipantsSale = () => {
  const { id: eventId } = useParams();

  const [stands, setStands] = useState([]);

  const [standsPlanPreview, setStandsPlanPreview] = useState("");

  const [ordersHistory, setOrdersHistory] = useState([]);

  const roomViewerUrl = useSelector((state) => state.app.settings.roomViewerUrl);

  useEffect(() => {
    const fetchStands = async () => {
      try {
        const { data } = await getStands({ eventId });

        const standsData = data.data.items;
        const ordersData = data.data.history;

        const fetchedStands = Object.values(standsData);

        setStands(
          fetchedStands.map((s) => ({
            id: s.id,
            standNumber: s.position,
            statusCode: s.status_code,
            status: s.status,
            company: s.company,
            ownerId: s.id,
          }))
        );

        setStandsPlanPreview(data.data.preview);
        setOrdersHistory(
          ordersData.map((o) => ({
            position: o.position,
            ownerId: o.id,
            company: o.company,
            date: o.created_at,
            standId: o.stand_id,
          }))
        );
      } catch (error) {}
    };

    fetchStands();
  }, [eventId]);

  return (
    <main className="participants-main">
      <div className="participants-stands">
        {stands.map((stand) => (
          <ParticipantsStand
            key={stand.standNumber}
            standNumber={stand.standNumber}
            status={stand.status}
            statusCode={stand.statusCode}
            company={stand.company}
            ownerId={stand.ownerId}
          />
        ))}
      </div>
      <div className="participants-history">
        {standsPlanPreview && <PlanExample />}
        <PromotionExample
          eventId={isNaN(eventId) ? null : eventId}
          roomViewerUrl={roomViewerUrl}
        />
        {ordersHistory && ordersHistory.length > 0 && (
          <div className="participants-orders">
            <div className="participants-orders__title">История заказов</div>
            <div className="participants-orders__main">
              {ordersHistory.map((order) => (
                <ParticipantsOrder
                  key={order.position}
                  ownerId={order.ownerId}
                  company={order.company}
                  date={order.date}
                  position={order.position}
                  standId={order.standId}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default ParticipantsSale;
