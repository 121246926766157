import React, { useState } from "react";
import { landingThreeWhales } from "../../assets";

const values = [
  {
    title: "БЫСТРО",
    paragraphs: [
      "Время самое дорогое, что у нас есть! В современном мире ценность времени выросла до невероятных высот. Простые инструменты управления мероприятием, позволяют создавать стенды, вносить изменения в анонсы, менять расписание и добавлять рекламные материалы в считанные минуты в реально времени. Мы уважаем Ваше время и внедряем новые технологии, чтобы Вы могли максимально сократить время на подготовку мероприятия и формирование отчетности по его завершению. Распределите освободившееся время на развитие Вашего бизнеса.",
    ],
  },
  {
    title: "НАДЕЖНО",
    paragraphs: [
      "Мы бережно и строго относимся к защите персональных данных. Все данные надежно защищены от утечки, доступ к информации строго контролируется организатором мероприятия. Данные, опубликованные на платформе, проходят строгую модерацию. ",
    ],
  },
  {
    title: "ЭФФЕКТИВНО",
    paragraphs: [
      "Мы стерли временные и географические границы, чтобы Ваше мероприятие получило больший отклик в новых для Вас регионах. Организовывая выставку онлайн или в дополнение к офлайн мероприятию, Вы получаете возможность увеличить часы работы или презентовать свои проекты в отдаленных регионах.",
      "Пользуйтесь статистическими данными в реальном времени мероприятия и управляйте работой стендов Ваших экспонентов, так чтобы посетители остались довольны.",
    ],
  },
];

const ValuesJEP = () => {
  const [selectedValue, setSelectedValue] = useState(values[0]);

  return (
    <div className="landing-three-whales">
      <div className="landing__block-title">ЦЕННОСТИ JEP</div>
      <div className="landing-three-whales__main">
        <div className="landing-three-whales__image">
          <button
            onClick={() => setSelectedValue(values[0])}
            className="landing-three-whales__image-btn landing-three-whales__image-btn--up"
          ></button>
          <button
            onClick={() => setSelectedValue(values[1])}
            className="landing-three-whales__image-btn landing-three-whales__image-btn--left"
          ></button>
          <button
            onClick={() => setSelectedValue(values[2])}
            className="landing-three-whales__image-btn landing-three-whales__image-btn--right"
          ></button>
          <img src={landingThreeWhales} alt="" />
        </div>
        <div className="landing-three-whales__text">
          <div className="landing-three-whales__title">
            <span></span>
            {selectedValue.title}
          </div>
          <div className="landing-three-whales__post-title">
            {selectedValue.paragraphs.map((p, i) => (
              <span key={i}>{p}</span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValuesJEP;
