import React from "react";
import { popupClose } from "../../assets";
import { ButtonLoader } from "./";

// utils/useConfirmPopup для назначения пропов попапа
const ConfirmPopup = ({ popupInfo, onCloseClick, isLoading = false }) => {
  const confirmBtnText = popupInfo.customConfirmText || "Ок";

  return (
    <div className="confirmation-popup">
      <div className="confirmation-popup__bg"></div>
      <div className="confirmation-popup__container">
        <div className="confirmation-popup__wrapper">
          <div className="confirmation-popup__header">
            <div className="confirmation-popup__title">{popupInfo.title}</div>
            <button onClick={onCloseClick} className="confirmation-popup__close-btn">
              <img src={popupClose} alt="" />
            </button>
          </div>
          <div className="confirmation-popup__main">
            <div className="confirmation-popup__text">{popupInfo.text}</div>
            {popupInfo.additional && (
              <div className="confirmation-popup__additional">{popupInfo.additional}</div>
            )}
            <div className="confirmation-popup__buttons">
              <button
                onClick={popupInfo.onCancelClick}
                className="confirmation-popup__cancel-btn white-btn"
              >
                {popupInfo.customCloseText || "Отмена"}
              </button>
              {popupInfo.link ? (
                <a
                  href={popupInfo.link}
                  onClick={popupInfo.onConfirmClick}
                  target="_blank"
                  rel="noreferrer"
                  className="confirmation-popup__agree-btn red-btn"
                  style={{ width: isLoading ? 100 : "auto" }}
                >
                  {isLoading ? <ButtonLoader /> : confirmBtnText}
                </a>
              ) : (
                <button
                  onClick={popupInfo.onConfirmClick}
                  className="confirmation-popup__agree-btn red-btn"
                  style={{ width: isLoading ? 100 : "auto" }}
                >
                  {isLoading ? <ButtonLoader /> : confirmBtnText}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
