import React from "react";
import { setProfileInfo } from "../../redux/userReducer";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { immediateToast } from "izitoast-react";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";

const PopupUser = ({ user }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onLogin = (user) => {
    dispatch(setProfileInfo(user));
    immediateToast("info", {
      message: "Происходит перенаправление в другой аккаунт...",
      position: "topCenter",
    });
    setTimeout(() => {
      history.push("/events/list");
      window.location.reload();
    }, 1000);
  };

  return (
    <div onClick={() => onLogin(user)} className="popup-user">
      <div className="popup-user__avatar">
        <img src={user.avatar} alt={user.fullName} />
      </div>
      <div className="popup-user__info">
        <div className="popup-user__name">{user.fullName}</div>
        <div className="popup-user__pos">{capitalizeFirstLetter(user.role)}</div>
      </div>
    </div>
  );
};

export default PopupUser;
