import React from "react";
import { ContactsCard } from "./";

const CardsList = ({ cards }) => {
  return (
    <main className="contacts-cards">
      {cards.map((card, i) => (
        <ContactsCard
          key={i}
          name={card.name}
          email={card.email}
          mobile={card.mobile}
          company={card.company}
          avatar={card.avatar}
        />
      ))}
    </main>
  );
};

export default CardsList;
