import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTariffs } from "../../../api/member";
import { createEventChooseTariff } from "../../../api/organizer";
import { AcceptArrowGreen, AcceptArrowGrey } from "../../../assets";
import { setCreateEventInfo } from "../../../redux/appReducer";
import { setFillingPageName } from "../../../redux/eventReducer";
import { errorHandler } from "../../../utils/errorHandler";
import { successMessage } from "../../../utils/successMessage";
import { EventHeader } from "./";
import { pageNames } from "./EventSidebar";
import SaveButton from "./SaveButton";

const tariffsSpecs = [
  { title: "Выбор шаблона зала", value: "Да" },
  {
    title: "Количество стендов",
    value: "Неограничено",
  },
  {
    title: "Выбор типа стендов ПРЕМИУМ / БИЗНЕС / СТАНДАРТ",
    value: (t) => (t.type !== "gallery" ? "Да" : "Нет"),
  },
  {
    title: `ПРЕМИУМ
7 презентационных поверхностей на фронтальных зонах стенда; хостесс с аудио приветствием; приоритет в выборе рекламных площадей; 2 рекламных интерактиных баннера в холле и общем зале выставки`,
    value: (t) => t.type !== "gallery",
    isNested: true,
  },
  {
    title: `БИЗНЕС 
  5 презентационных поверхностей на фронтальных зонах стенда; хостесс с аудио приветствием; 1 рекламный интерактиный баннер в холле или общем зале выставки`,
    value: (t) => t.type !== "gallery",
    isNested: true,
  },
  {
    title: `СТАНДАРТ 
  4 презентационных поверхностей на фронтальных зонах стенда; хостесс с аудио приветствием`,
    value: (t) => t.type !== "gallery",
    isNested: true,
  },
  {
    title: "Выбор шаблонов стендов:",
    value: (t) => (t.type !== "gallery" ? "Да" : "Нет"),
  },

  {
    title: `Выбор из шаблонов стендов типа ПРЕМИУМ`,
    value: (t) => t.type !== "gallery",
    isNested: true,
  },
  {
    title: `Выбор из шаблонов стендов типа БИЗНЕС`,
    value: (t) => t.type !== "gallery",
    isNested: true,
  },
  {
    title: `Выбор из шаблонов стендов типа СТАНДАРТ`,
    value: (t) => t.type !== "gallery",
    isNested: true,
  },
  {
    title: "Продажа рекламных баннеров",
    value: (t) => (t.type !== "gallery" ? "Да" : "Нет"),
  },
  { title: "Организация вебинаров", value: "Да" },
  { title: "Создание ресепшен (выбор из нескольких шаблонов) ", value: "Да" },
  { title: "Текстовый чат", value: "Да" },
  {
    title: "Возможность рассылки приглашений участникам",
    value: (t) => (t.type !== "gallery" ? "Да" : "Нет"),
  },
  {
    title: "Возможность рассылки приглашений посетителям",
    value: "Да",
  },
  { title: "Создание посадочной страницы", value: "Да" },
  { title: "Контакты посетителей", value: "Да" },
  { title: "Аналитика", value: "Да" },
  {
    title: "Документооборот (возможность обмениваться документами на платформе)",
    value: "Да",
  },
];

const SecondStep = ({ disabled }) => {
  const { id: eventId } = useParams();

  const dispatch = useDispatch();
  const [tariffs, setTariffs] = useState([]);
  const currentTariffId = useSelector((state) => state.app.createEventInfo.tarif)?.id;
  const onTarifChange = (tarif) => {
    dispatch(setCreateEventInfo({ tarif }));
  };

  useEffect(() => {
    const fetchTarifs = async () => {
      try {
        const { data } = await getTariffs();
        const allTariffs = data?.data?.tariffs;

        if (!allTariffs) return;

        setTariffs(allTariffs);
      } catch (error) {}
    };
    fetchTarifs();
  }, []);

  const onSave = async () => {
    try {
      await createEventChooseTariff({ eventId, tariffId: currentTariffId });

      dispatch(
        setFillingPageName({
          eventId,
          name: pageNames.HALL_CHOSE,
        })
      );

      successMessage("Пакет успешно выбран.");
    } catch (error) {
      errorHandler(error);
    }
  };

  const isAllow = currentTariffId && !disabled;

  return (
    <div className="create-stand__content create-stand-content">
      <EventHeader SaveButton={<SaveButton disabled={!isAllow} onClick={onSave} />} />
      <table className="create-stand-content__price-table">
        <tbody>
          <tr className="create-stand-content__price-header create-stand-content__price-row">
            <td className="create-stand-content__price-cell">Описание пакета</td>
            {tariffs.map((t) => (
              <React.Fragment key={t.id}>
                <td className="create-stand-content__price-cell create-stand-content__price-cell--bold">
                  {t.name}
                </td>
              </React.Fragment>
            ))}
          </tr>
          {tariffsSpecs.map((spec, ind) => {
            let title = <span>{spec.title}</span>;

            let paragraphs =
              typeof spec.title === "string" ? String(spec.title).split("\n") : [];

            if (paragraphs.length > 1) {
              title = (
                <div>
                  {paragraphs.map((p, i) => (
                    <span key={i}>{p}</span>
                  ))}
                </div>
              );
            }

            return (
              <tr
                key={ind}
                className={classNames("create-stand-content__price-row", {
                  "create-stand-content__price-row--nested": spec.isNested,
                })}
              >
                <td className="create-stand-content__price-cell">{title}</td>
                {tariffs.map((tariff, index) => {
                  let value = spec.value;

                  if (typeof value === "function") {
                    value = spec.value(tariff);
                  }

                  if (typeof value === "boolean") {
                    if (value) {
                      value = <img alt={""} src={AcceptArrowGreen} />;
                    } else {
                      value = <img alt={""} src={AcceptArrowGrey} />;
                    }
                  }

                  return (
                    <td key={index} className="create-stand-content__price-cell">
                      {value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <tr className="create-stand-content__price-row create-stand-content__price-footer">
            <td className="create-stand-content__price-cell">Стоимость</td>
            {tariffs.map((t) => (
              <React.Fragment key={t.id}>
                <td className="create-stand-content__price-cell">
                  {t?.price_name || t.price} ₽
                </td>
              </React.Fragment>
            ))}
          </tr>
          <tr className="create-stand-content__price-row">
            <td className="create-stand-content__price-cell"></td>
            {tariffs.map((t) => (
              <React.Fragment key={t.id}>
                <td className="create-stand-content__price-cell">
                  <button
                    onClick={() => onTarifChange(t)}
                    className={classNames("blue-btn", {
                      "blue-btn--active": t.id === currentTariffId,
                    })}
                    disabled={disabled}
                  >
                    Выбрать
                  </button>
                </td>
              </React.Fragment>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SecondStep;
