import React from "react";
import { useHistory } from "react-router-dom";
import { CloseSvg } from "../../assets";
import queryString from "query-string";

const Popup = ({ title, main }) => {
  const history = useHistory();

  const closePopup = () => {
    const newUrl = queryString.exclude(
      history.location.pathname + history.location.search,
      ["popup"]
    );

    history.push(newUrl);
  };

  return (
    <div className="events-popup">
      <header className="events-popup__header">
        <h2 className="events-popup__title">{title}</h2>
        <button onClick={closePopup} className="events-popup__close">
          <img src={CloseSvg} alt="" />
        </button>
      </header>
      <div className="events-popup__main">{main}</div>
    </div>
  );
};

export default Popup;
