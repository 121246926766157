import classNames from "classnames";
import { immediateToast } from "izitoast-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getParticipantInfo } from "../../api/organizer";
import { changeDocumentStatus } from "../../api/documents";
import {
  Checker,
  ConfirmPopup,
  Header,
  Sidebar,
  SidePopupChat,
} from "../../components/shared";
import { NavStand, StandHeader } from "../../components/stand";
import { ParticipantMainInfo } from "../../components/stand/participants";
import { useConfirmPopup } from "../../hooks/useConfirmPopup";
import { fetchDocuments, setStatusOfDocument } from "../../redux/eventReducer";
import { errorHandler } from "../../utils/errorHandler";
import { pdfIcon } from "../../assets";
import { downloadPdf } from "../../utils/downloadPdf";

// const dummyDocuments = [
// {
//   id: 23344533,
//   date: new Date(),
//   serviceType: "Стенд №1 Farm expo",
//   price: 60000,
//   VAT: 2000,
//   isSolved: true,
// },
// ];

const ParticipantCard = () => {
  const { popupInfo, setPopupInfo, closePopup } = useConfirmPopup();

  const { id: eventId, memberId } = useParams();

  const [cardInfo, setCardInfo] = useState(null);
  const dispatch = useDispatch();

  const documents = useSelector((state) => state.events.docs);

  useEffect(() => {
    const fetchCardInfo = async () => {
      try {
        const { data } = await getParticipantInfo({ eventId, participantId: memberId });

        const cardData = data.data;

        setCardInfo(cardData);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchCardInfo();
  }, [eventId, memberId]);

  useEffect(() => {
    dispatch(
      fetchDocuments({
        type: "stand",
        id: memberId,
      })
    );
  }, [dispatch, memberId]);

  const onCheckerClick = (documentId, checked) => {
    setPopupInfo({
      title: "Документ",
      text: "Вы уверены что хотите изменить статус заказа?",
      onCancelClick: () => {
        closePopup();
      },
      onConfirmClick: async () => {
        const status = checked ? "signed" : "unsigned";
        try {
          const { data } = await changeDocumentStatus({
            id: documentId,
            status,
          });
          if (data.success) {
            dispatch(setStatusOfDocument({ id: documentId, status }));
            immediateToast("success", {
              message: "Статус успешно изменен.",
              position: "topCenter",
            });
          }
        } catch (e) {
          errorHandler(e);
        }

        closePopup();
      },
      isOpened: true,
    });
  };

  const onFileClick = ({ fileUrl }) => {
    downloadPdf(fileUrl, "document.pdf");
  };

  return (
    <div className="app">
      <Sidebar />
      <Header />
      <SidePopupChat />
      <div className="page__main">
        {popupInfo.isOpened && (
          <ConfirmPopup popupInfo={popupInfo} onCloseClick={closePopup} />
        )}
        <NavStand />
        <div className="stand-settings">
          <StandHeader title="Информация" back />
          <div className="participants-documents">
            {cardInfo && <ParticipantMainInfo cardInfo={cardInfo} />}
            {documents && documents.length > 0 && (
              <div className="table-responsive ">
                <table className="table table-bordered  participants-table ">
                  <thead className="table__head">
                    <tr>
                      <th scope="col">Дата</th>
                      <th scope="col">Номер документа</th>
                      <th scope="col">Тип</th>
                      <th scope="col">Услуга</th>
                      <th scope="col">Стоимость, руб</th>
                      <th scope="col">Файлы</th>
                      <th scope="col">Статус</th>
                      <th scope="col">Активация</th>
                    </tr>
                  </thead>
                  <tbody className="table__body">
                    {documents.map((item) => (
                      <tr key={item.id}>
                        <td>{moment(item.created_at).format("DD MMM YYYY")}</td>
                        <td>{item.number}</td>
                        <td>
                          {(() => {
                            if (item.type === "bill") {
                              return "Счёт";
                            } else if (item.type === "contract") {
                              return "Договор";
                            } else if (item.type === "act") {
                              return "Акт";
                            } else {
                              return "(без типа)";
                            }
                          })()}
                        </td>
                        <td>{item.service}</td>
                        <td>{item.price}</td>
                        <td>
                          {item.file && (
                            <img
                              onClick={() => onFileClick({ fileUrl: item.file })}
                              src={pdfIcon}
                              alt=""
                              className="participants-documents__scan"
                              style={{ cursor: "pointer" }}
                            />
                          )}
                          {item.scans?.map((sc, ind) => (
                            <img
                              onClick={() => onFileClick({ fileUrl: sc })}
                              key={ind}
                              src={pdfIcon}
                              alt=""
                              className="participants-documents__scan"
                              style={{ cursor: "pointer" }}
                            />
                          ))}
                        </td>
                        <td
                          className={classNames({
                            "table__status-success": item.status_code === 15,
                            "table__status-wait": item.status_code !== 15,
                          })}
                        >
                          <span>{item.status}</span>
                        </td>
                        <td>
                          <div className="participants__table-checker">
                            <Checker
                              isActivated={item.status_code === 15}
                              onClick={(e) => onCheckerClick(item.id, e.target.checked)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipantCard;
