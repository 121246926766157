import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Layout } from "../../components/shared";
import {
  useParticipantTariffs,
  ParticipantTariffsTable,
  ParticipantStandsCount,
} from "../../components/shared/ParticipantTariffsTable";
import { NavStand } from "../../components/stand";
import { fetchTariff } from "../../redux/eventReducer";
import { exportPageToPdf } from "../../utils/exportPageToPdf";

const ResumePage = () => {
  const currentEvent = useSelector((state) => state.events.currentEvent) || {};
  const tariffStands = useSelector((state) => state.events.tariffStands) || [];
  const formatedTariffStands = Array.from(tariffStands).reverse();
  const dispatch = useDispatch();

  const [currentEventStatus] = currentEvent?.status_type || [];

  const showResumeInfo = currentEventStatus === "actual";

  const { id } = useParams();

  const { tariffs } = useParticipantTariffs({
    isPlaceholders: true,
  });

  useEffect(() => {
    dispatch(fetchTariff(id));
  }, [id, dispatch]);

  const isSameYearDates =
    moment(currentEvent.start_date).format("YYYY") ===
    moment(currentEvent.end_date).format("YYYY");

  const onDownloadPdfClick = () => {
    exportPageToPdf({
      wrapperSelector: ".resume__wrapper",
      hiddenElementsSelectors: [".resume-event__download-pdf"],
      fileName: "event-resume",
    });
  };

  return (
    <Layout>
      <div className="organizer-panel__main">
        <NavStand />
        <div className="stand-settings resume">
          <div className="resume__wrapper">
            {showResumeInfo ? (
              <div className="resume-event">
                <div className="resume-event__image">
                  <img src={currentEvent.logo} alt="" />
                </div>
                <div className="resume-event__about">
                  <div className="resume-event__about-header">
                    <div className="resume-event__header-left">
                      {currentEvent.start_date && currentEvent.end_date && (
                        <div className="resume-event__dates">
                          Даты проведения:{" "}
                          <span>
                            {moment(currentEvent.start_date).format(
                              `DD MMMM ${isSameYearDates ? "" : "YYYY"}`
                            )}
                            – {moment(currentEvent.end_date).format("DD MMMM YYYY")}
                          </span>
                        </div>
                      )}

                      <div className="resume-event__title">{currentEvent.name}</div>
                    </div>
                    <div className="resume-event__right">
                      <button
                        className="red-btn resume-event__download-pdf"
                        onClick={onDownloadPdfClick}
                        disabled={!currentEvent.id}
                      >
                        Скачать pdf
                      </button>
                    </div>
                  </div>
                  <div className="resume-event__about-text">
                    {currentEvent.short_description}
                  </div>
                </div>
              </div>
            ) : (
              <h2 style={{ marginBottom: 30 }}>
                Для получения полной информации пройдите модерацию заявки на мероприятие
              </h2>
            )}
            <div className="resume-tariffs">
              <div className="resume-tariffs__title">
                Тарифы и стоимость для участников
              </div>
              <div className="resume-tariffs__table">
                <ParticipantTariffsTable tariffs={tariffs} />
              </div>
              {tariffStands?.length > 0 && (
                <div className="resume-tariffs__table">
                  <ParticipantStandsCount stands={formatedTariffStands} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResumePage;
