import React from "react";
import { pdfLogoSite } from "../../../../assets";
import { downloadPdf } from "../../../../utils/downloadPdf";

const DemoPresentation = ({ colorTitle, pdf }) => {
  const onClick = () => {
    downloadPdf(pdf, "presentation.pdf");
  };

  return (
    pdf && (
      <div className="demo__container">
        <button
          onClick={onClick}
          className="demo-presentation"
          style={{ color: colorTitle }}
        >
          <img src={pdfLogoSite} alt="" />
          Скачать pdf презентацию
        </button>
      </div>
    )
  );
};

export default DemoPresentation;
