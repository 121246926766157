import React from "react";
import { NavLink } from "react-router-dom";
import { list, table } from "../../assets";

const EventsNav = () => {
  return (
    <div className="event-calendar__nav">
      <NavLink to="/events/list" activeClassName="active">
        <div className="event-calendar__nav-item">
          <img src={list} alt="Nav Icon" />
        </div>
      </NavLink>
      <NavLink to="/events/calendar" activeClassName="active">
        <div className="event-calendar__nav-item">
          <img src={table} alt="Nav Icon" />
        </div>
      </NavLink>
    </div>
  );
};

export default EventsNav;
