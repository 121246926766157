import React, { useState } from "react";
import { FavoriteNav, Title } from ".";
import { AdBanners } from "../events";
import { EventConfirmPopup, Header, Loader, Sidebar, SidePopupChat } from "../shared";

const PageWrapper = ({ children, isLoading }) => {
  const [popupOpened, setPopupOpened] = useState(false);

  return (
    <div className="app">
      {popupOpened && <EventConfirmPopup closePopup={() => setPopupOpened(false)} />}
      <Sidebar />
      <SidePopupChat />
      <Header />
      <div className="favorite">
        <Title />
        <FavoriteNav />
        <main className="favorite__main">
          {isLoading ? (
            <div className="favorite__loader">
              <Loader />
            </div>
          ) : (
            children
          )}
          <AdBanners />
        </main>
      </div>
    </div>
  );
};

export default PageWrapper;
