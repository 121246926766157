import { combineReducers, configureStore } from "@reduxjs/toolkit";

import webinarSlice from "./webinarReducer";
import bannerSlice from "./bannerReducer";
import appSlice from "./appReducer";
import userSlice from "./userReducer";
import eventSlice from "./eventReducer";
import chatSlice from "./chatReducer";
import contactsSlice from "./contactsReducer";
import eventSettingsSlice from "./eventSettings";
import programSlice from "./programReducer";
import mailingSlice from "./mailingReducer";
import notificationsSlice from "./notificationsReducer";

const rootReducer = combineReducers({
  webinars: webinarSlice,
  banners: bannerSlice,
  app: appSlice,
  user: userSlice,
  events: eventSlice,
  chats: chatSlice,
  contacts: contactsSlice,
  eventSettings: eventSettingsSlice,
  program: programSlice,
  mailing: mailingSlice,
  notifications: notificationsSlice,
});

export const store = configureStore({ reducer: rootReducer });
