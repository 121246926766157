import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setDemoBackgroundColor,
  setDemoTextColor,
  setDemoTitleColor,
} from "../../../redux/appReducer";
import { Badge } from "../../stand/promotion";

const textColors = ["#202531", "#676c6f", "#aeb5bd", "#ffffff"];

const titleColors = [
  "#39257e",
  "#0083c7",
  "#09aaa4",
  "#015756",
  "#008a57",
  "#321821",
  "#71370f",
  "#d20025",
  "#e84f13",
  "#a44a0e",
  "#ffdc1e",
  "#f0bb8f",
  "#f4dda7",
  "#ffffff",
];

const backgroundColors = [
  "#D7D3E5",
  "#CCE6F4",
  "#CCDDDD",
  "#CCE8DD",
  "#D6D1D3",
  "#E3D7CF",
  "#E4CED2",
  "#F6CCD3",
  "#FADCD0",
  "#EDDBCF",
  "#FFF8D2",
  "#FCF1E9",
  "#FDF8ED",
  "#ffffff",
];

const PromotionWebColors = () => {
  const role = useSelector((state) => state.user.profile.role);

  const isOrganizer = role === "организатор";

  const dispatch = useDispatch();
  const demoInfo = useSelector((state) => state.app.demo);

  const textColor = demoInfo.color.text;
  const titleColor = demoInfo.color.title;
  const backgroundColor = demoInfo.color.background;

  useEffect(() => {
    const defaultOrganizerColors = {
      text: "#202531",
      title: "#09aaa4",
      bg: "#CCE6F4",
    };

    const defaultParticipantColors = {
      text: "#676c6f",
      title: "#e84f13",
      bg: "#D6D1D3",
    };

    if (!textColor) {
      dispatch(
        setDemoTextColor(
          isOrganizer ? defaultOrganizerColors.text : defaultParticipantColors.text
        )
      );
    }

    if (!titleColor) {
      dispatch(
        setDemoTitleColor(
          isOrganizer ? defaultOrganizerColors.title : defaultParticipantColors.title
        )
      );
    }

    if (!backgroundColor) {
      dispatch(
        setDemoBackgroundColor(
          isOrganizer ? defaultOrganizerColors.bg : defaultParticipantColors.bg
        )
      );
    }
  }, [backgroundColor, dispatch, isOrganizer, textColor, titleColor]);

  const onTextColor = (hex) => {
    dispatch(setDemoTextColor(hex));
  };

  const onTitleColor = (hex) => {
    dispatch(setDemoTitleColor(hex));
  };

  const onBackgroundColor = (hex) => {
    dispatch(setDemoBackgroundColor(hex));
  };

  return (
    <div className="web-item">
      <div className="web-colors__item">
        <div className="web-colors__item-text">Цвет основного текста</div>
        <div className="web-colors__item-colors">
          {textColors.map((color, ind) => (
            <Badge
              key={ind}
              onClick={() => onTextColor(color)}
              color={color}
              isActive={textColor === color}
            />
          ))}
        </div>
      </div>
      <div className="web-colors__item">
        <div className="web-colors__item-text">Цвет заголовков и кнопок</div>
        <div className="web-colors__item-colors">
          {titleColors.map((color, ind) => (
            <Badge
              key={ind}
              onClick={() => onTitleColor(color)}
              color={color}
              isActive={titleColor === color}
            />
          ))}
        </div>
      </div>
      <div className="web-colors__item">
        <div className="web-colors__item-text">Цвет фона</div>
        <div className="web-colors__item-colors">
          {backgroundColors.map((color, ind) => (
            <Badge
              key={ind}
              onClick={() => onBackgroundColor(color)}
              color={color}
              isActive={backgroundColor === color}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PromotionWebColors;
