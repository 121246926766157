import { immediateToast } from "izitoast-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Element } from "react-scroll";
import { fetchMoreEvents, fetchEvents } from "../../redux/eventReducer";
import { prepareEventList } from "../../utils/prepareEventList";
import { EventItem } from "../events/";

const ClosestEvents = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const events = useSelector((state) => state.events.events);
  const lastPage = useSelector((state) => state.events.lastPage);

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 3;

  useEffect(() => {
    dispatch(
      fetchEvents({
        dateType: "actual",
        limit,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setItems(prepareEventList(events).sort((a, b) => a.startDate - b.startDate));
  }, [events]);

  const onEventClick = () => {
    history.push("/entry/registration");

    immediateToast("info", {
      message: "Необходимо зарегистрироваться, чтобы посетить данное мероприятие.",
      position: "topCenter",
    });
  };

  const onMoreItems = () => {
    setPage(page + 1);
    dispatch(
      fetchMoreEvents({
        dateType: "actual",
        page: page + 1,
        limit,
      })
    );
  };

  return (
    <Element name="landing-closest-events" className="landing-closest-events">
      <div className="landing__block-title">ближайшие мероприятия</div>
      <div className="landing__block-post-title">
        Успейте выбрать и зарегистрироваться на ближайшие мероприятия
      </div>
      <div className="landing-closest-events__main">
        {items &&
          items.length > 0 &&
          items.map((ev) => {
            return (
              <EventItem
                key={ev.id}
                eventInfo={ev}
                onEventClick={onEventClick}
                className="landing-closest-events__event"
              />
            );
          })}
        {items && items.length > 0 && lastPage !== page && (
          <button onClick={onMoreItems} className="landing-closest-events__show-more">
            Показать ещё мероприятия
          </button>
        )}
      </div>
    </Element>
  );
};

export default ClosestEvents;
