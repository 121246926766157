import { getToken, instance } from ".";

export const getContacts = () => {
  const token = getToken();

  return instance.get("/api/v1/chat/contacts", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getChat = ({ chatId }) => {
  const token = getToken();

  return instance.get(`/api/v1/chat/${chatId}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const storeMessage = ({ chatId, text }) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("text", text);

  return instance.post(`/api/v1/chat/${chatId}`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const exportContacts = ({ eventId, standId }) => {
  const token = getToken();
  let url;

  if (eventId) {
    url = `/api/v1/event/${eventId}/contacts/export`;
  } else if (standId) {
    url = `/api/v1/stand/${standId}/contacts/export`;
  }

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const markRead = ({ id }) => {
  const token = getToken();
  
  return instance.get(`/api/v1/chat/${id}/mark/read`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createContact = ({ side, eventId, standId }) => {
  const token = getToken();
  let url = `/api/v1/chat/${side}/create`;
  const formData = new URLSearchParams();

  if (eventId) {
    formData.append("event_id", eventId);
  }

  if (standId) {
    formData.append("stand_id", standId);
  }

  return instance.post(url, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};
