import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications(state, { payload }) {
      state.items = payload;
    },

    deleteNotification(state, { payload }) {
      state.items = state.items.filter((item) => item.id !== payload);
    },
  },
});

export const { setNotifications, deleteNotification } = notificationsSlice.actions;
export default notificationsSlice.reducer;
