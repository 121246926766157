import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";

const ParticipantsStand = ({ standNumber, statusCode, status, company, ownerId }) => {
  const { id } = useParams();

  const [popupIsOpen, setPopupIsOpen] = useState(false);

  return (
    <div className={classNames("participants-stands__item", { owned: company })}>
      <div className="participants-stands__name">Стенд №{standNumber}</div>
      <div className="participants-stands__info">
        {company && <div className="participants-stands__owner">{company}</div>}
        {statusCode === -1 && <div className="event-item__places safe">Свободно</div>}
        {statusCode !== -1 && <div className="event-item__places danger">{status}</div>}
        <button disabled={!company} onClick={() => setPopupIsOpen(!popupIsOpen)}>
          <i className="participants-stands__button">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.33333 9.33268C2.59695 9.33268 2 8.73573 2 7.99935C2 7.26297 2.59695 6.66602 3.33333 6.66602C4.06971 6.66602 4.66667 7.26297 4.66667 7.99935C4.66667 8.73573 4.06971 9.33268 3.33333 9.33268ZM8 9.33268C7.26362 9.33268 6.66667 8.73573 6.66667 7.99935C6.66667 7.26297 7.26362 6.66602 8 6.66602C8.73638 6.66602 9.33333 7.26297 9.33333 7.99935C9.33333 8.73573 8.73638 9.33268 8 9.33268ZM11.3333 7.99935C11.3333 8.73573 11.9303 9.33268 12.6667 9.33268C13.403 9.33268 14 8.73573 14 7.99935C14 7.26297 13.403 6.66602 12.6667 6.66602C11.9303 6.66602 11.3333 7.26297 11.3333 7.99935Z"
                fill={popupIsOpen ? "#3B99CC" : "#727E8E"}
              />
            </svg>
          </i>
        </button>
        {popupIsOpen && ownerId && (
          <Link
            to={`/event/${id}/settings/participant/${ownerId}`}
            className="promotion-item__settings-popup promotion-item__settings-popup--stand"
          >
            Платежные документы
          </Link>
        )}
      </div>
    </div>
  );
};

export default ParticipantsStand;
