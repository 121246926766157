import { getToken, instance } from ".";

export const getDocuments = ({ type, id }) => {
  const token = getToken();

  return instance.get(`/api/v1/${type}/${id}/documents`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const uploadDocumentScan = ({ documentId, file }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("scan[0]", file);

  return instance.post(`/api/v1/document/${documentId}/upload-scans`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const changeDocumentStatus = ({ id, status }) => {
  const token = getToken();

  const formData = new FormData();
  // signed/unsigned
  formData.append("status", status);

  return instance.post(`/api/v1/document/${id}/change-status`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};
