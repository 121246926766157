import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getEventsList } from "../api";
import { getAnalytics } from "../api/analytics";
import { getModerationBanner, getModerationBanners } from "../api/banners";
import { getDocuments } from "../api/documents";
import { changeFillingPageName, getEventInfo, getFillingPageName } from "../api/event";
import { getStandStats } from "../api/member";
import { getModerationStats, getParticipants, getStandTariffs } from "../api/organizer";
import {
  getModerationStand,
  getModerationStands,
  getStandColors,
  getStandInfo,
  getStandMedia,
} from "../api/stand";
import { getModerationWebinar, getModerationWebinars } from "../api/webinar";
import { pageNames } from "../components/organizer-panel/CreateEvent/EventSidebar";
import { errorHandler } from "../utils/errorHandler";

const initialState = {
  createStandOnEvent: 0,
  createRegisterOnEvent: 0,
  currentEvent: {},
  currentStand: null,
  moderationStand: {
    id: 1,
    name: "",
    logo: "",
    short: "Краткое описание",
    fio: "",
    email: "",
    phone: "",
    tariff_name: "Бизнес",
    tariff_price: 600,
    tmpl_id: 6,
    tmpl_image: "",
    created_at: "2020-12-20 23:20:20",
    info: {
      organization: "",
      inn: "",
      registration_number: "",
      bank_name: "",
      bill_number: "",
      bic: "",
      corresponding_number: "",
    },
    media: [],
  },
  docs: [],
  moderationWebinar: {
    id: 231,
    stand_id: 435,
    stand_logo: "",
    stand_name: "",
    name: "",
    short: "",
    started_at: "2021-02-21 22:23:23",
    ended_at: "2021-02-21 23:23:23",
  },
  moderationBanner: {
    id: 231,
    stand_id: 435,
    stand_logo: "",
    stand_name: "",
    name: "",
    url: "",
    number: "",
    started_at: "2021-02-21 22:23:23",
    ended_at: "2021-02-21 23:23:23",
  },
  standsPagination: {
    per_page: 50,
    last_page: 1,
    total: 1,
    from: 1,
  },
  stands: [
    // {
    //   id: 1,
    //   logo: "",
    //   name: "",
    //   status: "Заявка",
    //   status_code: 10,
    // },
  ],
  bannersPagination: {
    per_page: 50,
    last_page: 1,
    total: 1,
    from: 1,
  },
  banners: [
    // {
    //   id: 1,
    //   url: "",
    //   image: "",
    //   name: "",
    //   status: "Заявка",
    //   status_code: 10,
    // },
  ],
  webinarsPagination: {
    per_page: 50,
    last_page: 1,
    total: 1,
    from: 1,
  },
  webinars: [
    {
      id: 231,
      stand_id: 435,
      stand_logo: "",
      stand_name: "",
      name: "",
      status: "Модерация",
      status_code: 10,
    },
  ],
  activeStandIn3D: 0,
  activeEventIn3D: 0,
  currentStandInPlayer: null,
  media: null,
  currentStandPosition: null,
  actualEvents: [],
  events: [],
  moderationStats: {
    stands: 0,
    webinars: 0,
    banners: 0,
  },
  lastPage: 0,
  modelMasks: [],
  members: [],
  tariffId: null,
  tariffStands: [],
  tariffBanners: [],
  analytics: {
    geo: [],
    dem: [],
    avg_time_sessions: 0,
    users: 0,
    trafficsource: [],
    attendance: [],
  },
  stats: {
    actual: 0,
    pass: 0,
    filling: 0,
    requests: 0,
  },
  confirmedStandMediaIds: [],
  fillingPageName: pageNames.SHORT_DESC,
};

export const fetchAnalytics = createAsyncThunk("events/analytics", async (params) => {
  try {
    const { data } = await getAnalytics(params);

    const resp = data.data;
    if (!resp) {
      return;
    }

    return resp;
  } catch (error) {}
});

export const fetchStats = createAsyncThunk("events/stats", async (eventId) => {
  try {
    const { data } = await getStandStats({});

    const resp = data.data;
    if (!resp) {
      return;
    }

    return resp;
  } catch (error) {
    errorHandler(error);
  }
});

export const fetchTariff = createAsyncThunk("events/tariff", async (eventId) => {
  try {
    const { data } = await getStandTariffs({ eventId });

    const id = data.data?.id;
    const stands = data.data?.stands;
    const banners = data.data?.banners;
    if (!id) {
      return { stands: [], banners: [], id: null };
    }

    return { stands, banners, id };
  } catch (error) {
    return { stands: [], banners: [], id: null };
  }
});

export const fetchEvent = createAsyncThunk("events/get", async ({ eventId }) => {
  try {
    const { data } = await getEventInfo({ eventId });
    const name = await getFillingPageName({ eventId });

    const resp = data.data?.item;
    if (!resp) {
      return;
    }

    return { ...resp, fillingPageName: name };
  } catch (error) {
    errorHandler(error);
  }
});

export const fetchEventConstructor = createAsyncThunk(
  "events/get/constructor",
  async ({ eventId }) => {
    try {
      const { data } = await getEventInfo({ eventId, isConstructor: true });

      const resp = data.data;
      if (!resp) {
        return;
      }

      return resp;
    } catch (error) {}
  }
);

export const fetchStand = createAsyncThunk("stands/get", async (params) => {
  try {
    const { data } = await getStandInfo(params);

    const resp = data.data;
    if (!resp) {
      return;
    }

    return resp;
  } catch (error) {
    errorHandler(error);
  }
});

export const fetchStandMedia = createAsyncThunk("stands/media", async (params) => {
  try {
    const { data } = await getStandMedia(params);

    const resp = data.data;
    if (!resp) {
      return;
    }

    return resp;
  } catch (error) {}
});

export const fetchModerationStats = createAsyncThunk("moderation/stats", async (id) => {
  try {
    const { data } = await getModerationStats({ id });

    const resp = data.data;
    if (!resp) {
      return;
    }

    return resp;
  } catch (error) {
    console.error(error);
  }
});

export const fetchStandModeration = createAsyncThunk(
  "stand/moderation",
  async (params) => {
    try {
      const { data } = await getModerationStand(params);

      const resp = data.data;
      if (!resp) {
        return;
      }

      return resp;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchStandsModeration = createAsyncThunk(
  "stands/moderation",
  async (params) => {
    try {
      const { data } = await getModerationStands(params);

      const resp = data.data;

      if (!resp) {
        return;
      }

      return resp;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchBannerModeration = createAsyncThunk(
  "banner/moderation",
  async (params) => {
    try {
      const { data } = await getModerationBanner(params);

      const resp = data.data;
      if (!resp) {
        return;
      }

      return resp;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchBannersModeration = createAsyncThunk(
  "banners/moderation",
  async (params) => {
    try {
      const { data } = await getModerationBanners(params);

      const resp = data.data;
      if (!resp) {
        return;
      }

      return resp;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchWebinarsModeration = createAsyncThunk(
  "webinars/moderation",
  async (params) => {
    try {
      const { data } = await getModerationWebinars(params);

      const resp = data.data;
      if (!resp) {
        return;
      }

      return resp;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchWebinarModeration = createAsyncThunk(
  "webinar/moderation",
  async (params) => {
    try {
      const { data } = await getModerationWebinar(params);

      const resp = data.data;
      if (!resp) {
        return;
      }

      return resp;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchModelMasks = createAsyncThunk("stands/masks", async (params) => {
  try {
    const { data } = await getStandColors(params);

    const resp = data.data;

    if (!resp) {
      return;
    }

    const isCropped = !!params?.standId;

    return resp.map((m, i) => {
      let color = m.color || "";

      if (Array.isArray(color)) {
        color = color?.join();
      }

      return {
        id: i,
        img: isCropped ? `${m.mask}.cropped.png` : m.mask,
        color,
        name: m.object_name,
        type: m.type || (m.color ? "color" : ""),
      };
    });
  } catch (error) {
    return [];
  }
});

export const fetchMembers = createAsyncThunk("events/members", async (eventId) => {
  try {
    const { data } = await getParticipants(eventId);

    const resp = data.data?.items;
    if (!resp) {
      return [];
    }

    return resp.filter((m) => m.position !== 0);
  } catch (error) {
    return [];
  }
});

export const fetchDocuments = createAsyncThunk("documents", async (params) => {
  try {
    const { data } = await getDocuments(params);

    const resp = data.data?.data;
    if (!resp) {
      return [];
    }

    return resp;
  } catch (error) {
    errorHandler(error);
    return [];
  }
});

export const fetchEvents = createAsyncThunk("events/all", async (params) => {
  try {
    const { data } = await getEventsList(params);

    const resp = data.data?.data;
    if (!resp) {
      return [];
    }

    return resp;
  } catch (error) {
    errorHandler(error);
    return [];
  }
});

export const fetchMoreEvents = createAsyncThunk("events/more", async (params) => {
  try {
    const { data } = await getEventsList(params);

    const resp = data.data;
    if (!resp) {
      return [];
    }

    return resp;
  } catch (error) {
    errorHandler(error);
    return [];
  }
});

export const setFillingPageName = createAsyncThunk(
  "events/setFillingPage",
  async ({ eventId, name }) => {
    try {
      const { data } = await changeFillingPageName({ eventId, name });

      const resp = data.data;
      if (!resp) {
        return "";
      }

      return name;
    } catch (error) {
      return "";
    }
  }
);

export const fetchFillingPageName = createAsyncThunk(
  "events/setFillingPage",
  async ({ eventId }) => {
    try {
      const name = await getFillingPageName({ eventId });

      if (!name) {
        return "";
      }

      return name;
    } catch (error) {
      return "";
    }
  }
);

export const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setFileToMedia(state, { payload }) {
      if (state.media && state.media.banners) {
        const { rect, img, mediatype } = payload;

        state.media.banners.forEach((item) => {
          if (item.rect === rect) {
            item.src = img;
            item.src = mediatype;
          }
        });
      }
    },
    setCurrentEvent(state, { payload }) {
      state.currentEvent = payload;
    },
    resetCurrentEvent(state) {
      state.currentEvent = initialState.currentEvent;
      state.fillingPageName = initialState.fillingPageName;
    },
    setCreateStandOnEvent(state, { payload }) {
      state.createStandOnEvent = payload;
    },
    setCreateRegisterOnEvent(state, { payload }) {
      state.createRegisterOnEvent = payload;
    },
    setCurrentStand(state, { payload }) {
      state.currentStand = payload;
    },
    setCurrentStandLogo(state, { payload }) {
      if (!state.currentStand) {
        return;
      }

      state.currentStand.logo = payload;
    },
    setCurrentStandLogoLink(state, { payload }) {
      if (!state.currentStand) {
        return;
      }

      state.currentStand.logo_link = payload;
    },
    setActiveStandIn3D(state, { payload }) {
      state.activeStandIn3D = payload;
    },
    setActiveEventIn3D(state, { payload }) {
      state.activeEventIn3D = payload;
    },
    setCurrentStandInPlayer(state, { payload }) {
      state.currentStandInPlayer = payload;
    },
    setCurrentStandPosition(state, { payload }) {
      state.currentStandPosition = payload;
    },
    setStatusOfDocument(state, { payload }) {
      if (state.docs) {
        state.docs.forEach((doc) => {
          if (doc.id === payload.id) {
            doc.status_code = payload.status === "signed" ? 15 : 40;
            doc.status = payload.status === "signed" ? "Подтверждено" : "Отклонено";
          }
        });
      }
    },
    setActualEvents(state, { payload }) {
      state.actualEvents = payload;
    },
    updateMaskColor(state, { payload }) {
      state.modelMasks = state.modelMasks.map((m) => {
        if (m.id === payload.id) {
          m.color = payload.color;
        }

        return m;
      });
    },
    confirmStandMediaId(state, { payload }) {
      if (state.confirmedStandMediaIds.includes(payload)) return;

      state.confirmedStandMediaIds.push(payload);
    },
  },
  extraReducers: {
    [fetchEventConstructor.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.currentEvent = {
          ...payload,
          fillingPageIndex: state.currentEvent.fillingPageIndex || 0,
        };
      }
    },
    [setFillingPageName.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.fillingPageName = payload;
      }
    },
    [fetchFillingPageName.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.fillingPageName = payload;
      } else {
        state.fillingPageName = initialState.fillingPageName;
      }
    },
    [fetchEvent.fulfilled]: (state, { payload }) => {
      state.currentEvent = payload;
      if (payload) {
        state.fillingPageName = payload.fillingPageName;
      } else {
        state.fillingPageName = initialState.fillingPageName;
      }
    },
    [fetchStand.fulfilled]: (state, action) => {
      state.currentStand = action.payload;
    },
    [fetchAnalytics.fulfilled]: (state, action) => {
      if (!action.payload) return;
      state.analytics = action.payload;
    },
    [fetchStandsModeration.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.stands = payload?.data;
        state.standsPagination = {
          per_page: payload?.per_page,
          last_page: payload?.last_page,
          total: payload?.total,
          from: payload?.from,
        };
      }
    },
    [fetchBannersModeration.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.banners = payload?.data;
        state.bannersPagination = {
          per_page: payload?.per_page,
          last_page: payload?.last_page,
          total: payload?.total,
          from: payload?.from,
        };
      }
    },
    [fetchWebinarsModeration.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.webinars = payload?.data;
        state.webinarsPagination = {
          per_page: payload?.per_page,
          last_page: payload?.last_page,
          total: payload?.total,
          from: payload?.from,
        };
      }
    },
    [fetchBannerModeration.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.moderationBanner = payload;
      }
    },
    [fetchStandModeration.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.moderationStand = payload;
      }
    },
    [fetchWebinarModeration.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.moderationWebinar = payload;
      }
    },
    [fetchModerationStats.fulfilled]: (state, action) => {
      if (action.payload) {
        state.moderationStats = action.payload;
      }
    },
    [fetchStandMedia.fulfilled]: (state, action) => {
      state.media = action.payload;
    },
    [fetchModelMasks.fulfilled]: (state, action) => {
      state.modelMasks = action.payload;
    },
    [fetchEvents.fulfilled]: (state, { payload }) => {
      state.events = payload;
    },
    [fetchDocuments.fulfilled]: (state, { payload }) => {
      state.docs = payload;
    },
    [fetchMoreEvents.fulfilled]: (state, { payload }) => {
      const items = payload?.data;
      const page = payload?.last_page;
      if (page) {
        state.lastPage = page;
      }
      if (items && items.length) {
        items.forEach((item) => {
          state.events.push(item);
        });
      }
    },
    [fetchTariff.fulfilled]: (state, { payload }) => {
      if (!payload) return;

      const { stands, banners, id } = payload;
      state.tariffId = id;
      state.tariffStands = stands;
      state.tariffBanners = banners;
    },
    [fetchStats.fulfilled]: (state, { payload }) => {
      state.stats = {
        actual: payload ? payload.actual : 0,
        pass: payload ? payload?.pass : 0,
        requests: payload ? payload?.requests : 0,
        filling: payload ? payload?.filling : 0,
      };
    },
    [fetchMembers.fulfilled]: (state, { payload }) => {
      state.members = payload ? payload : [];
    },
  },
});

export const {
  setCreateStandOnEvent,
  setCreateRegisterOnEvent,
  setActualEvents,
  setActiveStandIn3D,
  setActiveEventIn3D,
  setStatusOfDocument,
  setCurrentStand,
  setCurrentStandPosition,
  setCurrentStandInPlayer,
  setCurrentEvent,
  setFileToMedia,
  updateMaskColor,
  resetCurrentEvent,
  confirmStandMediaId,
  setCurrentStandLogo,
  setCurrentStandLogoLink,
} = eventSlice.actions;

export default eventSlice.reducer;
