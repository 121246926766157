import classNames from "classnames";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { immediateToast } from "izitoast-react";
import { Sidebar, Header, Button } from "../../components/shared";
import { NavProfile } from "../../components/profile";
import { infoSvg } from "../../assets";
import { getCurrencies } from "../../api";
import { getMemberFullInfo, updateBankInfo } from "../../api/member";
import { errorHandler } from "../../utils/errorHandler";

const BankMember = () => {
  const profile = useSelector((state) => state.user.profile);

  const [currencies, setCurrencies] = useState([]);
  const [valute, setValute] = useState("1");
  const [bankName, setBankName] = useState("");
  const [billNumber, setBillNumber] = useState("");
  const [correspondingNumber, setCorrespondingNumber] = useState("");
  const [bic, setBic] = useState("");

  const isAllow = () => {
    if (
      valute &&
      bankName.length >= 5 &&
      billNumber.length === 20 &&
      correspondingNumber.length === 20 &&
      bic.length === 9
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const { data } = await getCurrencies();
        setCurrencies(data.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    const fetchMemberFullInfo = async () => {
      try {
        const { data } = await getMemberFullInfo();
        const memberInfo = data?.data?.info;

        if (!memberInfo) return;

        setValute((memberInfo.valute && parseInt(memberInfo.valute)) || "0");
        setBankName(memberInfo.bank_name || "");
        setBillNumber(memberInfo.bill_number || "");
        setCorrespondingNumber(memberInfo.corresponding_number || "");
        setBic(memberInfo.bic || "");
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchMemberFullInfo();

    fetchCurrencies();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!isAllow()) {
        return;
      }

      await updateBankInfo({
        bank_name: bankName,
        bic,
        bill_number: billNumber,
        corresponding_number: correspondingNumber,
        valute,
      });

      immediateToast("success", {
        message: "Данные успешно сохранены.",
        position: "topCenter",
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <div className="page-wrapper-documents">
      <div className="app">
        <Sidebar />
        <Header userName={profile.fullName} />
        <div className="page__main">
          <NavProfile />
          <div className="personal">
            <div className="main">
              <h3 className="main__title">Профиль ({profile.role})</h3>
              <h5 className="main__subtitle">Банковская информация</h5>
              <form method="post" className="main__form">
                <div style={{ display: "none" }} className="input input--profile">
                  <label className="input__label">Рассчет в валюте*</label>
                  <select
                    className={classNames("input__main", "input__select", {
                      filled: valute,
                    })}
                    name="value"
                    value={valute}
                    onChange={(e) => setValute(e.target.value)}
                  >
                    <option value="" disabled style={{ display: "none" }}>
                      Выберите валюту
                    </option>
                    {currencies.map((c) => (
                      <option key={c.code} value={c.id}>
                        {c.code}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input input--profile">
                  <label className="input__label">Наименование учреждения банка*</label>
                  <input
                    className={classNames(
                      "input__main",
                      {
                        error: bankName.length > 0 && bankName.length < 5,
                      },
                      { filled: bankName.length >= 5 }
                    )}
                    placeholder="Наименование учреждения банка"
                    type="text"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                    maxLength="190"
                  />
                  {bankName.length > 0 && bankName.length < 5 ? (
                    <p className="input__text">
                      Наименование учреждения банка не может содержать меньше 5 символов
                    </p>
                  ) : null}
                </div>
                <div className="input input--profile">
                  <label className="input__label">Номер расчетного счета*</label>
                  <input
                    className={classNames(
                      "input__main",
                      {
                        error: billNumber.length > 0 && billNumber.length !== 20,
                      },
                      { filled: billNumber.length === 20 }
                    )}
                    placeholder="Основной регистрационный номер"
                    type="text"
                    value={billNumber}
                    onChange={(e) => setBillNumber(e.target.value.replace(/[^\d]/g, ""))}
                    minLength="20"
                    maxLength="20"
                  />
                  {billNumber.length > 0 && billNumber.length !== 20 ? (
                    <p className="input__text">
                      Номер расчетного счета не может содержать меньше 20 символов
                    </p>
                  ) : null}
                </div>
                <div className="input input--create-stand input--profile">
                  <div className="create-stand-content__input-alert">
                    <label>Банковский идентификационный код*</label>
                    <div className="create-stand-content__input-icon">
                      <img src={infoSvg} alt="#" />
                      <div className="create-stand-content__input-promt bic">
                        Уникальный идентификатор банка
                      </div>
                    </div>
                  </div>
                  <input
                    className={classNames(
                      "input__main",
                      {
                        error: bic.length > 0 && bic.length !== 9,
                      },
                      { filled: bic.length === 9 }
                    )}
                    placeholder="Банковский идентификационный номер"
                    type="text"
                    value={bic}
                    onChange={(e) => setBic(e.target.value.replace(/[^\d]/g, ""))}
                    minLength="9"
                    maxLength="9"
                  />
                  {bic.length > 0 && bic.length !== 9 ? (
                    <p className="input__text">
                      Банковский идентификационный номер не может содержать меньше 9
                      символов
                    </p>
                  ) : null}
                </div>
                <div className="input input--create-stand input--profile">
                  <div className="create-stand-content__input-alert">
                    <label>Номер корреспондирующего счета*</label>
                    <div className="create-stand-content__input-icon">
                      <img src={infoSvg} alt="#" />
                      <div className="create-stand-content__input-promt correspondingNumber">
                        Номер корреспондирующего счета(20 цифр)
                      </div>
                    </div>
                  </div>
                  <input
                    className={classNames(
                      "input__main",
                      {
                        error:
                          correspondingNumber.length > 0 &&
                          correspondingNumber.length !== 20,
                      },
                      { filled: correspondingNumber.length === 20 }
                    )}
                    placeholder="Номер корреспондирующего счета"
                    type="text"
                    value={correspondingNumber}
                    onChange={(e) =>
                      setCorrespondingNumber(e.target.value.replace(/[^\d]/g, ""))
                    }
                    minLength="20"
                    maxLength="20"
                  />
                  {correspondingNumber.length > 0 && correspondingNumber.length !== 20 ? (
                    <p className="input__text">
                      Номер корреспондирующего счета не может содержать меньше 20 символов
                    </p>
                  ) : null}
                </div>
                <Button onClick={onSubmit} disabled={!isAllow()} className="button__save">
                  Сохранить
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankMember;
