import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getMemberFullInfo } from "../../api/member";
import { Content, Nav } from "../../components/organizer-panel";
import { pageNames } from "../../components/organizer-panel/CreateEvent/EventSidebar";
import { Layout } from "../../components/shared/Layout";
import { setCreateEventInfo, setResetEventInfo } from "../../redux/appReducer";
import {
  fetchEvent,
  resetCurrentEvent,
  setFillingPageName,
} from "../../redux/eventReducer";
import { prepareDateForApi } from "../../utils/prepareDateForApi";

export const OrganizerPanel = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const currentEvent = useSelector((state) => state.events.currentEvent);
  const fillingPageName = useSelector((state) => state.events.fillingPageName);

  const user = useSelector((state) => state.user.profile);

  useEffect(() => {
    const changeEventFillingPageName = async () => {
      try {
        if (!currentEvent) return;

        if (currentEvent?.status_code === 21 && fillingPageName !== pageNames.DOCUMENTS) {
          dispatch(
            setFillingPageName({
              eventId: id,
              name: pageNames.DOCUMENTS,
            })
          );

          history.push(`/event/${id}/settings/document-flow`);
        }
      } catch (error) {}
    };

    changeEventFillingPageName();
  }, [currentEvent, dispatch, fillingPageName, history, id]);

  useEffect(() => {
    if (!id) {
      dispatch(resetCurrentEvent());
      dispatch(setResetEventInfo());
      return;
    }

    if (id && !currentEvent) {
      dispatch(fetchEvent({ eventId: id }));
    }

    const fetchInitialInfo = async () => {
      try {
        const { data } = await getMemberFullInfo();

        const info = data?.data?.info;

        if (!info) {
          return;
        }

        const inn = info.inn || "";
        const organization = info.organization_name || info.organization || "";
        const registrationNumber = info.registration_number || "";
        const bankName = info.bank_name || "";
        const bic = info.bic || "";
        const billNumber = info.bill_number || "";
        const correspondingNumber = info.corresponding_number || "";
        const kpp = info.kpp || "";
        const justification = info.justification || "";
        const juridicalAdress = info.address || "";
        const fullName = user.fullName || "";

        dispatch(
          setCreateEventInfo({
            eventName: currentEvent.name || "",
            startDate: prepareDateForApi(currentEvent.start_date) || "",
            endDate: prepareDateForApi(currentEvent.end_date) || "",
            tarif: {
              id: currentEvent.tariff_id || "",
            },
            shortDescription: currentEvent.short_description || "",
            questions: currentEvent.info?.questions?.map((q, i) => ({
              number: i + 1,
              text: q,
              edit: false,
            })) || [
              { number: 1, text: "Имя", edit: false },
              { number: 2, text: "Фамилия", edit: false },
              { number: 3, text: "Ваша профессия", edit: false },
            ],
            organization: currentEvent.info?.organization || organization || "",
            inn: currentEvent.info?.inn || inn || "",
            registrationNumber:
              currentEvent.info?.registration_number || registrationNumber || "",
            bic: currentEvent.info?.bic || bic || "",
            bankName: currentEvent.info?.bank_name || bankName || "",
            billNumber: currentEvent.info?.bill_number || billNumber || "",
            correspondingNumber:
              currentEvent.info?.corresponding_number || correspondingNumber || "",
            registrationCode: currentEvent.info?.kpp || kpp || "",
            directorName: currentEvent.info?.head_fio || fullName || "",
            actionBasis: currentEvent.info?.justification || justification || "",
            juridicalAdress: currentEvent.info?.address || juridicalAdress || "",
            contactPhone: currentEvent.info?.phone || user.mobile || "",
            templateId: currentEvent.tmpl?.id,
          })
        );
      } catch (error) {}
    };

    fetchInitialInfo();
  }, [currentEvent, dispatch, id, user.fullName, user.mobile]);

  return (
    <Layout>
      <div className="organizer-panel__main">
        <Nav />
        <Content />
      </div>
    </Layout>
  );
};

export default OrganizerPanel;
