import React from "react";
import { useHistory, useParams } from "react-router-dom";

const ParticipantsTable = ({ members }) => {
  const history = useHistory();

  const { id } = useParams();

  const onMemberClick = (memberId) => {
    history.push(`/event/${id}/settings/participant/${memberId}`);
  };

  return (
    <div style={{width: "100%"}} className="table-responsive">
      <table className="table table-bordered participants-table participants-table--cards">
        <thead className="table__head">
          <tr>
            <th scope="col">Наименование</th>
            <th scope="col">Контактное лицо</th>
            <th scope="col">E-mail</th>
            <th scope="col">Контактный телефон</th>
            <th scope="col">Номер стенда</th>
            <th scope="col">Статус</th>
          </tr>
        </thead>
        <tbody className="table__body">
          {members.map((member, i) => (
            <tr key={i + 1} onClick={() => onMemberClick(member.id)}>
              <td>{member.name || "-"}</td>
              <td>{member.person || "-"}</td>
              <td>{member.email || "-"}</td>
              <td>{member.phone || "-"}</td>
              <td>
                <div className="promotion-item__status promotion-item__status--participants">
                  Стенд №{member.position}
                </div>
              </td>
              <td>{member.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ParticipantsTable;
