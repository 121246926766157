import { immediateToast } from "izitoast-react";
import queryString from "query-string";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createContact } from "../../api/chat";
import { createStandContact } from "../../api/contacts";
import {
  fetchContacts,
  parseContacts,
  setCurrentChat,
  setTab,
} from "../../redux/chatReducer";

const ShareContacts = ({ selectedStandId, isOnContentView }) => {
  const profile = useSelector((state) => state.user.profile);
  const dispatch = useDispatch();

  const history = useHistory();

  const parsedQuery = queryString.parse(history.location.search);

  const isOrganizer = parsedQuery.memberId === "place0";

  const onShareClick = async () => {
    try {
      const { data } = await createContact({ side: "stand", standId: selectedStandId });
      try {
        await createStandContact({
          user: profile,
          standId: selectedStandId,
        });
      } catch (error) {}

      // открытие чата после с участником после обмена контактами
      if (data?.data?.chat) {
        const newContact = parseContacts([data.data.chat])[0];

        dispatch(fetchContacts());
        dispatch(setCurrentChat(newContact));
        dispatch(setTab("messages"));

        history.push(
          history.location.pathname +
            "?" +
            queryString.stringify({ ...parsedQuery, sidebar: "chat" })
        );

        const contactRole = isOrganizer ? "Организатор" : "Участник";

        immediateToast("success", {
          message: `${contactRole} добавлен в контакты`,
          position: "topCenter",
        });
      } else {
        throw new Error("Участник не найден");
      }
    } catch (error) {
      immediateToast("error", {
        message: "Участник не найден.",
        position: "topCenter",
      });
    }
  };

  if (isOnContentView) {
    return null;
  }

  return (
    <button className="share-contacts" onClick={onShareClick}>
      Обменяться контактами
    </button>
  );
};

export default ShareContacts;
