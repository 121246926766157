import React from "react";
import classNames from "classnames";

const MainAbout = ({ noScroll }) => {
  return (
    <div className={classNames("registration__main", { noScroll: noScroll })}>
      <div className="registration__title">
        <h3 className="entry__title entry__title--mod">
          <span>простое решение</span>
          <span>сложных задач</span>
        </h3>
        <p className="entry__subtitle">платформа онлайн-мероприятий</p>
      </div>
    </div>
  );
};

export default MainAbout;
