import React from "react";

const Checker = ({ isActivated, onClick, disabled }) => {
  return (
    <input
      type="checkbox"
      className="checker"
      checked={isActivated}
      onChange={onClick}
      disabled={disabled}
    />
  );
};

export default Checker;
