import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setResetEventInfo } from "../../redux/appReducer";

const Title = () => {
  const role = useSelector((state) => state.user.profile.role);
  const dispatch = useDispatch();

  const onCreateNewEventClick = () => {
    dispatch(setResetEventInfo());
  };

  return (
    <div className="favorite__title">
      <h3>
        {role === "посетитель" && "Мои мероприятия"}
        {role === "участник" && "Мои стенды на мероприятиях"}
        {role === "организатор" && "Мои мероприятия"}
      </h3>
      {role === "организатор" && (
        <Link
          onClick={onCreateNewEventClick}
          to="/organizer-panel/short-desc"
          className="favorite__create-btn red-btn"
        >
          Создать мероприятие
        </Link>
      )}
    </div>
  );
};

export default Title;
