import cn from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../../api";
import { fullScreenBackSvg, fullScreenSvg, standsPlaceholder } from "../../../assets";
import { Loader } from "../../shared";

const PromotionExample = ({
  eventId,
  places,
  roomViewerUrl,
  withHall = true,
  withReception = true,
}) => {
  const frameReception = useRef(null);
  const frameExhibition = useRef(null);

  // количество фреймов в компоненте
  const framesCount = useMemo(() => {
    let count = 2;
    if (!withHall) count -= 1;
    if (!withReception) count -= 1;

    return count;
  }, [withHall, withReception]);

  const profile = useSelector((state) => state.user.profile);
  const token = getToken();
  const dispatch = useDispatch();

  const [wideFrame, setWideFrame] = useState(null);

  const [framesLoaded, setFramesLoaded] = useState(0);

  const onWideFrame = (type) => {
    if (wideFrame === type) {
      setWideFrame(null);
    } else {
      setWideFrame(type);
    }
  };

  // передача токена в iframe
  useEffect(() => {
    // disable part of message handler to avoid banner types generation
    // load banner types with event data
    const msgHandler = (evt) => {
      let message;
      try {
        message = JSON.parse(evt.data);
      } catch (e) {
        message = evt.data;
      }

      if (message.method === "GetAuthTokenCB") {
        // загрузился один из фреймов
        setFramesLoaded((prev) => prev + 1);
      }

      if (message.form === "reception") {
        /* actual for 3d player
        if (message.method === "SendBannersI" && message.banners) {
          dispatch(
            setTypes({
              pattern: "Баннер в холле",
              count: parseInt(message.banners),
              type: "reception",
            })
          );
        }
        */
        if (message.method === "GetAuthTokenCB") {
          return frameReception.current.contentWindow.postMessage(
            JSON.stringify({
              type: "setAuthToken",
              token: token,
              exhibition_id: `event_room:${eventId}`,
              user_id: profile.id,
              is_lobby: true,
            }),
            "*"
          );
        }
      } else if (message.form === "exhibition") {
        /* actual for 3d player
        if (message.method === "SendBannersI" && message.banners) {
          dispatch(
            setTypes({
              pattern: "Баннер в зале",
              count: parseInt(message.banners),
              type: "exhibition",
            })
          );
        } */
        if (message.method === "GetAuthTokenCB") {
          return frameExhibition.current.contentWindow.postMessage(
            JSON.stringify({
              type: "setAuthToken",
              token: token,
              exhibition_id: `event_room:${eventId}`,
              user_id: profile.id,
            }),
            "*"
          );
        }
      }
    };

    window.addEventListener("message", msgHandler);

    return () => {
      window.removeEventListener("message", msgHandler);
    };
  }, [dispatch, frameReception, frameExhibition, token, profile.id, eventId]);

  const hasPlaces = places && places.length > 0;

  return (
    <>
      <div className="example">
        <p className="example__text">Место для рекламы</p>
        <div
          className="example__main"
          style={
            (hasPlaces && places.length < 2) || !eventId ? { minHeight: "auto" } : {}
          }
        >
          <div className="example-nav">
            <div className="example-nav__item"></div>
            <div className="example-nav__item"></div>
            <div className="example-nav__item"></div>
          </div>
          {hasPlaces && (
            <div>
              {places.map((pl, i) => (
                <img style={{ width: "100%" }} key={i} src={pl.src} alt="Example Img" />
              ))}
            </div>
          )}

          {eventId && !hasPlaces && framesLoaded < framesCount && (
            <div className="example__loader">
              <Loader className="example__loader-image" />
            </div>
          )}
          {!eventId && (
            <div className="example__places">
              <div className="example-place__iframe-wrapper">
                <img className="example-place__iframe" src={standsPlaceholder} alt="" />
              </div>
            </div>
          )}
          {roomViewerUrl && eventId && (
            <div
              className={cn("example__places", {
                "example__places--loading": framesLoaded < framesCount,
              })}
            >
              <div
                className={cn("example-place", {
                  "example-place--full": wideFrame === 1,
                  "example-place--hidden": !withReception,
                })}
                key="1"
              >
                <div className="example-place__title">Холл</div>
                <div className="example-place__shadow">
                  <div className="example-place__iframe-wrapper">
                    <div className="example-place__wrap-iframe">
                      <iframe
                        src={roomViewerUrl + "?form=reception"}
                        className="example-place__iframe"
                        frameBorder="0"
                        title={"tariff-ads1"}
                        ref={frameReception}
                        onLoad={(ev) => {
                          frameReception.current.contentWindow.postMessage(
                            { type: "LoadHall", event: eventId },
                            "*"
                          );
                        }}
                      ></iframe>
                      <div
                        className="example-place__wrap-fullscreen"
                        onClick={() => {
                          onWideFrame(1);
                        }}
                      >
                        <img
                          src={fullScreenSvg}
                          alt=""
                          className="example-place__fullscreen"
                        />
                        <img
                          src={fullScreenBackSvg}
                          alt=""
                          className="example-place__fullscreen-back"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={cn("example-place", {
                  "example-place--full": wideFrame === 2,
                  "example-place--hidden": !withHall,
                })}
                key="2"
              >
                <div className="example-place__title">Зал</div>
                <div className="example-place__shadow">
                  <div className="example-place__iframe-wrapper">
                    <div className="example-place__wrap-iframe">
                      <iframe
                        src={roomViewerUrl + "?form=exhibition"}
                        className="example-place__iframe"
                        frameBorder="0"
                        title={"tariff-ads2"}
                        ref={frameExhibition}
                        onLoad={(ev) => {
                          frameExhibition.current.contentWindow.postMessage(
                            { type: "LoadHall", event: eventId },
                            "*"
                          );
                        }}
                      ></iframe>
                      <div
                        className="example-place__wrap-fullscreen"
                        onClick={() => {
                          onWideFrame(2);
                        }}
                      >
                        <img
                          src={fullScreenSvg}
                          alt=""
                          className="example-place__fullscreen"
                        />
                        <img
                          src={fullScreenBackSvg}
                          alt=""
                          className="example-place__fullscreen-back"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PromotionExample;
