import React from "react";
import { useSelector } from "react-redux";
import { noActual } from "../../assets";

export const favoriteEventTypes = {
  actual: "actual",
  pass: "pass",
  requests: "requests",
  filling: "filling",
  rejected: "rejected",
};

const NoActual = ({ eventType }) => {
  const role = useSelector((state) => state.user.profile.role);

  return (
    <div className="no-actual">
      <img src={noActual} alt="No actual icon" className="no-actual__img" />
      <h2 className="no-actual__title">
        {eventType === favoriteEventTypes.actual && "Нет актуальных мероприятий"}
        {eventType === favoriteEventTypes.pass && "Нет прошедших мероприятий"}
        {eventType === favoriteEventTypes.requests &&
          role === "участник" &&
          "Нет стендов в заявках"}
        {eventType === favoriteEventTypes.filling &&
          role === "участник" &&
          "Нет стендов на модерации"}
        {eventType === favoriteEventTypes.rejected &&
          role === "участник" &&
          "Нет отклоненных стендов"}
        {eventType === favoriteEventTypes.requests &&
          role === "организатор" &&
          "Нет мероприятий в заявках"}
        {eventType === favoriteEventTypes.filling &&
          role === "организатор" &&
          "Нет мероприятий на модерации"}
        {eventType === favoriteEventTypes.rejected &&
          role === "организатор" &&
          "Нет отклоненных мероприятий"}
      </h2>
      <p className="no-actual__subtitle">
        {eventType === favoriteEventTypes.actual &&
          "У вас нет мероприятий запланированных на ближайщее время, вы можете найти подходящее в Event календаре"}
        {eventType === favoriteEventTypes.pass &&
          "У вас нет прошедших, вы можете найти подходящее в Event календаре"}
        {eventType === favoriteEventTypes.requests &&
          role === "участник" &&
          "У вас нет созданных стендов"}
        {eventType === favoriteEventTypes.filling &&
          role === "участник" &&
          "У вас нет стендов на модерации"}
        {eventType === favoriteEventTypes.rejected &&
          role === "участник" &&
          "У вас нет отклоненных стендов"}
        {eventType === favoriteEventTypes.requests &&
          role === "организатор" &&
          "У вас нет созданных мероприятий"}
        {eventType === favoriteEventTypes.filling &&
          role === "организатор" &&
          "У вас нет мероприятий на модерации"}
        {eventType === favoriteEventTypes.rejected &&
          role === "организатор" &&
          "У вас нет отклоненных мероприятий"}
      </p>
    </div>
  );
};

export default NoActual;
