import { getToken, instance } from ".";

export const getBanners = (params) => {
  const token = getToken();

  let url = "/api/v1/banners";
  const query = new URLSearchParams(params);

  return instance.get(`${url}?${query.toString()}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getModerationBanners = ({ eventId }) => {
  const token = getToken();

  let url = `/api/v1/event/${eventId}/moderation/banners`;

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getModerationBanner = ({ id }) => {
  const token = getToken();

  let url = `/api/v1/banner/${id}/moderation`;

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getMyBanners = ({ eventId, standId }) => {
  const token = getToken();

  let url = "/api/v1/my-banners/";

  if (standId) {
    url += `stand/${standId}`;
  }

  if (eventId) {
    url += `event/${eventId}`;
  }

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setBanners = ({ eventId, standId, id, image, url, startDate, endDate }) => {
  const token = getToken();

  let link = "/api/v1/banner/set/";

  if (standId) {
    link += `stand/${standId}`;
  }

  if (eventId) {
    link += `event/${eventId}`;
  }

  const formData = new FormData();
  formData.append("id", id);
  formData.append("image", image);
  formData.append("url", url);
  formData.append("start_date", startDate);
  formData.append("end_date", endDate);

  return instance.post(link, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getCacheBanners = () => {
  const token = getToken();

  return instance.get(`/api/v1/cache/get/banners`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setCacheBanners = ({ banners }) => {
  const token = getToken();
  const formData = new URLSearchParams();
  formData.append("data", banners);

  return instance.post(`/api/v1/cache/set/banners`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const changeBannerStatus = ({ id, comment, status }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("status", status);
  formData.append("comment", comment);

  const url = `/api/v1/banner/${id}/moderation`;

  return instance.post(url, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getBannerPlaces = (token = null) => {
  if (!token) {
    token = getToken();
  }

  return instance.get("/api/v1/setting/banner-places", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setBannerImage = ({ image }) => {
  if (!image) {
    return;
  }
  const token = getToken();

  const formData = new FormData();
  formData.append("file", image);

  return instance.post(`/api/v1/cache/file/banner`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const updateBanner = ({ id, image, url, startDate, endDate }) => {
  if (!image && !url && !startDate && !endDate) {
    return;
  }

  const formData = new FormData();
  if (url) formData.append("url", url);
  if (startDate) formData.append("start_date", startDate);
  if (endDate) formData.append("end_date", endDate);
  if (image) formData.append("image", image);

  const token = getToken();
  return instance.post(`/api/v1/banner/${id}/update`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const deleteBanner = ({ id }) => {
  const token = getToken();
  return instance.delete(`/api/v1/banner/${id}`, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getOrderBanners = ({ eventId }) => {
  const token = getToken();

  return instance.get(`/api/v1/event/${eventId}/orderbanners`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};
