import React from "react";
import { EntryHeader, EntryLogo, MainAbout } from "../../components/entry";
import { Form } from "../../components/entry/registration-start";

const RegistrationStart = () => {
  return (
    <div className="registration">
      <EntryLogo />
      <MainAbout />
      <main className="registration-form">
        <EntryHeader />
        <div className="registration-form__main">
          <h3 className="registration-form__title">Регистрация JUSTEVENTS</h3>
          <Form />
        </div>
        {/* <EntryFooter /> */}
      </main>
    </div>
  );
};

export default RegistrationStart;
