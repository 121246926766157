import { immediateToast } from "izitoast-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { changeBannerStatus } from "../../api/banners";
import { useConfirmPopup } from "../../hooks/useConfirmPopup";
import { errorHandler } from "../../utils/errorHandler";
import { ConfirmPopup, ViewPopup } from "../shared";
import { useSelector } from "react-redux";
import moment from "moment";
import { cloneObject } from "../../utils";
import { useDispatch } from "react-redux";
import { fetchBannerModeration } from "../../redux/eventReducer";

const ModerationBanner = ({ afterModeration = () => {} }) => {
  const { mId: bannerId } = useParams();
  const { popupInfo, setPopupInfo, closePopup } = useConfirmPopup();
  const [viewPopup, setViewPopup] = useState(null);
  const [banner, setBanner] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const moderationBanner = useSelector((state) => state.events.moderationBanner);

  useEffect(() => {
    const item = cloneObject(moderationBanner);
    item.created_at = moment(item.created_at);
    item.start_date = moment(item.start_date);
    item.end_date = moment(item.end_date);
    if (item.media && item.media.length) {
      for (let k in item.media) {
        item.media[k].created_at = moment(item.media[k].created_at);
      }
    }
    setBanner(item);
  }, [moderationBanner]);

  useEffect(() => {
    dispatch(fetchBannerModeration({ id: bannerId }));
  }, [bannerId, dispatch]);

  let comment = "";
  const onChangeTextarea = (e) => {
    comment = e.target.value;
  };

  const onDecline = () => {
    setPopupInfo({
      title: "Причина отклонения",
      customConfirmText: "Подтвердить",
      isOpened: true,
      additional: (
        <div>
          <textarea
            id="cancelation-comment"
            onChange={onChangeTextarea}
            required
            placeholder="Напишите причину отклонения заявки"
            className="moderation__textarea"
            name="text"
          ></textarea>
        </div>
      ),
      onConfirmClick() {
        if (comment) {
          (async () => {
            try {
              setIsLoading(true);
              const { data } = await changeBannerStatus({
                id: bannerId,
                comment,
                status: "decline",
              });
              setIsLoading(false);
              if (data.message) {
                immediateToast("warning", {
                  message: data.message,
                  position: "topCenter",
                });
              }
              afterModeration();
            } catch (e) {
              setIsLoading(false);
              errorHandler(e);
            } finally {
              closePopup();
            }
          })();
        } else {
          immediateToast("error", {
            message: "Вы не указали причину",
            position: "topCenter",
          });
        }
      },
      onCancelClick() {
        closePopup();
      },
    });
  };

  const onApprove = async () => {
    setPopupInfo({
      title: "Подтверждение",
      customConfirmText: "Подтвердить",
      isOpened: true,
      additional: (
        <div>
          <p>Вы действительно подтверждаете баннер?</p>
        </div>
      ),
      onConfirmClick() {
        (async () => {
          try {
            setIsLoading(true);
            const { data } = await changeBannerStatus({
              id: bannerId,
              comment,
              status: "approve",
            });
            setIsLoading(false);
            if (data.message) {
              immediateToast("success", {
                message: data.message,
                position: "topCenter",
              });
            }

            afterModeration();
          } catch (e) {
            setIsLoading(false);
            errorHandler(e);
          } finally {
            closePopup();
          }
        })();
      },
      onCancelClick() {
        closePopup();
      },
    });
  };

  const onCloseClick = () => {
    closePopup();
    setViewPopup(null);
  };

  return (
    <div>
      {popupInfo.isOpened && (
        <ConfirmPopup
          isLoading={isLoading}
          popupInfo={popupInfo}
          onCloseClick={onCloseClick}
        />
      )}
      {viewPopup && (
        <ViewPopup
          link={viewPopup.link}
          type={viewPopup.type}
          onCloseClick={onCloseClick}
        />
      )}
      <div className="item-info__item item-info__item--documents">
        <div className="item-info__header item-info__header--participants">
          <div className="participants-cards__info participants-cards__info--documents">
            <h5 className="item-info__name item-info__name--participants">
              Основная информация
            </h5>
          </div>
          <div className="promotion-item__status">Баннер #{bannerId}</div>
        </div>
        <div className="item-info__main item-info__main--documents">
          {banner && (
            <div className="participants-cards__part participants-cards__part--small">
              <div className="item-info__data">
                <div className="item-info__point point">Изображение</div>
                <div className="item-info__response response">
                  <img width="400" src={banner.image} alt="" />
                </div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">Название</div>
                <div className="item-info__response response">{banner.name}</div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">Ссылка на сайт</div>
                <div className="item-info__response response">
                  {banner.url ? (
                    <a href={banner.url} target="_blank" rel="noreferrer">
                      {banner.url}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">Кол-во кликов</div>
                <div className="item-info__response response">{banner.clicks || "0"}</div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">Цена за день показа</div>
                <div className="item-info__response response">
                  {banner?.price || "0"} руб.
                </div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">Полная сумма</div>
                <div className="item-info__response response">
                  {banner?.amount || "0"} руб.
                </div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">Дата начала показа</div>
                <div className="item-info__response response">
                  {banner?.start_date.format("DD MMM YYYY HH:mm")}
                </div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">Дата конца показа</div>
                <div className="item-info__response response">
                  {banner?.end_date.format("DD MMM YYYY HH:mm")}
                </div>
              </div>
              <div className="item-info__data">
                <div className="item-info__point point">Создано</div>
                <div className="item-info__response response">
                  {banner?.created_at.format("DD MMM YYYY HH:mm")}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="moderation__actions">
        <div className="moderation__btn-group">
          <button
            onClick={onApprove}
            style={{ marginRight: 15 }}
            className="btn btn-lg btn-success moderation__btn"
          >
            Принять
          </button>
          <button onClick={onDecline} className="btn btn-lg btn-danger moderation__btn">
            Отклонить
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModerationBanner;
