import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyEvents } from "../../api";
import { EventItem } from "../../components/events";
import { FavoritePopup, NoActual } from "../../components/favorite";
import { favoriteEventTypes } from "../../components/favorite/NoActual";
import PageWrapper from "../../components/favorite/PageWrapper";
import { useFavoritePopup } from "../../hooks/useFavoritePopup";
import { convertMonth } from "../../utils/convertMonth";
import { prepareEventList } from "../../utils/prepareEventList";
import useAbortController from "../../utils/useAbortController";

const FavoriteRequests = () => {
  const [drafts, setDrafts] = useState([]);
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.profile.role);
  const abortController = useAbortController(false);

  // массив объектов {месяц, год}
  const [eventsDates, setEventsDates] = useState([]);

  const { closePopup, popupInfo, setPopupInfo } = useFavoritePopup();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getEvents = async () => {
      try {
        setIsLoading(true);
        const { data } = await getMyEvents({ date_type: "requests" });
        if(abortController.signal.aborted){
          return;
        }

        const eventList = data.data.data;
        setDrafts(prepareEventList(eventList, "request"));

        const dates = data.data.data.map((eve) => {
          const startDate = moment(eve.start_date, "YYYY-MM-DD HH:mm:ss");
          return {
            date: parseInt(startDate.format("YYYYMM")),
            month: startDate.month(),
            year: startDate.year(),
          };
        });

        const filteredDates = [];
        dates.map((d) => {
          if (filteredDates.some((date) => date.date === d.date)) {
            return d;
          }

          filteredDates.push(d);
          return d;
        });

        // сортировка месяцев по убыванию
        setEventsDates(filteredDates.sort((a, b) => b.date - a.date));
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    getEvents();
  }, [dispatch,abortController]);

  const onEventClick = async ({ id }) => {
    let selectedEvent = drafts.find((e) => e.id === id);

    if (selectedEvent) {
      selectedEvent = { ...selectedEvent, type: "request" };
    }

    if (role === "организатор") {
      // действия не доступны
      if (
        selectedEvent.statusCode === 10 ||
        selectedEvent.statusCode === 41 ||
        selectedEvent.statusCode === 20
      ) {
        return;
      }

      return setPopupInfo({
        isOpened: true,
        selectedEvent,
      });
    }

    if (role === "участник") {
      // действия не доступны
      if (
        selectedEvent.standStatus === "на модерации" ||
        selectedEvent.standStatus === "ожидает оплаты" ||
        selectedEvent.standStatus === "стенд на модерации"
      ) {
        return;
      }

      return setPopupInfo({
        isOpened: true,
        selectedEvent,
      });
    }
  };

  return (
    <PageWrapper isLoading={isLoading}>
      {popupInfo.isOpened && (
        <FavoritePopup popupInfo={popupInfo} closePopup={closePopup} />
      )}

      {(drafts && drafts.length > 0 && (
        <div className="event-calendar__content">
          {eventsDates.map((item, index) => (
            <React.Fragment key={index}>
              <h3 className="event-item__month event-item__month--actual">
                {convertMonth(item.month) + " "}
                {item.year}
              </h3>
              {drafts.map((evt) => {
                const date = parseInt(moment(evt.startDate).format("YYYYMM"));
                if (date === item.date) {
                  return (
                    <EventItem key={evt.id} onEventClick={onEventClick} eventInfo={evt} />
                  );
                }
                return null;
              })}
            </React.Fragment>
          ))}
        </div>
      )) || <NoActual eventType={favoriteEventTypes.requests} />}
    </PageWrapper>
  );
};

export default FavoriteRequests;
