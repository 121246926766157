import classNames from "classnames";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { changeCurrentPassword } from "../../api";
import { NavProfile } from "../../components/profile";
import { Button, Header, Sidebar, EventConfirmPopup } from "../../components/shared";
import { errorMessage } from "../../utils/errorMessage";
import { successMessage } from "../../utils/successMessage";

const Password = () => {
  const profile = useSelector((state) => state.user.profile);
  const [popupOpened, setPopupOpened] = React.useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function isAllowSend() {
    if (
      currentPassword.length >= 5 &&
      newPassword.split("").length >= 5 &&
      confirmPassword === newPassword
    ) {
      return true;
    } else {
      return false;
    }
  }

  function checkPassword(password) {
    if (password.length < 5) {
      return true;
    }
  }

  const onChanges = async (evt) => {
    evt.preventDefault();
    try {
      if (newPassword === currentPassword) {
        return errorMessage("Новый пароль совпадает со старым");
      }

      if (
        currentPassword &&
        newPassword.split("").length >= 5 &&
        newPassword === confirmPassword
      ) {
        await changeCurrentPassword({
          old_password: currentPassword,
          password: newPassword,
          password_confirmation: confirmPassword,
        });

        successMessage("Пароль успешно обновлен");

        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      }
    } catch (error) {
      if (error.response?.data?.data?.data?.old_password) {
        errorMessage("Неверный актуальный пароль");
      } else {
        errorMessage("Произошла ошибка");
      }
    }
  };

  return (
    <div className="page-wrapper-password">
      <div className="app">
        {popupOpened && <EventConfirmPopup closePopup={() => setPopupOpened(false)} />}
        <Sidebar />
        <Header />
        <div className="page__main">
          <NavProfile />
          <div className="personal">
            <div className="main main-password">
              <h3 className="main__title">Профиль ({profile.role})</h3>
              <h5 className="main__subtitle">Пароль</h5>
              <form className="main__form">
                <div className="input input--profile">
                  <label className="input__label" htmlFor="currentPassword">
                    Актуальный пароль*
                  </label>
                  <input
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    className={classNames(
                      "input__main",
                      {
                        error: currentPassword && checkPassword(currentPassword),
                      },
                      {
                        filled: currentPassword && currentPassword.split("").length >= 6,
                      }
                    )}
                    placeholder="Актуальный пароль"
                    type="password"
                    id="currentPassword"
                  />
                  {currentPassword && checkPassword(currentPassword) ? (
                    <p className="input__text">
                      Пароль должен содержать не меньше 5 символов*
                    </p>
                  ) : null}
                </div>
                <div className="input input--profile">
                  <label className="input__label" htmlFor="newPassword">
                    Новый пароль*
                  </label>
                  <input
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className={classNames(
                      "input__main",
                      {
                        error: newPassword && checkPassword(newPassword),
                      },
                      {
                        filled: newPassword && newPassword.split("").length >= 6,
                      }
                    )}
                    placeholder="Новый пароль"
                    type="password"
                    id="newPassword"
                    maxLength={191}
                  />
                  {newPassword && checkPassword(newPassword) ? (
                    <p className="input__text">
                      Пароль должен содержать не меньше 5 символов*
                    </p>
                  ) : null}
                </div>
                <div className="input input--profile">
                  <label className="input__label" htmlFor="confirmPassword">
                    Повторить новый пароль*
                  </label>
                  <input
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className={classNames(
                      "input__main",
                      {
                        error: newPassword !== confirmPassword,
                      },
                      { filled: newPassword !== confirmPassword }
                    )}
                    placeholder="Повторить новый пароль"
                    type="password"
                    id="confirmPassword"
                    maxLength={191}
                  />
                  {newPassword !== confirmPassword ? (
                    <p className="input__text">Пароли не совпадают</p>
                  ) : null}
                </div>
                <Button
                  onClick={onChanges}
                  disabled={!isAllowSend()}
                  className="button__save"
                >
                  Изменить пароль
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Password;
