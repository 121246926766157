/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import classNames from "classnames";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { forgotPassword } from "../../api";
import { setResetEmail } from "../../redux/appReducer";
import {
  EntryFooter,
  EntryHeader,
  EntryLogo,
  MainAbout,
} from "../../components/entry";
import { Button } from "../../components/shared";
import {errorHandler} from "../../utils/errorHandler";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = React.useState("");
  const [trueEmail, setTrueEmail] = React.useState(true);

  const onChangeEmail = (e) => {
    setEmail(e);
    setTrueEmail(true);
  };

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const onSubmit = async (evt) => {
    evt.preventDefault();
    try {
      await forgotPassword(email);
      dispatch(setResetEmail(email));
      history.push("/entry/reset/confirm");
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <div className="registration registration--login">
      <EntryLogo />
      <MainAbout noScroll />
      <main className="registration-form">
        <EntryHeader login />
        <div className="registration-form__main">
          <h3 className="registration-form__title">Сброс пароля JUSTEVENTS</h3>
          <form className="registration-form__inputs">
            <div className="input">
              <label className="input__label" htmlFor="currentPassword">
                Введите E-mail для сброса пароля
              </label>
              <input
                value={email}
                onChange={(e) => onChangeEmail(e.target.value)}
                className={classNames(
                  "input__main",
                  { error: email && !validateEmail(email) },
                  { filled: validateEmail(email) }
                )}
                placeholder="Введите e-mail"
                type="text"
                id="email"
              />
              {!trueEmail && <p className="input__text">Нет такого email</p>}
              {email && !validateEmail(email) ? (
                <p className="input__text">Неверный формат электронной почты</p>
              ) : null}
              <Link to="/entry/login" className="input__password">
                Вспомнили пароль?
              </Link>
            </div>
            <Button
              onClick={onSubmit}
              disabled={!validateEmail(email)}
              className="registration-reset__link"
            >
              Сбросить пароль
            </Button>
          </form>
        </div>
        {/* <EntryFooter login /> */}
      </main>
    </div>
  );
};

export default ResetPassword;
