import moment from "moment";

export const prepareOrganizerProgram = (
  programData,
  settings = { withWebinarSignature: false }
) => {
  const { withWebinarSignature } = settings;

  const programDays = [];

  const allEvents = [
    ...programData.main.map((ev) => ({ ...ev, isWebinar: false })),
    ...programData.webinars.map((ev) => ({ ...ev, isWebinar: true })),
  ];

  allEvents.map((ev) => {
    const eventDay = moment(ev.date_start).format("YYYY-MM-DD");

    if (programDays.some((d) => d === eventDay)) {
      return ev;
    }

    programDays.push(eventDay);

    return ev;
  });

  if (programDays.length === 0) {
    return [];
  }

  return programDays
    .sort((a, b) => new Date(a) - new Date(b))
    .map((day) => {
      const dayEvents = allEvents
        .filter((ev) => moment(ev.date_start).format("YYYY-MM-DD") === day)
        .sort((a, b) => new Date(a.date_start) - new Date(b.date_start));

      return {
        date: new Date(day).toISOString(),
        events: dayEvents.map((ev) => {
          const description = withWebinarSignature
            ? ev.name + (ev.isWebinar ? ". Вебинар" : "")
            : ev.name;

          return {
            timeStart: ev.date_start,
            timeEnd: ev.date_end,
            description,
            isWebinar: ev.isWebinar,
          };
        }),
      };
    });
};
