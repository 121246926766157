import classNames from "classnames";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getEventInfo, registerOnEvent } from "../../api";
import {
  Button,
  EventConfirmPopup,
  EventNotificationPopup,
} from "../../components/shared";
import { Header } from "../../components/vr-event";
import { immediateToast } from "izitoast-react";
import { errorHandler } from "../../utils/errorHandler";

const RegistrationEvent = () => {
  moment.locale("ru");

  const profile = useSelector((state) => state.user.profile);

  const [eventQuestions, setEventQuestions] = useState([]);
  const [eventName, setEventName] = useState("");
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");
  const [eventType, setEventType] = useState("");
  const [eventLogo, setEventLogo] = useState("");

  const history = useHistory();
  const parsedQuery = queryString.parse(history.location.search);

  const [goToEventPopup, setGoToEventPopup] = useState(false);
  const [selectedEventTitle, setSelectedEventTitle] = useState("");
  const [registeredNotificationPopup, setRegisteredNotificationPopup] = useState(false);

  const eventId = parsedQuery.id;
  if (!eventId) {
    immediateToast("error", {
      message: "Мероприятие не найдено.",
      position: "topCenter",
    });
  }

  const onSubmit = async (evt) => {
    evt.preventDefault();
    try {
      const { data } = await registerOnEvent({
        info: eventQuestions,
        event_id: eventId,
      });

      // уже началось
      if (new Date() > new Date(data.data.start_date)) {
        setGoToEventPopup(true);
      } else {
        // ещё не началось
        setRegisteredNotificationPopup(true);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    const fetchEventInfo = async () => {
      try {
        const { data } = await getEventInfo({ event_id: eventId });

        const itemData = data.data?.item;
        if (!itemData) {
          return;
        }

        setEventName(itemData.name);
        setSelectedEventTitle(itemData.name);
        setEventStartDate(new Date(itemData.start_date));
        setEventEndDate(new Date(itemData.end_date));
        setEventLogo(itemData.logo);

        if (itemData.info?.questions) {
          let quests = itemData.info?.questions;
          if (quests) {
            let items = [];
            quests.forEach((item) => {
              items.push({
                key: item.replace(/\*/, ""),
                value: "",
                required: item.indexOf("*") > -1,
              });
            });
            setEventQuestions(items);
          }
        }
        if (itemData.type) {
          setEventType(itemData.type.name);
        }
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchEventInfo();
  }, [eventId]);

  useEffect(() => {
    if (eventQuestions?.length > 0 && profile?.fullName) {
      const splitedFullname = profile.fullName.split(" ");
      setValue(splitedFullname[0], "Имя");
      setValue(splitedFullname[1], "Фамилия");
      setValue(profile?.position, "Ваша профессия");
    }
  }, [eventQuestions?.length, profile?.fullName, profile?.position]);

  let mStartDate = eventStartDate ? moment(eventStartDate).format("D MMMM") : "";
  let mEndDate = eventEndDate ? moment(eventEndDate).format("D MMMM") : "";

  const setValue = (str, key) => {
    setEventQuestions((prev) =>
      prev.map((q) => (q.key === key ? { ...q, value: str || "" } : q))
    );
  };

  return (
    <div className="events-registration">
      {goToEventPopup && (
        <EventConfirmPopup
          eventLink={
            profile.role === "участник"
              ? `/events/create/${eventId}?currentStep=1`
              : `/events/${eventId}?location=lobby`
          }
          closePopup={() => setGoToEventPopup(false)}
          eventTitle={selectedEventTitle}
          afterClose={() => history.push("/favorite/actual")}
        />
      )}
      {registeredNotificationPopup && (
        <EventNotificationPopup
          popupText="Вы успешно зарегистрированы на мероприятие. Ожидайте его начала"
          closePopup={() => setRegisteredNotificationPopup(false)}
          afterClose={() => history.push("/favorite/actual")}
        />
      )}
      <Header title={eventName} />
      <div className="events-registration__content">
        <div className="events-registration__preview">
          <div className="events-registration__preview-main">
            <div className="events-registration__logo">
              <img src={eventLogo} alt="" />
            </div>
            <div className="events-registration__date">
              {mStartDate && mEndDate ? `${mStartDate} - ${mEndDate}` : "Загрузка..."}
            </div>
            <h5 className="events-registration__title-preview">{eventName}</h5>
            <p className="events-registration__subtitle-preview">{eventType}</p>
          </div>
        </div>
        <div className="events-registration__main">
          <form className="main__form main__form--registration-event">
            <h3 className="events-registration__title">Регистрация на мероприятие</h3>
            {eventQuestions?.length > 0 &&
              eventQuestions.map((quest, k) => {
                return (
                  <div key={k} className="input">
                    <input
                      onChange={(e) => setValue(e.target.value, quest.key)}
                      className={classNames("input__main")}
                      value={quest.value}
                      placeholder={quest.key}
                      type="text"
                      required={quest.required}
                    />
                  </div>
                );
              })}
            <Button onClick={onSubmit} className="events-registration__link">
              Зарегистрироваться
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegistrationEvent;
