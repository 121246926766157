import React from "react";
import { NavLink, useParams } from "react-router-dom";

const ParticipantsNav = () => {
  const { id } = useParams();

  return (
    <nav className="participants-nav">
      <div className="favorite-nav favorite-nav--notification">
        <NavLink to={`/event/${id}/settings/tariffs/stands`} activeClassName="active">
          <div className="favorite-nav__item">
            <p>Тарифы участия</p>
          </div>
        </NavLink>
        <NavLink to={`/event/${id}/settings/tariffs/ads`} activeClassName="active">
          <div className="favorite-nav__item">
            <p>Тарифы рекламы</p>
          </div>
        </NavLink>
      </div>
    </nav>
  );
};

export default ParticipantsNav;
