import { createSlice } from "@reduxjs/toolkit";
import { authManager, userManager } from "../utils/managers";

const initialState = {
  accounts: [],
  settings: {
    ymCounter: "",
    playerUrl: "",
    roomViewerUrl: "",
  },
  profile: {
    id: 0,
    fullName: "",
    birth: "",
    country: "",
    avatar: "",
    role: "",
    position: "",
    mobile: "",
  },
  events: [],
  registration: {
    email: "",
  },
  reset: {
    email: "",
  },
  memberRegistration: {
    entity: "",
    organization: "",
    INN: "",
    registrationNumber: "",
    phone: "",
    valute: "",
    bank: "",
    billNumber: "",
    bankNumber: "",
    correspondingNumber: "",
  },
  organizerRegistration: {
    entity: "",
    organization: "",
    INN: "",
    registrationNumber: "",
    phone: "",
    valute: "",
    bank: "",
    billNumber: "",
    bankNumber: "",
    correspondingNumber: "",
  },
  stand: {
    notification: {
      visitors: {
        invite: false,
        webinar: false,
        mailing: false,
        reminder: false,
      },
      contacts: {
        invite: false,
        succesfulRegistration: false,
        mailing: false,
        reminder: false,
        stock: false,
        feedback: false,
      },
    },
  },
  event: {
    notification: {
      participants: {
        invite: false,
        create: false,
        mailing: false,
        reminder: false,
      },
      visitors: {},
      earlier: {},
    },
  },
  demo: {
    color: {
      text: "",
      title: "",
      background: "",
    },
    header: {
      title: "",
      subtitle: "",
      background: "",
      contactInfo: {
        mobile: "",
        email: "",
      },
      logo: "",
      startDate: "",
      endDate: "",
      button: {
        name: "",
        link: "",
      },
      visibleMobile: false,
    },
    main: {
      about: "",
      advantages: [],
      products: [],
      participants: [
        // { name: "", description: "", image: "" },
        // { name: "", description: "", image: "" },
      ],
      program: [
        // {
        //   date: "",
        //   events: [
        //     { timeStart: "", timeEnd: "", description: "" },
        //     { timeStart: "", timeEnd: "", description: "" },
        //   ],
        // },
      ],
      speakers: [
        // { fullName: "", pos: "", image: "" },
        // { fullName: "", pos: "", image: "" },
      ],
      video: "",
      logo: "",
      pdf: "",
    },
    footer: {
      text: "",
    },
    isDemo: false,
    isCreated: false,
    eventType: "default",
  },
  createStandInfo: {
    standId: "",
    companyName: "",
    branch: "",
    logo: "",
    templateId: "",
    shortDescription: "",
    organization: "",

    inn: "",
    registrationNumber: "",
    bic: "",
    bankName: "",
    billNumber: "",
    correspondingNumber: "",

    registrationCode: "",
    directorName: "",
    actionBasis: "",
    juridicalAdress: "",
    contactPhone: "",

    tarif: "",
    standPositionId: "",
    withVAT: false,
    errors: {},
  },
  createEventInfo: {
    fillingPageIndex: 0,
    eventId: "",
    eventName: "",
    type: "",
    logo: "",
    templateId: "",
    shortDescription: "",
    startDate: "",
    endDate: "",
    tarif: "",
    access: 10,
    maxQuests: "",
    maxParticipants: "",
    questions: [
      { number: 1, text: "Имя", edit: false },
      { number: 2, text: "Фамилия", edit: false },
      { number: 3, text: "Ваша профессия", edit: false },
    ],

    organization: "",
    inn: "",
    registrationNumber: "",
    bic: "",
    bankName: "",
    billNumber: "",
    correspondingNumber: "",
    registrationCode: "",
    directorName: "",
    actionBasis: "",
    juridicalAdress: "",
    contactPhone: "",

    tariff: {},
    withVAT: false,
    errors: {},
  },
  currentWebinar: {
    id: "",
    title: "",
    startDay: "",
    startTime: "",
    endTime: "",
    description: "",
  },
};

export const profileSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setProfileInfo(state, action) {
      const user = userManager().prepare(action.payload);

      state.profile = user;
      authManager().setUser(user);
    },
    setAccount(state, action) {
      let user = action.payload;
      let acc = state.accounts.find((acc) => acc.id === user.id);
      if (!acc) {
        user = userManager().prepare(user);
        state.accounts.push(user);
      }
    },
    setNewAvatar(state, action) {
      state.profile.avatar = action.payload;
    },
    resetProfileInfo(state) {
      state.profile = initialState.profile;
    },
    resetAccounts(state) {
      state.accounts = initialState.accounts;
    },
    setEvents(state, action) {
      state.events = action.payload;
    },
    setRegistrationEmail(state, action) {
      state.registration.email = action.payload;
    },
    setResetEmail(state, action) {
      state.reset.email = action.payload;
    },
    setDemoTextColor(state, action) {
      state.demo.color.text = action.payload;
    },
    setDemoTitleColor(state, action) {
      state.demo.color.title = action.payload;
    },
    setDemoBackgroundColor(state, action) {
      state.demo.color.background = action.payload;
    },
    setClearDemoSite(state) {
      state.demo = initialState.demo;
    },
    setNewDemoSite(state, action) {
      state.demo = action.payload;
    },
    setMemberRegistration(state, action) {
      state.memberRegistration = {
        ...state.memberRegistration,
        ...action.payload,
      };
    },
    setOrganizerRegistration(state, action) {
      state.organizerRegistration = {
        ...state.organizerRegistration,
        ...action.payload,
      };
    },
    setMemberOrganization(state, action) {
      state.memberRegistration = {
        ...state.memberRegistration,
        organization: action.payload,
      };
    },
    setNotificationVisitorsInvite(state) {
      state.stand.notification.visitors.invite =
        !state.stand.notification.visitors.invite;
    },
    setNotificationVisitorsWebinar(state) {
      state.stand.notification.visitors.webinar =
        !state.stand.notification.visitors.webinar;
    },
    setNotificationVisitorsMailing(state) {
      state.stand.notification.visitors.mailing =
        !state.stand.notification.visitors.mailing;
    },
    setNotificationVisitorsReminder(state) {
      state.stand.notification.visitors.reminder =
        !state.stand.notification.visitors.reminder;
    },
    setNotificationContactsInvite(state) {
      state.stand.notification.contacts.invite =
        !state.stand.notification.contacts.invite;
    },
    setNotificationContactsSuccesfulRegistration(state) {
      state.stand.notification.contacts.succesfulRegistration =
        !state.stand.notification.contacts.succesfulRegistration;
    },
    setNotificationContactsMailing(state) {
      state.stand.notification.contacts.mailing =
        !state.stand.notification.contacts.mailing;
    },
    setNotificationContactsReminder(state) {
      state.stand.notification.contacts.reminder =
        !state.stand.notification.contacts.reminder;
    },
    setNotificationContactsStock(state) {
      state.stand.notification.contacts.stock = !state.stand.notification.contacts.stock;
    },
    setNotificitaionOrgPartInvite(state) {
      state.event.notification.participants.invite =
        !state.event.notification.participants.invite;
    },
    setNotificitaionOrgPartCreate(state) {
      state.event.notification.participants.create =
        !state.event.notification.participants.create;
    },
    setNotificitaionOrgPartMailing(state) {
      state.event.notification.participants.mailing =
        !state.event.notification.participants.mailing;
    },
    setNotificitaionOrgPartReminder(state) {
      state.event.notification.participants.reminder =
        !state.event.notification.participants.reminder;
    },
    setNotificationContactsFeedback(state) {
      state.stand.notification.contacts.feedback =
        !state.stand.notification.contacts.feedback;
    },
    setCreateStandInfo(state, { payload }) {
      state.createStandInfo = {
        ...state.createStandInfo,
        ...payload,
      };
    },
    setCreateStandInfoErrors(state, { payload }) {
      state.createStandInfo.errors = {
        ...state.createStandInfo.errors,
        ...payload,
      };
    },
    clearCreateStantInfoErrors(state) {
      state.createStandInfo.errors = {};
    },
    setCreateEventInfo(state, { payload }) {
      state.createEventInfo = {
        ...state.createEventInfo,
        ...payload,
      };
    },
    changeCreateEventFillingPage(state, { payload }) {
      if (payload > state.createEventInfo.fillingPageIndex) {
        state.createEventInfo.fillingPageIndex = payload;
      }
    },
    setResetEventInfo(state) {
      state.createEventInfo = initialState.createEventInfo;
    },
    setResetStandInfo(state) {
      state.createStandInfo = initialState.createStandInfo;
    },
    setEditQuestion(state, action) {
      state.createEventInfo.questions = state.createEventInfo.questions
        .map((question) => (question.edit ? { ...question, edit: false } : question))
        .map((question) =>
          question.number === action.payload
            ? { ...question, edit: !question.edit }
            : question
        );
    },
    setSaveQuestion(state, action) {
      state.createEventInfo.questions = state.createEventInfo.questions.map((question) =>
        question.number === action.payload ? { ...question, edit: false } : question
      );
    },
    setChangeQuestion(state, { payload }) {
      state.createEventInfo.questions = state.createEventInfo.questions.map((question) =>
        question.number === payload.id ? { ...question, text: payload.text } : question
      );
    },
    setDeleteQuestion(state, action) {
      state.createEventInfo.questions = state.createEventInfo.questions.filter(
        (question) => question.number !== action.payload
      );
    },
    setNewQuestion(state, action) {
      state.createEventInfo.questions.push(action.payload);
    },
    setProgram(state, action) {
      state.demo.main.program = action.payload;
    },
    setDateProgramInfo(state, { payload }) {
      state.demo.main.program = state.demo.main.program.map((day, index) =>
        payload.programId === index
          ? {
              ...day,
              date: payload.value,
            }
          : day
      );
    },
    setTimeEventProgramInfo(state, { payload }) {
      state.demo.main.program = state.demo.main.program.map((day, index) =>
        payload.programId === index
          ? {
              ...day,
              events: day.events.map((event, index) =>
                payload.eventId === index ? { ...event, time: payload.value } : event
              ),
            }
          : day
      );
    },
    setDescriptionEventProgramInfo(state, { payload }) {
      state.demo.main.program = state.demo.main.program.map((day, index) =>
        payload.programId === index
          ? {
              ...day,
              events: day.events.map((event, index) =>
                payload.eventId === index
                  ? { ...event, description: payload.value }
                  : event
              ),
            }
          : day
      );
    },
    setNewProgramDay(state) {
      state.demo.main.program.push({
        date: "",
        events: [
          { time: "", description: "", number: 1 },
          { time: "", description: "", number: 2 },
        ],
        id: state.demo.main.program.length,
      });
    },
    setNewProgramEvent(state, { payload }) {
      state.demo.main.program.map((day, index) =>
        payload.programId === index
          ? day.events.push({ time: "", description: "", number: day.events.length + 1 })
          : day
      );
    },
    setDeleteProgramDay(state, { payload }) {
      state.demo.main.program = state.demo.main.program.filter(
        (day) => day.id !== payload.programId
      );
    },
    setDeleteProgramEvent(state, { payload }) {
      state.demo.main.program = state.demo.main.program.map((day) =>
        day.id === payload.programId
          ? {
              ...day,
              events: day.events.filter((event) => event.number !== payload.eventId),
            }
          : day
      );
    },

    setSettings(state, { payload }) {
      state.settings = payload;
    },
  },
});

export const {
  changeCreateEventFillingPage,
  setProfileInfo,
  setSettings,
  setAccount,
  setNewAvatar,
  setRegistrationEmail,
  setResetEmail,
  resetProfileInfo,
  setDemoTextColor,
  setDemoTitleColor,
  setDemoBackgroundColor,
  setNewDemoSite,
  setMemberRegistration,
  setOrganizerRegistration,
  setMemberOrganization,
  setNotificationVisitorsInvite,
  setNotificationVisitorsWebinar,
  setNotificationVisitorsMailing,
  setNotificationVisitorsReminder,
  setNotificationContactsInvite,
  setNotificationContactsSuccesfulRegistration,
  setNotificationContactsMailing,
  setNotificationContactsReminder,
  setNotificationContactsStock,
  setNotificationContactsFeedback,
  setNotificitaionOrgPartInvite,
  setNotificitaionOrgPartCreate,
  setNotificitaionOrgPartMailing,
  setNotificitaionOrgPartReminder,
  setCreateStandInfo,
  setCreateStandInfoErrors,
  clearCreateStantInfoErrors,
  setCreateEventInfo,
  setResetEventInfo,
  setResetStandInfo,
  resetAccounts,
  setEditQuestion,
  setChangeQuestion,
  setDeleteQuestion,
  setSaveQuestion,
  setNewQuestion,
  setDateProgramInfo,
  setTimeEventProgramInfo,
  setDescriptionEventProgramInfo,
  setNewProgramDay,
  setNewProgramEvent,
  setDeleteProgramDay,
  setDeleteProgramEvent,
  setProgram,
  setClearDemoSite,
} = profileSlice.actions;
export default profileSlice.reducer;
