import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const AverageVisit = () => {
  const avgTimeSessions = useSelector(state => state.events.analytics.avg_time_sessions);
  const [time, setTime] = useState(0);
  useEffect(() => {
    if(avgTimeSessions){
      setTime(moment.utc(avgTimeSessions*1000).format('HH:mm:ss'));
    }
  }, [avgTimeSessions]);

  return (
    <div className="stand-analytics__duration analytics-duration">
      <h3 className="analytics-duration__title">Ср. продолжительность посещения</h3>
      <div className="analytics-duration__time">
        <span>{ time }</span>
      </div>
    </div>
  );
};

export default AverageVisit;
