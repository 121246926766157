import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { registerUser } from "../../../api";
import { AgreementPdf } from "../../../assets";
import { useCountries } from "../../../hooks/useCountries";
import { setRegistrationEmail } from "../../../redux/appReducer";
import { downloadPdf } from "../../../utils/downloadPdf";
import { authManager } from "../../../utils/managers";
import { Button, Loader, Select } from "../../shared";

const MAX_STRING_LENGTH = 191;

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const Form = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [isAgree, setIsAgree] = useState(false);
  const [country, setCountry] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const countries = useCountries();

  const [error, setError] = useState("");

  const onSubmit = async (evt) => {
    evt.preventDefault();
    try {
      if (!isAllow()) {
        return;
      }

      setIsLoading(true);

      const { data } = await registerUser(email, fullName, country.value, 1);
      const res = data.data;

      if (data.success) {
        res.user.token = res.token;
        authManager().setUser(res.user);

        dispatch(setRegistrationEmail(email));
        setIsLoading(false);
        history.push("/entry/registration/confirm");
      } else {
        setError(data.message);
        setIsLoading(false);
      }
    } catch ({ response }) {
      setIsLoading(false);

      if (response?.data?.data.email) {
        return setError("Пользователь с данным e-mail уже зарегистрирован");
      }

      const errorMessage = error.response?.data?.message || "Ошибка при регистрации";
      setError(errorMessage);
      throw error;
    }
  };

  const emailIsAllow = validateEmail(email) && email.length <= MAX_STRING_LENGTH;
  const fullNameIsAllow = fullName.length >= 5 && fullName.length <= MAX_STRING_LENGTH;

  const isAllow = () => {
    if (emailIsAllow && fullNameIsAllow && isAgree && country.value) {
      return true;
    }
  };

  const onAgreementDownloadClick = () => {
    downloadPdf(AgreementPdf, "Пользовательское соглашение.pdf");
  };

  return (
    <form className="registration-form__inputs">
      <div className="input input--registration">
        <label className="input__label" htmlFor="email">
          E-mail*
        </label>
        <input
          onChange={(e) => setEmail(e.target.value)}
          className={classNames(
            "input__main",
            { error: email.length > 0 && !emailIsAllow },
            { filled: validateEmail(email) }
          )}
          placeholder="Введите e-mail"
          type="email"
          id="email"
          maxLength={MAX_STRING_LENGTH}
        />
        {email && !validateEmail(email) ? (
          <p className="input__text">Неверный формат электронной почты</p>
        ) : null}
        {email.length > MAX_STRING_LENGTH && (
          <p className="input__text">
            Максимальная длина email - {MAX_STRING_LENGTH} символов
          </p>
        )}
      </div>
      <div className="input input--registration">
        <label className="input__label" htmlFor="fullName">
          Ваше ФИО*
        </label>
        <input
          onChange={(e) => setFullName(e.target.value)}
          className={classNames(
            "input__main",
            { error: fullName.length > 0 && !fullNameIsAllow },
            { filled: fullName.length >= 5 }
          )}
          placeholder="Введите ваше ФИО"
          type="text"
          id="fullName"
          maxLength={MAX_STRING_LENGTH}
        />
        {fullName.length >= 1 && fullName.length < 5 ? (
          <p className="input__text">Поле должно содержать не менее 5 символов</p>
        ) : null}
        {fullName.length > MAX_STRING_LENGTH && (
          <p className="input__text">
            Максимальная длина ФИО - {MAX_STRING_LENGTH} символов
          </p>
        )}
      </div>
      <div className="input input--registration">
        <label className="input__label">Страна*</label>
        <div
          style={{
            marginTop: 5,
          }}
        >
          <Select
            placeholder="Выберите страну"
            options={countries}
            value={country}
            onChange={setCountry}
          />
        </div>
      </div>
      <div className="registration-form__agreement">
        <input
          onChange={() => setIsAgree(!isAgree)}
          type="checkbox"
          id="agreement"
          className="registration-form__agreement-input"
        />
        <label htmlFor="agreement">
          Регистрируясь, вы подтверждаете, что принимаете{<br />}
          <button
            type="button"
            onClick={onAgreementDownloadClick}
            className="registration-form__link"
          >
            Пользовательское соглашение
          </button>
        </label>
      </div>
      {error && <p className="registration-form__error">{error}</p>}
      <div className="registration-form__submit">
        <Button onClick={onSubmit} disabled={!isAllow() || isLoading}>
          {isLoading ? <Loader className="registration-form__loader" /> : "Регистрация"}
        </Button>
      </div>
    </form>
  );
};

export default Form;
