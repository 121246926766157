import React from "react";
import { useSelector } from "react-redux";
import { NavProfile, TableDocuments } from "../../components/profile";
import { EventConfirmPopup, Header, Sidebar } from "../../components/shared";

const Documents = () => {
  const profile = useSelector((state) => state.user.profile);
  const [popupOpened, setPopupOpened] = React.useState(false);

  return (
    <div className="page-wrapper-documents">
      <div className="app">
        {popupOpened && <EventConfirmPopup closePopup={() => setPopupOpened(false)} />}
        <Sidebar />
        <Header />
        <div className="page__main">
          <NavProfile />
          <div className="personal">
            <div className="main main-documents">
              <div className="main__documents">
                <div className="main__header">
                  <h3 className="main__title">Профиль ({profile.role})</h3>
                  <h5 className="main__subtitle">Платежные документы</h5>
                </div>
                <div className="main__documents__button"></div>
              </div>
              <TableDocuments />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
